import React, { useState } from 'react'
import logo from '../../assets/img/logo.svg'
import SidebarMenu from '../../utils/Menu'
import { NavLink } from 'react-router-dom'
import Cookies from 'js-cookie';
import { RiLogoutCircleRLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'

const Sidebar = () => {
    const [activeTab, setActiveTab] = useState('')
    const navigate = useNavigate()

    return (
        <div className='genti-sidebar position-fixed d-none d-lg-block'>
            <div className='header-left-container px-5'>
                <div className='header-logo-wrapper'>
                    <img src={logo} alt='genti' />
                </div>
            </div>
            <div className='sidebar-menu-wrapper px-3'>
                {SidebarMenu.map((el, i) =>
                    <NavLink to={`/${el.link}`} key={i}
                        // onClick={() => {
                        //     setActiveTab(el.link)
                        // }}
                        className={({ isActive, isPending }) => {
                            if (isActive) setActiveTab(el.link)
                            return isPending ? "pending" : isActive ? "menu-link active" : "menu-link"
                        }
                        }
                    >
                        <div className={`d-flex align-items-center px-3 mb-4  `}>
                            <div>
                                {activeTab === (`${el.link}`) || activeTab === (`/${el.link}`) ?

                                    <img src={el.icon} alt='icon' /> :
                                    <img src={el.inactiveIcon} alt='icon' />}
                            </div>
                            <p className='menu-label mb-0'>{el.name}</p>
                        </div>

                    </NavLink>
                )}

            </div>
            <div className='position-absolute logout-section pb-4' role='button'>
                <div className='d-flex align-items-center'>
                    <RiLogoutCircleRLine className='text-danger' />
                    <p className='text-danger mb-0 px-2' onClick={() => {
                        Cookies.remove('CREATOR_AUTH_TOKEN')
                        Cookies.remove('CREATOR_CURRENT_USER')
                        navigate('/auth/login')
                    }}>Logout</p>
                </div>

            </div>
        </div>
    )
}

export default Sidebar