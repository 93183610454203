import React from 'react'
import Header from './Header'
import Sidebar from './Sidebar'

const AppLayout = ({ children }) => {
    return (
        <div className='genti-app_wrapper'>
            <div className='d-flex'>
                <Sidebar />
                <div className='genti-main'>
                    <Header />
                    <div className='genti-main-container'>
                        {children}
                    </div>
                </div>
            </div>



        </div>
    )
}

export default AppLayout