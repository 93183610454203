import React from 'react'
import './button.css'
import playIcon from '../../../../assets/img/playIcon.svg'
import pauseIcon from '../../../../assets/img/pause.svg'




function Button({ play, isPlaying }) {
  return (
    <div className='btn-container'>

      <div onClick={play} role='button' className='play-ctrl-btn'>
        {
          !isPlaying ?
            <img src={playIcon} alt='play-ctrl' />
            :
            <img src={pauseIcon} alt='play-ctrl' height='20px' width='20px' />

        }
      </div>

    </div>
  )
}
export default Button
