import React, { useState } from 'react'
import { Modal } from 'reactstrap'
import deleteIcon from '../../../assets/img/modalDelete.svg'
import confirmDeleteIcon from '../../../assets/img/confirmDelete.svg'
import { useNavigate } from 'react-router-dom'


const DeleteContent = ({ deleteContent, toggleDeleteContent, selectedContent }) => {
    const navigate = useNavigate()
    const [confirmDelete, setConfirmDelete] = useState(false)

    const toggleConfrimDelete = () => {
        setConfirmDelete(!confirmDelete)
    }

    return (
        <div>
            <Modal isOpen={deleteContent} toggle={toggleDeleteContent} className='modal-dialog-centered' contentClassName="upload-success__modal_content-wrapper">
                <div className="analytics-card upload-success__modal">
                    <div className='upload-succes__icon__container text-center'>
                        <img src={deleteIcon} alt='icon' />
                    </div>
                    <h3 className='upload-success__text'>Attention!!</h3>
                    <p className='upload-success__sub-text mb-4'>Are you sure you want to delete <span className='selected-content__text'>{selectedContent?.title}</span> from your Genti database?</p>
                    <div className='d-flex align-items-center justify-content-between mb-2 delete_content__confirmation_btn'>
                        <button className='btn cancel-btn w-100  upload-success__button' type='submit' onClick={() => {
                            // navigate('/content')
                            toggleDeleteContent()
                        }}>
                            {/* {
                        isLoading ?
                            <ButtonLoader /> :
                            'Sign in'
                    } */}
                            Cancel


                        </button>
                        <button className='btn auth-btn w-100  upload-success__button' type='submit' onClick={() => {
                            // navigate('/content')
                            toggleDeleteContent()
                            toggleConfrimDelete()
                        }}>
                            {/* {
                        isLoading ?
                            <ButtonLoader /> :
                            'Sign in'
                    } */}
                            Done


                        </button>
                    </div>

                </div>

            </Modal >

            <Modal isOpen={confirmDelete} toggle={toggleConfrimDelete} className='modal-dialog-centered' contentClassName="upload-success__modal_content-wrapper">
                <div className="analytics-card upload-success__modal">
                    <div className='upload-succes__icon__container text-center'>
                        <img src={confirmDeleteIcon} alt='icon' />

                    </div>
                    <h3 className='upload-success__text'>Deleted! </h3>
                    <p className='upload-success__sub-text mb-4'>You have successfully deleted <span className='selected-content__text'>{selectedContent?.title}</span> from your Genti database</p>
                    <button className='btn auth-btn w-100 upload-success__button' type='submit' onClick={() => {
                        navigate('/content')
                        toggleConfrimDelete()
                    }}>
                        {/* {
                        isLoading ?
                            <ButtonLoader /> :
                            'Sign in'
                    } */}
                        Done


                    </button>
                </div>

            </Modal >
        </div>
    )
}

export default DeleteContent