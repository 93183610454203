import React, { useState } from "react";
// import backArrow from '../../../../assets/img/backArrow.svg'
import info from "../../../../assets/img/info.svg";
import { Row, Col } from "reactstrap";
import Select from "../../../../components/SelectInput";
import Dropzone from "../../../../components/Dropzone";
import { useForm, useController } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { metadataValidation } from "../../../../utils/form_validation";
import ButtonLoader from "../../../../components/ButtonLoader";
import ImageUploadHandler from "../../../../utils/imageUpload";

const Form = ({
  setSection,
  genreList,
  subGenreList,
  authorDetails,
  typesList,
  languageList,
  handleUpload,
  isLoading,
}) => {
  const [author_image, setAuthorImage] = useState(null);
  const [title_image, setContentImage] = useState(null);
  const [uploading, setUploading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(metadataValidation),
  });

  const {
    field: { value: genre, onChange: genreOnChange, ...restGenreField },
  } = useController({ name: "genre", control });
  const {
    field: {
      value: sub_genre,
      onChange: subGenreOnChange,
      ...restSubGenreField
    },
  } = useController({ name: "sub_genre", control });
  const {
    field: {
      value: language,
      onChange: languageOnChange,
      ...restLanguageField
    },
  } = useController({ name: "language", control });
  const {
    field: { value: type_id, onChange: typeOnChange, ...restTypeField },
  } = useController({ name: "type_id", control });
  // const { field: { value: is_paywall, onChange: paywallOnChange, ...restPaywallField } } = useController({ name: 'is_paywall', control });

  const onSubmitHandler = async (data) => {
    const {
      author_description,
      authors,
      description,
      genre,
      language,
      narrators,
      publishers,
      sub_genre,
      title,
      type_id,
      // is_paywall
    } = data;
    setUploading(true);

    const authorPicture = author_image
      ? await ImageUploadHandler(author_image)
      : { serial_number: "" };
    const contentPicture = title_image
      ? await ImageUploadHandler(title_image)
      : { serial_number: "" };

    const formdata = new FormData();
    formdata.append("author_description", author_description);
    formdata.append("authors", authors);
    formdata.append("description", description);
    formdata.append("genre", genre);
    formdata.append("language", language);
    formdata.append("narrators", narrators);
    formdata.append("publishers", publishers);
    formdata.append("sub_genre", sub_genre);
    formdata.append("title", title);
    formdata.append("type_id", type_id);
    formdata.append("author_image", authorPicture.serial_number);
    formdata.append("title_image", contentPicture.serial_number);
    formdata.append("change_author_image", author_image ? true : false);
    formdata.append("change_title_image", title_image ? true : false);
    // formdata.append('is_paywall', is_paywall)
    setUploading(false);
    // console.log(formdata)
    handleUpload(formdata);
  };
  return (
    <form
      className="rss-uploaded_content__form"
      onSubmit={handleSubmit(onSubmitHandler)}
    >
      <div className="side-padding">
        <h3 className="metadata__text">Metadata</h3>
        <Row>
          <Col sm="6">
            <div className="form-input__container">
              {errors.title ? (
                <label className="text-danger auth-label">
                  {errors.title?.message}
                </label>
              ) : (
                <label className="">Content Title</label>
              )}
              <input
                className="form-control"
                placeholder="Content Title"
                {...register("title")}
              />
            </div>
            <div className="form-input__container">
              {errors.genre ? (
                <label className="text-danger auth-label">
                  {errors.genre?.message}
                </label>
              ) : (
                <label className="">Genre (s)</label>
              )}
              <Select
                className="form-control cstm-react__select__wrapper"
                classNamePrefix="cstm-react__select"
                isMulti
                isClearable={false}
                placeholder="Select Genre (s)"
                options={
                  genreList
                    ? genreList.map((el) => ({
                        label: el?.title,
                        value: el?.id,
                      }))
                    : []
                }
                onChange={(option) =>
                  genreOnChange(
                    option ? option.map((el) => el.label).join(",") : option
                  )
                }
                {...restGenreField}
              />
            </div>

            {/* <div className='form-input__container'>
                            <label className=''>Synopsis of each Chapter/ Episode</label>

                            <input className='form-control' placeholder='Synopsis' />
                            <div className='input-info__wrapper d-flex '>
                                <div className='input-info_icon__wrapper'>
                                    <img src={info} alt='icon' />
                                </div>
                                <p className='input-info_text'>
                                    Eg: Episode 1: This is what episode 1 is about. Episode 2: This is....
                                    (For Podcasters, fill as 'I am a podcaster).
                                </p>

                            </div>
                        </div> */}
          </Col>
          <Col sm="6">
            <div className="form-input__container">
              {errors.description ? (
                <label className="text-danger auth-label">
                  {errors.description?.message}
                </label>
              ) : (
                <label className="">Description</label>
              )}
              <textarea
                className="form-control uploaded-content__description"
                placeholder="Enter Description"
                rows="6"
                {...register("description")}
              />
            </div>
          </Col>
        </Row>
        <Row>
          {/* <Col sm='6'>
                        <div className='form-input__container'>

                            {
                                errors.genre ? <label className='text-danger auth-label'>{errors.genre?.message}</label> : <label className=''>Genre (s)</label>
                            }
                            <Select
                                className='form-control cstm-react__select__wrapper'
                                classNamePrefix="cstm-react__select"
                                isMulti
                                isClearable={false}
                                placeholder='Select Genre (s)'
                                options={genreList ?
                                    genreList.map(el => (
                                        {
                                            label: el?.title,
                                            value: el?.id
                                        }
                                    )
                                    ) : []
                                }
                                onChange={option => genreOnChange(option ? option.map(el => el.label).join(',') : option)}
                                {...restGenreField}
                            />
                        </div>


                    </Col> */}
          <Col sm="6">
            <div className="form-input__container">
              {errors.sub_genre ? (
                <label className="text-danger auth-label">
                  {errors.sub_genre?.message}
                </label>
              ) : (
                <label className="">Sub Genre</label>
              )}
              <Select
                className="form-control cstm-react__select__wrapper"
                classNamePrefix="cstm-react__select"
                isMulti
                isClearable={false}
                placeholder="Sub Genre"
                options={
                  subGenreList
                    ? subGenreList.map((el) => ({
                        label: el?.title,
                        value: el?.id,
                      }))
                    : []
                }
                onChange={(option) =>
                  subGenreOnChange(
                    option ? option.map((el) => el.label).join(",") : option
                  )
                }
                {...restSubGenreField}
              />
            </div>
          </Col>
          <Col sm="6">
            <div className="form-input__container">
              {errors.type_id ? (
                <label className="text-danger auth-label">
                  {errors.type_id?.message}
                </label>
              ) : (
                <label className="">Type</label>
              )}
              <Select
                className="form-control cstm-react__select__wrapper"
                classNamePrefix="cstm-react__select"
                // isMulti
                isClearable={false}
                placeholder="Select Option"
                options={
                  typesList
                    ? typesList
                        .filter((el) => el?.title !== "Podcast")
                        .map((el) => ({
                          label: el?.title,
                          value: el?.id,
                        }))
                    : []
                }
                onChange={(option) =>
                  typeOnChange(option ? option.value : option)
                }
                {...restTypeField}
              />
            </div>
          </Col>
          <Col sm="6">
            <div className="form-input__container">
              {errors.language ? (
                <label className="text-danger auth-label">
                  {errors.language?.message}
                </label>
              ) : (
                <label className="">Language (s)</label>
              )}
              <Select
                className="form-control cstm-react__select__wrapper"
                classNamePrefix="cstm-react__select"
                isMulti
                isClearable={false}
                placeholder="Select Language (s)"
                options={
                  languageList
                    ? languageList.map((el) => ({
                        label: el?.name,
                        value: el?.id,
                      }))
                    : []
                }
                onChange={(option) =>
                  languageOnChange(
                    option ? option.map((el) => el.label).join(",") : option
                  )
                }
                {...restLanguageField}
              />
            </div>
          </Col>
          <Col sm="6">
            <div className="form-input__container">
              {errors.authors ? (
                <label className="text-danger auth-label">
                  {errors.authors?.message}
                </label>
              ) : (
                <label className="">Author (s)</label>
              )}
              <input
                className="form-control"
                defaultValue={authorDetails?.name}
                placeholder="Author (s)"
                {...register("authors")}
              />
            </div>
          </Col>
          <Col sm="6">
            <div className="form-input__container">
              {errors.author_description ? (
                <label className="text-danger auth-label">
                  {errors.author_description?.message}
                </label>
              ) : (
                <label className="">Author Description</label>
              )}
              <input
                className="form-control"
                defaultValue={authorDetails?.description}
                placeholder="Author Description"
                {...register("author_description")}
              />
            </div>
          </Col>
          <Col sm="6">
            <div className="form-input__container">
              {errors.narrators ? (
                <label className="text-danger auth-label">
                  {errors.narrators?.message}
                </label>
              ) : (
                <label className="">Narrator(s)</label>
              )}
              <input
                className="form-control"
                placeholder="Narrator (s)"
                {...register("narrators")}
              />
              <div className="input-info__wrapper d-flex ">
                <div className="input-info_icon__wrapper">
                  <img src={info} alt="icon" />
                </div>
                <p className="input-info_text">
                  For everyone apart from podcasters. Podcasters please fill
                  narrator as 'I am a podcaster.
                </p>
              </div>
            </div>
          </Col>
          <Col sm="6">
            <div className="form-input__container">
              {errors.publishers ? (
                <label className="text-danger auth-label">
                  {errors.publishers?.message}
                </label>
              ) : (
                <label className="">Publisher (If Applicable)</label>
              )}
              <input
                className="form-control"
                placeholder="Content Title"
                {...register("publishers")}
              />
              <div className="input-info__wrapper d-flex ">
                <div className="input-info_icon__wrapper">
                  <img src={info} alt="icon" />
                </div>
                <p className="input-info_text">Not necessary for podcasters.</p>
              </div>
            </div>
          </Col>
          {/* <Col sm='6'>
                        <div className='form-input__container'>

                            {
                                errors.is_paywall ? <label className='text-danger auth-label'>{errors.is_paywall?.message}</label> : <label className=''>Is paywall?</label>
                            }
                            <Select
                                className='form-control cstm-react__select__wrapper'
                                classNamePrefix="cstm-react__select"
                                // isMulti
                                isClearable={false}
                                placeholder='Select Option'
                                options={[
                                    {
                                        label: 'Outside Paywall',
                                        value: '0'
                                    },
                                    {
                                        label: 'Inside Paywall',
                                        value: '1'
                                    }
                                ]

                                }
                                onChange={option => paywallOnChange(option ? option.value : option)}
                                {...restPaywallField}
                            />
                        </div>

                        <div className='input-info__wrapper d-flex '>
                            <div className='input-info_icon__wrapper'>
                                <img src={info} alt='icon' />
                            </div>
                            <p className='input-info_text'>
                                Do you want your work Outside the Paywall or Inside the Paywall? Outside the paywall means you will earn revenue on Ads and subscriptions as listeners can listen for free once they have the app, while Inside the Paywall means you will earn revenue on subscriptions only as listeners will need to be on Genti premium to listen to your work (Recommended for audiobooks)
                            </p>

                        </div>
                    </Col> */}
        </Row>
        <div className="upload-content__image__wrapper">
          <Row className="gy-4">
            <Col sm="12" md="6">
              <label className="">
                Content Cover Art as available (Square)
              </label>

              <Dropzone
                name={"title_image"}
                register={register}
                setValue={setContentImage}
                onChange={setContentImage}
              />
              <div className="no-cover_art__wrapper d-flex align-items-center">
                <div className="">
                  <input
                    type="checkbox"
                    name="checkbox"
                    className="cstm-checkbox__input"
                    role="button"
                  />
                </div>
                <p className="mb-0 no-cover_art__text">
                  No Image? No problem. Genti will create one for you, Tick this
                  box
                </p>
              </div>
            </Col>
            <Col sm="12" md="6">
              <label className="">Authors Image</label>

              <Dropzone
                name={"author_image"}
                register={register}
                setValue={setAuthorImage}
                onChange={setAuthorImage}
              />
            </Col>
          </Row>
        </div>
      </div>

      <div className="d-flex justify-content-end rss-upload-button__wrapper">
        <div className="side-padding">
          <button
            className="btn auth-btn "
            type="submit"
            // onClick={(e) => {
            //     e.preventDefault()
            //     setSection("Content_upload")
            // }}
            disabled={isLoading || uploading}
          >
            {isLoading || uploading ? <ButtonLoader /> : "Next"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default Form;
