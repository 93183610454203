import React, { useState } from 'react'
import { Col } from 'reactstrap'
import { useForm } from "react-hook-form";
import { useQuery, useMutation } from 'react-query'
import Client from '../../../client';
import { toast } from 'react-toastify';
import { yupResolver } from "@hookform/resolvers/yup";
import { setPinValidation, changePinValidation } from '../../../utils/form_validation';
import ButtonLoader from '../../../components/ButtonLoader';


const TransactionPin = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(setPinValidation),
    });

    const { register: register_2, handleSubmit: handleSubmit_2, formState: { errors: errors_2 }, reset: reset_2 } = useForm({
        resolver: yupResolver(changePinValidation),
    });

    const [showOldPassword, setShowOldPassword] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showPassword2, setShowPassword2] = useState(false)

    const toggleOldPassword = () => {
        setShowOldPassword(!showOldPassword)
    }
    const togglePassword = () => {
        setShowPassword(!showPassword)
    }
    const togglePassword2 = () => {
        setShowPassword2(!showPassword2)
    }


    const [refresh, setRefresh] = useState(false)
    const [hasPin, setHasPin] = useState(0)

    useQuery(['user-details', refresh], () => Client.profile.get_user_profile(), {
        onSuccess: (data) => {
            setHasPin(data.data.user.has_pin)
        }
    })

    const { mutate: setPin, isLoading } = useMutation(Client.profile.set_transaction_pin, {
        onSuccess: (data) => {
            toast.success(data.message)
            setRefresh(!refresh)
            reset()
        },
        onError: (err) => {
        }
    })

    const { mutate: changePin, isLoading: loading } = useMutation(Client.profile.change_transaction_pin, {
        onSuccess: (data) => {
            toast.success(data.message)
            setRefresh(!refresh)
            reset_2()
        },
        onError: (err) => {
            toast.error(err.response.data.message)
        }
    })

    const onSubmitHandler = (values) => {

        setPin(values)

    }

    const onSubmitHandler_2 = (values) => {

        changePin(values)
    }

    return (

        <div className='pb-5'>

            <Col sm='12' md='9'>
                {
                    hasPin === 0 ?
                        <form
                            className='upload-content__form rss-input__form w-100 overflow-hidden mt-0'
                            onSubmit={handleSubmit(onSubmitHandler)}
                        >
                            <div className='mb-4'>
                                <h3 className='password-settings__text'>Transaction PIN</h3>
                                <p className='password-settings__subtext'>Please set a PIN you’ll use to confirm your transactions on Genti</p>
                            </div>
                            <div className='mb-4  no-border'>


                                {
                                    errors.transaction_pin ? <label className='text-danger '>{errors.transaction_pin?.message}</label> : <label className=''>PIN</label>
                                }
                                <div className='password-input-wrappe  d-flex align-items-center justify-content-between position-relative'>
                                    <input
                                        pattern="[0-9]*"
                                        inputMode="numeric"
                                        type={`number`}
                                        onInput={(e) => {
                                            if (e.target.value.length > e.target.maxLength) {
                                                e.target.value = e.target.value.slice(0, e.target.maxLength)
                                            }
                                        }}
                                        maxlength={"4"}
                                        className={`auth-input form-control w-100
                                         ${errors.transaction_pin ? 'border-danger' : ''}
                                         ${showOldPassword ? 'unmask-input' : 'mask-input'}`
                                        }
                                        {...register("transaction_pin")}
                                    />
                                    <div className='visibility-icon position-absolute' onClick={toggleOldPassword} role='button'>
                                        {`${showOldPassword ? 'HIDE' : 'SHOW'}`}
                                    </div>
                                </div>

                            </div>
                            <div className='mb-2'>
                                {errors.confirm_transaction_pin ? <p className='text-danger auth-label mb-0'>{errors.confirm_transaction_pin?.message}</p> : <label className='auth-label' tabIndex={0}>New PIN</label>}
                                <div className='password-input-wrappe  d-flex align-items-center justify-content-between position-relative'>
                                    <input
                                        pattern="[0-9]*"
                                        inputMode="numeric"

                                        type={`number`}
                                        maxlength={"4"}
                                        className={`auth-input form-control w-100
                                         ${errors.confirm_transaction_pin ? 'border-danger' : ''}
                                         ${showPassword2 ? 'unmask-input' : 'mask-input'}`}
                                        {...register("confirm_transaction_pin")}
                                    />
                                    <div className='visibility-icon position-absolute' onClick={togglePassword2} role='button'>
                                        {`${showPassword2 ? 'HIDE' : 'SHOW'}`}
                                    </div>
                                </div>
                            </div>




                            <div className=' rss-upload-button__wrapper no-border mb-3'>
                                <div className=''>
                                    <button className='btn auth-btn ' type='submit'

                                        disabled={isLoading}
                                    >
                                        {
                                            isLoading ?
                                                <ButtonLoader /> :
                                                'Set PIN'
                                        }
                                    </button>
                                </div>
                            </div>
                        </form> :
                        <form
                            className='upload-content__form rss-input__form w-100 overflow-hidden mt-0'
                            onSubmit={handleSubmit_2(onSubmitHandler_2)}
                        >
                            <div className='mb-4'>
                                <h3 className='password-settings__text'>Transaction PIN</h3>
                                <p className='password-settings__subtext'>To change your transaction PIN, please fill the details below</p>
                            </div>
                            <div className='mb-4  no-border'>


                                {
                                    errors_2.old_transaction_pin ? <label className='text-danger '>{errors_2.old_transaction_pin?.message}</label> : <label className=''>PIN</label>
                                }
                                <div className='password-input-wrappe  d-flex align-items-center justify-content-between position-relative'>
                                    <input
                                        pattern="[0-9]*"
                                        inputMode="numeric"
                                        type={`number`}
                                        onInput={(e) => {
                                            if (e.target.value.length > e.target.maxLength) {
                                                e.target.value = e.target.value.slice(0, e.target.maxLength)
                                            }
                                        }}
                                        maxlength={"4"}
                                        className={`auth-input form-control w-100
                                         ${errors_2.old_transaction_pin ? 'border-danger' : ''}
                                         ${showPassword ? 'unmask-input' : 'mask-input'}`
                                        }
                                        {...register_2("old_transaction_pin")}
                                    />
                                    <div className='visibility-icon position-absolute' onClick={togglePassword} role='button'>
                                        {`${showPassword ? 'HIDE' : 'SHOW'}`}
                                    </div>
                                </div>

                            </div>
                            <div className='mb-4  no-border'>


                                {
                                    errors_2.transaction_pin ? <label className='text-danger '>{errors_2.transaction_pin?.message}</label> : <label className=''>NEW PIN</label>
                                }
                                <div className='password-input-wrappe  d-flex align-items-center justify-content-between position-relative'>
                                    <input
                                        pattern="[0-9]*"
                                        inputMode="numeric"
                                        type={`number`}
                                        onInput={(e) => {
                                            if (e.target.value.length > e.target.maxLength) {
                                                e.target.value = e.target.value.slice(0, e.target.maxLength)
                                            }
                                        }}
                                        maxlength={"4"}
                                        className={`auth-input form-control w-100
                                         ${errors_2.transaction_pin ? 'border-danger' : ''}
                                         ${showOldPassword ? 'unmask-input' : 'mask-input'}`
                                        }
                                        {...register_2("transaction_pin")}
                                    />
                                    <div className='visibility-icon position-absolute' onClick={toggleOldPassword} role='button'>
                                        {`${showOldPassword ? 'HIDE' : 'SHOW'}`}
                                    </div>
                                </div>

                            </div>
                            <div className='mb-2'>
                                {errors_2.confirm_transaction_pin ? <p className='text-danger auth-label mb-0'>{errors_2.confirm_transaction_pin?.message}</p> : <label className='auth-label' tabIndex={0}>Re-enter New PIN</label>}
                                <div className='password-input-wrappe  d-flex align-items-center justify-content-between position-relative'>
                                    <input
                                        pattern="[0-9]*"
                                        inputMode="numeric"

                                        type={`number`}
                                        maxlength={"4"}
                                        className={`auth-input form-control w-100
                                         ${errors_2.confirm_transaction_pin ? 'border-danger' : ''}
                                         ${showPassword2 ? 'unmask-input' : 'mask-input'}`}
                                        {...register_2("confirm_transaction_pin")}
                                    />
                                    <div className='visibility-icon position-absolute' onClick={togglePassword2} role='button'>
                                        {`${showPassword2 ? 'HIDE' : 'SHOW'}`}
                                    </div>
                                </div>
                            </div>





                            <div className=' rss-upload-button__wrapper no-border mb-3'>
                                <div className=''>
                                    <button className='btn auth-btn ' type='submit'

                                        disabled={loading}
                                    >
                                        {
                                            loading ?
                                                <ButtonLoader /> :
                                                'Change PIN'
                                        }
                                    </button>
                                </div>
                            </div>
                        </form>
                }

            </Col>
        </div>
    )
}

export default TransactionPin