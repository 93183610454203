import ENDPOINTS from '../client/endpoints';
import axios from "axios";
import { HttpClient } from "../client/axios";


const formatDuration = (duration) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.round(duration % 60);

    const formattedHours = hours > 0 ? `${hours}:` : '';
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
};

function getImageMetadata(url, fileName) {
    return new Promise((resolve, reject) => {
        const data = {
            url,
            file_name: fileName,
            type: 'Title'
        }
        HttpClient.post(`${ENDPOINTS.UPLOAD_IMAGE}`, data)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const ImageUploadHandler = (file) => {
    console.log(file)
    return new Promise((resolve, reject) => {
        const fileName = file.name;
        const fileExt = fileName.substring(fileName.lastIndexOf('.') + 1);

        HttpClient.get(`${ENDPOINTS.GET_PRESIGN_URL}/${fileName}`)
            .then((response) => {
                const newName = response.filePath;
                const modifiedFile = new File([file], newName, { type: file.type });

                const xhr = new XMLHttpRequest();
                xhr.open('PUT', response.uploadUrl);
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200) {
                            getImageMetadata(response.file, fileName)
                                .then((metadata) => {
                                    const data = {
                                        serial_number: metadata.data.serial_number
                                    };
                                    resolve(data);
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        } else {
                            reject(new Error('Could not upload file.'));
                        }
                    }
                };
                xhr.send(modifiedFile);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export default ImageUploadHandler;
