import React, { useState } from 'react'
import Empty from './Empty'
import Form from './Form'
import BankList from './List'
import { useQuery } from 'react-query'
import Client from '../../../client';


const Bank = () => {
    const [bankList, setBankList] = useState([])
    const [section, setSection] = useState('empty')

    useQuery(['bank-details'], () => Client.profile.get_bank_list(), {
        onSuccess: (data) => {
            setBankList(data.data)
            if (data?.data === null){
                setSection('empty')
            }
            else{
                setSection('bank_list')
            }
        }
    })

    return (

        <div className='pb-5'>
            <div className='mb-4'>
                <h3 className='password-settings__text'>Bank Details</h3>
            </div>
            {section === 'empty' && <Empty setSection={setSection} />}
            {section === 'form' && <Form setSection={setSection} />}
            {section === 'bank_list' && <BankList setSection={setSection} bankList={bankList} />}
        </div>
    )
}

export default Bank