import React, { useState } from 'react'
import backArrow from '../../../../assets/img/backArrow.svg'
import t1 from '../../../../assets/img/t14.svg'
import trash from '../../../../assets/img/trash.svg'

import Player from '../../../../components/Player'

const ContentUpload = ({ setSection }) => {
    const [contentEpisodes, setContentEpisodes] = useState(1)
    const [uploadedMP3, setUploadedMP3] = useState([])

    function handleFiles(i, event) {
        var files = event.target.files;
        setUploadedMP3(mp3 => [...mp3, { id: i, audio: URL.createObjectURL(files[0]) }
        ])
    }

    function deleteFile(i) {
        const arr = uploadedMP3.filter(el => el.id !== i)
        setUploadedMP3(arr)
    }
    return (
        <div>
            <p className='page-title'>Content/<span className='page-sub-title text-white'>Upload Content</span></p>

            <div className='mb-4 d-flex align-items-center'>
                <div
                    style={{
                        marginRight: '9px'
                    }}
                    onClick={() => {
                        setSection('RSS_Content')
                    }}
                    role='button'
                >
                    <img src={backArrow} alt='icon' />
                </div>
                <h4 className='page-details mb-0'>Upload Content</h4>

            </div>

            <div className="third-row analytics-card content-table withdraw rss-upload--form__wrapper no-side__padding">
                <div className='uploaded-rss__details__wrapper side-padding'>
                    <div className='uploaded-rss__details__header d-flex align-items-center'>
                        <div className='uploaded-rss__details__img-wrapper'>
                            <img src={t1} alt='icon' className='uploaded-rss__details__img' />
                        </div>
                        <h3 className='uploaded-rss__details__title mb-0'>
                            Name of Content here
                        </h3>
                    </div>
                </div>
                <div className='rss-uploaded_content__form upload-rss_details__container side-padding no-border'>
                    {
                        new Array(contentEpisodes).fill("-").map((el, i) =>
                            <div className='  uploaded-rss_details d-flex align-items-center justify-content-between' key={i}>

                                <div className={`${uploadedMP3.some(el => el.id === i) ? 'w-100' : 'w-50'}  d-flex align-items-center`}>
                                    <div className={`form-input__container ${uploadedMP3.some(el => el.id === i) ? 'w-100' : 'w-75'}`}>
                                        <label className=''>Chapter {i + 1}</label>
                                        <input className='form-control w-100' placeholder='Introduction to King Wakanda' />
                                    </div>
                                    {
                                        !uploadedMP3.some((el) => el.id === i) &&
                                        <div className='w-25'>
                                            <label for={`mp3-upload__${i}`} className='upload-mp3__btn btn w-100 mb-0' >Upload MP3</label>
                                            <input type='file'
                                                className='d-none'
                                                id={`mp3-upload__${i}`} name={`mp3-upload__${i}`}
                                                accept=".mp3"
                                                onChange={(e) => {
                                                    handleFiles(i, e)
                                                }} />
                                        </div>
                                    }

                                </div>

                                {
                                    uploadedMP3.some((el) => el.id === i) && <div className='w-100'>
                                        <div className='rss-player__container'>
                                            <Player audio={uploadedMP3[i].audio} />
                                        </div>
                                    </div>

                                }



                                {/* <div className='w-100'>
                                    <div className='rss-player__container'>
                                        <Player />
                                    </div>
                                </div> */}


                                <div className='w-25 '>
                                    {
                                        uploadedMP3.some((el) => el.id === i) &&
                                        <div className='w-100 d-flex justify-content-center align-items-center' role='button'
                                            onClick={() => {
                                                deleteFile(i)
                                            }}>
                                            <div className='delete-mp3__container text-center'>
                                                <div className='delete-mp3__icon__wrapper'>
                                                    <img src={trash} alt='icon' />
                                                </div>
                                                <p className='delete-mp3__text mb-0'>Delete MP3</p>
                                            </div>
                                        </div>

                                    }
                                </div>

                            </div>
                        )
                    }
                    <div className=' rss-upload-button__wrapper no-border'>
                        <button className='btn auth-btn add-chapter__btn' type='submit' onClick={() => {
                            setContentEpisodes(episodes => episodes + 1)
                        }}>
                            Add Chapter
                        </button>

                    </div>
                    {
                        contentEpisodes === 1 ?
                            <div className='fill-chapter-container'>

                            </div> : null
                    }

                </div>



                {/* <div className='rss-uploaded_content__form upload-rss_details__container'>
                    <div className='  uploaded-rss_details d-flex align-items-center justify-content-between'>

                        <div className='w-100'>
                            <div className='form-input__container'>
                                <label className=''>Chapter 1</label>
                                <input className='form-control' placeholder='Introduction to King Wakanda' />
                            </div>
                        </div>

                        <div className='w-100'>
                            <div className='rss-player__container'>
                                <Player />
                            </div>
                        </div>


                        <div className='w-25'>
                        </div>

                    </div>
                    <div className='  uploaded-rss_details d-flex align-items-center justify-content-between no-border'>

                        <div className='w-100'>
                            <div className='form-input__container'>
                                <label className=''>Chapter 2</label>
                                <input className='form-control' placeholder='Home, sweet home' />
                            </div>
                        </div>

                        <div className='w-100'>
                            <div className='rss-player__container'>
                                <Player />
                            </div>
                        </div>


                        <div className='w-25'>
                        </div>

                    </div>
                </div> */}
                <div className='d-flex justify-content-end rss-upload-button__wrapper'>
                    <div className='side-padding'>
                        <button className='btn auth-btn ' type='submit' onClick={() => {
                            setSection('Copyright')
                        }}>
                            {/* {
                        isLoading ?
                            <ButtonLoader /> :
                            'Sign in'
                    } */}
                            Next


                        </button>
                    </div>


                </div>



            </div>

        </div >

    )
}

export default ContentUpload;