import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import AppLayout from '../../layout/App'
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie';

const ProtectedeRoute = () => {
    const accessToken = Cookies.get("CREATOR_AUTH_TOKEN")
  let authorized=true
  if (accessToken) {
    let decoded = jwt_decode(accessToken)
    if (decoded.exp * 1000 < Date.now()) {
      authorized = false
    } else {
      authorized = true
    }
  } else {
    authorized = false
  }

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return authorized ? (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ) : (
    <Navigate to='/auth/login' />
  )
}



export default ProtectedeRoute