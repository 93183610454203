import React from 'react'
import usePagination, { DOTS } from './PaginationHooks'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'

function Pagination({
  onPageChange,
  totalCount,
  currentPage,
  pageSize,
}) {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    pageSize
  })

  const onNext = () => {
    onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
  }

  return (
    <ul className='pagination-wrapper' aria-label='pagination'>
      <li className='paginationItem'>
        <button
          type='button'
          className='arrowButton left'
          aria-label='Goto previous page'
          onClick={onPrevious}
          disabled={currentPage === 1} // change this line to disable a button.
        >
          Prev
        </button>
      </li>

      {paginationRange.map(pageNumber => {
        const key = nanoid()

        if (pageNumber === DOTS) {
          return (
            <li key={key} className='dots'>
              &#8230;
            </li>
          )
        }

        return (
          <li
            key={key}
            // className=''
            className={`paginationItem ${currentPage === pageNumber ? 'active-page' : 'false'}`}
            aria-current={currentPage === pageNumber ? 'page' : 'false'} // change this line to highlight a current page.
          >
            <button
              type='button'
              aria-label={`Goto page ${pageNumber}`}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </button>
          </li>
        )
      })}

      <li className='paginationItem'>
        <button
          type='button'
          className='arrowButton right'
          aria-label='Goto next page'
          onClick={onNext}
          disabled={currentPage === Math.ceil(totalCount / pageSize)} // change this line to disable a button.
        >
          Next
        </button>
      </li>

    </ul>
  )
}

Pagination.propTypes = {
  totalCount: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  onPageChange: PropTypes.func,
  onPageSizeOptionChange: PropTypes.func
}

Pagination.defaultProps = {
  totalCount: 0,
  currentPage: 1,
  pageSize: 1,
  //   pageSizeOptions: [15, 25, 50, 100],
  onPageChange: () => { },
  onPageSizeOptionChange: () => { }
}

export default Pagination
