import React from 'react'
import Select from 'react-select';

const SelectInput = (props) => {
    const { options, onChange, isMulti, className, placeholder, defaultValue } = props
    return (
        <Select
            className={className}
            classNamePrefix="cstm-react__select"
            placeholder={placeholder}
            menuPortalTarget={document.body} // Or document.getElementById('scrollContainer')
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
            }}
            closeMenuOnScroll={event => {
                return event.target.id === 'scrollContainer';
            }}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary25: '#8E75FF',
                    primary: '#121212',
                },
            })}
            isMulti={isMulti}
            options={options}
            onChange={onChange}
            defaultValue={defaultValue}
            hideSelectedOptions={true}
            {...props}
        />
    )
}

export default SelectInput