import React, { useState } from "react";
import AuthLayout from "../../layout/Auth";
import { useNavigate } from "react-router-dom";
import Success from "../../components/SuccessAnimation";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPasswordValidation } from "../../utils/form_validation";
import { useMutation } from "react-query";
import ButtonLoader from "../../components/ButtonLoader";
import Cookies from "js-cookie";

import Client from "../../client";

const Login = () => {
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState("password-form");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const togglePassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(resetPasswordValidation),
  });

  const { mutate: resetPassword, isLoading } = useMutation(
    Client.auth.reset_password,
    {
      onSuccess: (data) => {
        if (data.error) {
          toast.error(data.error.message);
        } else {
          reset();
          Cookies.remove("GENTI_RESET_EMAIL");
          Cookies.remove("GENTI_RESET_CODE");
          setActiveSection("congrats-form");
        }
      },
      onError: (error) => {
        if (error.response)
          return toast.error(
            error.response.data.error
              ? error.response.data.error.message
              : error.response.data.message
          );
        else if (error.data) return toast.error(error.data.message);
        else return toast.error(error.message);
      },
    }
  );

  const onSubmitHandler = (data) => {
    data.email = Cookies.get("GENTI_RESET_EMAIL");
    data.code = Cookies.get("GENTI_RESET_CODE");

    resetPassword(data);
  };

  return (
    <AuthLayout>
      {activeSection === "password-form" && (
        <form className="auth-form" onSubmit={handleSubmit(onSubmitHandler)}>
          <div className="mb-5">
            <h3 className="form-title sign-text mb-2">New Password</h3>
            <p className="otp-text">
              Please enter your new password to continue
            </p>
          </div>

          <div className="mb-4">
            {errors.new_password ? (
              <p className="text-danger auth-label mb-0">
                {errors.new_password?.message}
              </p>
            ) : (
              <label className="auth-label" tabIndex={0}>
                New Password
              </label>
            )}
            <div className="password-input-wrappe  d-flex align-items-center justify-content-between position-relative">
              <input
                type={`${showPassword ? "text" : "password"}`}
                className={`auth-input form-control w-100 ${
                  errors.new_password ? "border-danger" : ""
                }`}
                {...register("new_password")}
              />
              <div
                className="visibility-icon position-absolute"
                onClick={togglePassword}
                role="button"
              >
                {`${showPassword ? "HIDE" : "SHOW"}`}
              </div>
            </div>
          </div>

          <div className="mb-5">
            {errors.confirm_password ? (
              <p className="text-danger auth-label mb-0">
                {errors.confirm_password?.message}
              </p>
            ) : (
              <label className="auth-label" tabIndex={0}>
                Re-enter New Password
              </label>
            )}
            <div className="password-input-wrappe  d-flex align-items-center justify-content-between position-relative">
              <input
                type={`${showPassword2 ? "text" : "password"}`}
                className={`auth-input form-control w-100 ${
                  errors.confirm_password ? "border-danger" : ""
                }`}
                {...register("confirm_password")}
              />
              <div
                className="visibility-icon position-absolute"
                onClick={togglePassword2}
                role="button"
              >
                {`${showPassword2 ? "HIDE" : "SHOW"}`}
              </div>
            </div>
          </div>

          <div className="sign-in-btn">
            <button
              className="btn auth-btn w-100 "
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? <ButtonLoader /> : "Reset Password"}
            </button>
          </div>
        </form>
      )}

      {activeSection === "congrats-form" && (
        <form className="auth-form position-relative">
          <div className="animation-wrapper mb-2">
            <Success />
          </div>
          <p className="congrats-text">All Set!! </p>
          <p className="congrats-sub-text">
            You have successfully reset your password.{" "}
          </p>
          <div className="sign-in-btn">
            <button
              className="btn auth-btn w-100 "
              onClick={(e) => {
                e.preventDefault();
                navigate("/auth/login");
              }}
            >
              Done
            </button>
          </div>
        </form>
      )}
    </AuthLayout>
  );
};

export default Login;
