import React, { useState } from 'react'
import backArrow from '../../../assets/img/backArrow.svg'
import select from '../../../assets/img/headerselect.svg'
import b1 from '../../../assets/img/b1.svg'
import b2 from '../../../assets/img/b2.svg'
import b3 from '../../../assets/img/b3.svg'


import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import Select from '../../../components/SelectInput'
import ButtonLoader from '../../../components/ButtonLoader'
import { useForm, useController } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { withdrawalValidation } from '../../../utils/form_validation'
import TransactionDetails from '../Modal/TransactionDetails'



const Withdraw = ({
    setUserType,
    bankList,
    walletBalance
}) => {
    // console.log('-->>bank-list<<--', bankList)
    const { has_pin } = bankList
    const navigate = useNavigate()
    const [transactionDetails, showTransactionDetails] = useState(false)
    const [selectedAccount, setSelectedAccount] = useState(null)
    const [showUserBanks, setShowUserBanks] = useState(false)
    const [amount, setAmount] = useState()
    const loading = false;
    const { register, handleSubmit, formState: { errors }, control, watch, setValue } = useForm({
        resolver: yupResolver(withdrawalValidation),
    });


    const toggleTransactionDetails = () => {
        showTransactionDetails(!transactionDetails)
    }

    const onSubmitHandler = (data) => {
        // Cookies.set("CREATOR_RSS_LINK", data.rss)
        // setRSSUploadedLink(data.rss)
        setAmount(data?.amount)
        toggleTransactionDetails()
        // handleUpload(data)
    };

    const toggleUserBanks = () => {
        setShowUserBanks(!showUserBanks)
    }

    // const amountValue = watch('amount');

    const handleAmountChange = (event) => {
        const rawValue = event.target.value.replace(/[^\d]/g, '');
        setValue('amount', rawValue);
        const formattedValue = parseFloat(rawValue.replace(/,/g, '')).toLocaleString();
        setValue('amountFormatted', formattedValue === 'NaN' ? '' : formattedValue);
      
    };

    return (
        <>
            {


                <div>
                    <p className='page-title'>Transactions /<span className='page-sub-title text-white'>Withdraw</span></p>

                    <div className='mb-4 d-flex align-items-center'>
                        <div style={{
                            marginRight: '9px'
                        }}
                            role='button'
                            onClick={() => {
                                navigate('/transactions')
                            }}
                        >
                            <img src={backArrow} alt='icon' />
                        </div>
                        <h4 className='page-details mb-0'>Withdraw</h4>

                    </div>

                    <div className="third-row analytics-card content-table withdraw">



                        <Row>
                            <Col sm='12' md='7'>
                                <form
                                    className='upload-content__form rss-input__form w-100 overflow-hidden'
                                    onSubmit={handleSubmit(onSubmitHandler)}
                                >
                                    <Row className='gx-3' style={{
                                        margin: 'auto'
                                    }}>

                                        <Col sm='12' md='12'>
                                            <div className='mb-4' role='button'>

                                                <div className=' cstm-select__aacount-input position-relative' onClick={() => {
                                                    toggleUserBanks()
                                                }}>
                                                    {
                                                        selectedAccount
                                                            ?
                                                            <div className=' d-flex align-items-center justify-content-between' role='button' >
                                                                <p className='user-bank_details mb-0'> <span className=''>{bankList?.bank_name}</span></p>
                                                                <p className='user-bank_details mb-0'>{bankList?.account_number}</p>

                                                            </div> :
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <p className='cstm-select__placeholder'>Select Account</p>
                                                                <div>
                                                                    <img src={select} alt='icon' />
                                                                </div>
                                                            </div>
                                                    }
                                                    {
                                                        showUserBanks && <div className='position-absolute user-saved__bank-list'>
                                                            <div className='user-saved-bank w-75'>

                                                                <div className=' d-flex align-items-center justify-content-between mb-3' role='button' onClick={() => {
                                                                    setSelectedAccount(1)
                                                                    toggleUserBanks()
                                                                }}>
                                                                    <p className='user-bank_details mb-0'> <span className=''>{bankList?.bank_name}</span></p>
                                                                    <p className='user-bank_details mb-0'>{bankList?.account_number}</p>
                                                                </div>

                                                            </div>
                                                            <div className='w-75 px-md-5 px-0 mt-4 mb-3 withdraw-outline__btn-wrapper'>
                                                                <button className='btn withdraw-outline__btn w-100' type='submit'
                                                                    onClick={(e) => {
                                                                        e.preventDefault()
                                                                        setUserType(0)
                                                                    }}
                                                                >

                                                                    Withdraw to a new account
                                                                </button>
                                                            </div>

                                                        </div>
                                                    }

                                                </div>

                                            </div>

                                            <div className='mb-4  no-border'>
                                                {
                                                    errors.amount ? <label className='text-danger '>{errors.amount?.message}</label> : <label className=''>Enter Amount (NGN)</label>
                                                }
                                                <input
                                                    // type='number'
                                                    className={`form-control mb-2 w-100 ${errors.amount ? 'border-danger' : ''}`}
                                                    placeholder='Amount'
                                                    {...register("amount")}
                                                    value={watch('amountFormatted', '')}
                                                    onChange={handleAmountChange}
                                                    defaultValue={0}

                                                />
                                                {/* <input
                                                    className={`form-control mb-2 w-100 ${errors.amount ? 'border-danger' : ''}`}
                                                    placeholder='Amount'
                                                    {...register("amount")}
                                                /> */}
                                                <div className='user-withdrawable__amount'>
                                                    {`Withdrawable Balance (NGN) : ₦ ${Number(walletBalance ?? 0).toLocaleString()}`}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>


                                    <div className=' rss-upload-button__wrapper no-border'>
                                        <div className=''>
                                            <button className='btn auth-btn ' type='submit'
                                                onClick={(e) => {
                                                    // e.preventDefault()
                                                    // toggleTransactionDetails()
                                                }}
                                                disabled={loading}
                                            >
                                                {
                                                    loading ?
                                                        <ButtonLoader /> :
                                                        'Withdraw'
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </Col>

                        </Row>
                    </div>

                </div >
            }

            <TransactionDetails
                isOpen={transactionDetails}
                toggle={toggleTransactionDetails}
                userType={has_pin}
                bankList={bankList}
                amount={amount}
            />
        </>
    )
}

export default Withdraw