import React, { useState } from 'react'
import { Modal } from 'reactstrap'
import BankSetSuccess from './BankSetSuccesful'
import { useMutation } from 'react-query'
import Client from '../../../../client'
import { toast } from 'react-toastify';
import ButtonLoader from '../../../../components/ButtonLoader'


const BankDetails = ({ isOpen, toggle, setSection, bankDetails }) => {
    const { bank_name, bank_details: { account_name, account_number } } = bankDetails;
    const [addBank, setAddBank] = useState(false)

    const toggleAddBank = () => {
        setAddBank(!addBank)
    }

    const { mutate: addBankDetails, isLoading } = useMutation(Client.profile.set_bank_details, {
        onSuccess: (data) => {
            if (data.error) {
                toast.error(data.error.message)
                // navigate('/auth/verify-login')
            } else {
                toggle()
                toggleAddBank()

            }

        },
        onError: (error) => {
            if (error.response) return toast.error(error.response.data.error.message)
            else if (error.data) return toast.error(error.data.message)
            else return toast.error(error.message)
            // navigate('/auth/verify-login')

        }
    })

    const setBankDetails = () => {
        const payload = {
            bank_name,
            account_number,
            account_name
        }
        addBankDetails(payload)
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={toggle}
                className='modal-dialog-centered '
                contentClassName="upload-success__modal_content-wrapper  "
            >
                <div className='analytics-card transaction__modal'>
                    <h3 className='confirm-transaction__modal_text text-center'>Confirm to add bank</h3>
                    <p className='confirm-transaction__modal_sub-text' >Are you sure you want to add this bank to your Genti profile?</p>

                    <div className='transactions-details__wrapper'>

                        <div className='d-flex align-items-center justify-content-between transaction_details_container'>
                            <p className='transaction_details_name mb-0'>Bank Name</p>
                            <p className='transaction_details_value m-0'>{bank_name}</p>
                        </div>
                        <div className='d-flex align-items-center justify-content-between transaction_details_container'>
                            <p className='transaction_details_name mb-0'>Account Number</p>
                            <p className='transaction_details_value m-0'>{account_number}</p>
                        </div><div className='d-flex align-items-center justify-content-between transaction_details_container'>
                            <p className='transaction_details_name mb-0'>Account Name</p>
                            <p className='transaction_details_value m-0'>{account_name}</p>
                        </div>

                    </div>
                    <div>

                        <div className=' d-flex align-items-center justify-content-between'>
                            <div className='w-100 ' style={{
                                marginRight: '9px'
                            }}>
                                <button className='btn  w-100 cancel-btn' type='submit'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        toggle()
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                            <div className='w-100 ' style={{
                                marginLeft: '9px'
                            }}>
                                <button className='btn auth-btn w-100' type='submit'
                                    disabled={isLoading}

                                    onClick={(e) => {
                                        e.preventDefault()
                                        setBankDetails()
                                    }}
                                >
                                    {
                                        isLoading ?
                                            <ButtonLoader /> :
                                            'Add'
                                    }
                                </button>
                            </div>


                        </div>

                    </div>
                </div>

            </Modal >
            <BankSetSuccess
                toggle={toggleAddBank}
                isOpen={addBank}
                setSection={setSection}
                bank_name={bank_name}
                account_number={account_number}
            />
        </>

    )
}

export default BankDetails