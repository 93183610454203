import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import Select from '../../../../components/SelectInput'
import ButtonLoader from '../../../../components/ButtonLoader'
import { useForm, useController } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { bankDetailsValidation } from '../../../../utils/form_validation'
import BankDetails from '../Modal/BankDetails'
import { useQuery, useMutation } from 'react-query'
import Client from '../../../../client'
import { toast } from 'react-toastify';







const Withdraw = ({ setSection }) => {
    const navigate = useNavigate()
    const [bankDetails, showBankDetails] = useState(false)
    const [bankList, setBankList] = useState([])
    const [bank_details, setBankDetails] = useState(null)
    const [bank_name, setBankName] = useState(null)

    const loading = false;
    const { register, handleSubmit, formState: { errors }, control } = useForm({
        resolver: yupResolver(bankDetailsValidation),
    });


    const { field: { value: bank, onChange: typeOnChange, ...restTypeField } } = useController({ name: 'bank', control });


    const toggleBankDetails = () => {
        showBankDetails(!bankDetails)
    }




    useQuery(['bank-list'], () => Client.transactions.get_bank_list(), {
        onSuccess: (data) => {
            setBankList(data.data)
        }
    })

    const { mutate: verifyBankDetails, isLoading } = useMutation(Client.transactions.verify_bank_details, {
        onSuccess: (data) => {
            if (data.error) {
                toast.error(data.error.message)
                // navigate('/auth/verify-login')
            } else {
                setBankDetails(data.data)
                toggleBankDetails()
            }

        },
        onError: (error) => {
            if (error.response) return toast.error(error.response.data.error.message)
            else if (error.data) return toast.error(error.data.message)
            else return toast.error(error.message)
            // navigate('/auth/verify-login')

        }
    })

    const onSubmitHandler = (data) => {
        // Cookies.set("CREATOR_RSS_LINK", data.rss)
        // setRSSUploadedLink(data.rss)
        const { bank: { bank_code, bank_name }, account_number } = data
        setBankName(bank_name)
        const payload = {
            bank_code,
            account_number
        }
        verifyBankDetails(payload)
        // handleUpload(data)
    };

    return (
        <>
            {


                <div>



                    <Row>
                        <Col sm='12' md='8'>
                            <form
                                className='upload-content__form rss-input__form w-100 overflow-hidden mt-2'
                                onSubmit={handleSubmit(onSubmitHandler)}
                            >
                                <Row className='gx-3' style={{
                                    margin: 'auto'
                                }}>

                                    <Col sm='12' md='12'>
                                        <div className=''>
                                            {
                                                errors.bank ? <label className='text-danger auth-label'>{errors.bank?.message}</label> : <label className=''>Select Bank</label>
                                            }
                                            <Select
                                                className={`mb-4 form-control rss-type__select cstm-react__select__wrapper w-100 ${errors.bank_code ? 'error_detected' : ""}`}
                                                placeholder='Select Bank'
                                                options={bankList ?
                                                    bankList.map(el => (
                                                        {
                                                            label: el?.bank_name,
                                                            value: el
                                                        }
                                                    )
                                                    ) : []
                                                }

                                                onChange={option => typeOnChange(option ? option.value : option)}
                                                {...restTypeField}
                                            />
                                        </div>
                                        <div className='mb-4  no-border'>
                                            {
                                                errors.account_number ? <label className='text-danger '>{errors.account_number?.message}</label> : <label className=''>Account Number</label>
                                            }
                                            <input
                                                type='number'
                                                className={`form-control w-100 ${errors.account_number ? 'border-danger' : ''}`}
                                                placeholder='Account Number'
                                                {...register("account_number")}
                                            />
                                        </div>

                                    </Col>
                                </Row>


                                <div className=' rss-upload-button__wrapper no-border'>
                                    <div className=''>
                                        <button className='btn auth-btn ' type='submit'

                                            disabled={loading || isLoading}
                                        >
                                            {
                                                loading || isLoading ?
                                                    <ButtonLoader /> :
                                                    'Add Bank'
                                            }
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </Col>

                    </Row>

                </div >
            }

            {
                bank_details && bank_name && <BankDetails
                    isOpen={bankDetails}
                    toggle={toggleBankDetails}
                    bankDetails={{ bank_name, bank_details }}
                    userType={0}
                    setSection={setSection}
                />
            }

        </>
    )
}

export default Withdraw