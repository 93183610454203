import React, { useState } from 'react'
import { Modal } from 'reactstrap'
import OtpForm from '../../../components/OTP'
import pinIcon from '../../../assets/img/pin.svg'
import TransactionSuccesful from './TransactionSuccesful'
import { useMutation } from 'react-query'
import Client from '../../../client'
import { toast } from 'react-toastify'
import ButtonLoader from '../../../components/ButtonLoader'


const Pin = ({ isOpen,
    toggle,
    userType,
    withdraw,
    toggleTransactionSuccess,
    transactionSuccessful,
    withdrawing,
}) => {
    const [otp, setOtp] = useState('')
    const [confirm_otp, setConfirmOtp] = useState('')



    const { mutate: setPin, isLoading } = useMutation(Client.profile.set_transaction_pin, {
        onSuccess: (data) => {
            toast.success(data.message)
            withdraw(otp)
        },
        onError: (err) => {
        }
    })

    const handlePinSetup = () => {
        if (otp.length === 4 && confirm_otp.length === 4) {
            const data = {
                confirm_transaction_pin: confirm_otp,
                transaction_pin: otp
            }
            setPin(data)
        }
    }


    return (
        <>
            {
                userType === 0 ?
                    <Modal
                        isOpen={isOpen}
                        toggle={toggle}
                        className='modal-dialog-centered '
                        contentClassName="upload-success__modal_content-wrapper  "
                    >
                        <div className='analytics-card transaction__modal position-relative'>

                            <h3 className='confirm-transaction__modal_text text-center'>Set Transaction PIN</h3>
                            <p className='text-center mb-0 set-transaction_pin__guide'>Please set your 4-digit PIN for transactions</p>
                            <div className='px-4'>
                                <form className='set-pin__form'>
                                    <div className='mb-3'>
                                        <label className='px-4 mb-1 set_transaction__label'>Pin</label>
                                        <OtpForm otp={otp} setOtp={setOtp} />
                                    </div>

                                    <div className='mb-3'>
                                        <label className='px-4 mb-1 set_transaction__label'>Re-enter PIN</label>
                                        <OtpForm otp={confirm_otp} setOtp={setConfirmOtp} />
                                    </div>



                                </form>
                                <div className=' d-flex align-items-center justify-content-between'>

                                    <button className='btn auth-btn w-100' type='submit'
                                        onClick={(e) => {
                                            e.preventDefault()
                                            handlePinSetup()

                                        }}
                                        disabled={isLoading}
                                    >
                                        {
                                            !isLoading ? 'Set' : <ButtonLoader />
                                        }
                                    </button>
                                </div>
                            </div>
                            <div className='position-absolute transactions-pin__icon'>
                                <img src={pinIcon} alt='icon' />

                            </div>
                        </div>

                    </Modal > :
                    <Modal
                        isOpen={isOpen}
                        toggle={toggle}
                        className='modal-dialog-centered '
                        contentClassName="upload-success__modal_content-wrapper  "
                    >
                        <div className='analytics-card transaction__modal position-relative'>

                            <h3 className='confirm-transaction__modal_text text-center'>Transaction PIN</h3>
                            <p className='text-center mb-0 set-transaction_pin__guide'>Enter your 4-digit PIN to confirm this transaction</p>
                            <div className='px-4'>
                                <form className='set-pin__form'>
                                    <div className='mb-3'>
                                        <OtpForm otp={otp} setOtp={setOtp} />
                                    </div>





                                </form>
                                <div className=' d-flex align-items-center justify-content-between'>

                                    <button className='btn auth-btn w-100' type='submit'
                                        onClick={(e) => {
                                            e.preventDefault()
                                            // toggle()
                                            // toggleTransactionSuccess()
                                            withdraw(otp)
                                        }}
                                        disabled={withdrawing || otp.length < 4}
                                    >
                                        {
                                            !withdrawing ? 'Done' : <ButtonLoader />
                                        }
                                    </button>
                                </div>
                            </div>
                            <div className='position-absolute transactions-pin__icon'>
                                <img src={pinIcon} alt='icon' />

                            </div>
                        </div>

                    </Modal >
            }


            <TransactionSuccesful
                isOpen={transactionSuccessful}
                toggle={toggleTransactionSuccess}
            />
        </>
    )
}

export default Pin