import React, { useState, useRef } from "react";
import backArrow from "../../../assets/img/backArrow.svg";
import { useNavigate } from "react-router-dom";
import showIcon from "../../../assets/img/Show.svg";
import t13 from "../../../assets/img/t13.svg";
import search from "../../../assets/img/searchIcon.svg";
import sort from "../../../assets/img/filterTable.svg";
import EmptyContent from "../Empty";
import filter from "../../../assets/img/filter-list.svg";
import activeFilter from "../../../assets/img/activeFilter.svg";
import menu from "../../../assets/img/menu.svg";
import downloadReport from "../../../assets/img/downloadReport.svg";
import { useQuery } from "react-query";
import Client from "../../../client";
import moment from "moment";
import truncate from "truncate";
import MobileTile from "./MobileTile";
import Pagination from "../../../components/Pagination";
import ButtonLoader from "../../../components/ButtonLoader";

const RecentEarnings = () => {
  const navigate = useNavigate();
  const [reports, setReports] = useState([1]);
  const [filterContent, setFilterContent] = useState(false);
  const [editContent, setEditContent] = useState(null);
  const [title_earnings, setTitleEarnings] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [order, setOrder] = useState("asc");
  const [orderParam, setOrderParam] = useState(null);

  const { isLoading, isRefetching, isFetching } = useQuery(
    ["title_earnings", activePage, orderParam, order],
    () =>
      Client.transactions.get_title_earnings({
        page: activePage,
        orderParam: orderParam,
        order: orderParam ? order : null,
      }),
    {
      onSuccess: (data) => {
        setTitleEarnings(data.data);
      },
    }
  );

  const {
    data: titleEarnings,
    total,
    current_page,
    per_page,
  } = title_earnings || {};

  const earnings_Ref = useRef(null);

  const changeContent = (e) => {
    setActivePage(e);
    // window.scrollTo(0, 0)
    earnings_Ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      {
        <div>
          <p className="page-title">
            Transactions /
            <span className="page-sub-title text-white">Recent Earnings</span>
          </p>

          <div className="mb-4 d-flex align-items-center">
            <div
              style={{
                marginRight: "9px",
              }}
              role="button"
              onClick={() => {
                navigate("/transactions");
              }}
            >
              <img src={backArrow} alt="icon" />
            </div>
            <h4 className="page-details mb-0">Recent Earnings</h4>
          </div>
          <div className="reports-card_wrapper" ref={earnings_Ref}>
            <div className="third-row analytics-card content-table withdraw">
              {
                <>
                  <div className="table-details__wrapper">
                    <div className="d-md-flex d-block align-items-center justify-content-between ">
                      <div className="d-flex align-items-center mb-0">
                        <h4 className="table-title pt-2">All Earnings</h4>
                        <div className="d-none d-md-flex search-content__wrapper">
                          <div className="pb-1">
                            <img src={search} alt="icon" />
                          </div>
                          <input
                            className="search-content__input"
                            placeholder="Search Transaction"
                            // defaultValue={searchValue} onChange={(e) => {
                            //     setSearchValue(e.target.value.toLowerCase())

                            // }}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="d-flex d-md-none search-content__wrapper ps-1">
                          <div className="pb-1">
                            <img src={search} alt="icon" />
                          </div>
                          <input
                            className="search-content__input"
                            placeholder="Search Transaction"
                            // defaultValue={searchValue} onChange={(e) => {
                            //     setSearchValue(e.target.value.toLowerCase())

                            // }}
                          />
                        </div>
                        {/* <div className='download-report_img__wrapper d-flex align-items-center justify-content-center'>
                                                    <img src={downloadReport} alt='download_report' className='download-report_img' />
                                                </div> */}
                        {filterContent ? (
                          <div
                            className="filtering-active__wrapper filter-icon__wrapper d-flex justify-content-center align-items-center"
                            onClick={() => setFilterContent(false)}
                            role="button"
                          >
                            <img role="button" src={activeFilter} alt="icon" />
                            <p className="filter-content__text pt-1 mb-1">
                              Filter
                            </p>
                          </div>
                        ) : (
                          <div
                            role="button"
                            className="filter-icon__wrapper d-flex justify-content-center align-items-center"
                            onClick={() => {
                              setFilterContent(true);
                            }}
                          >
                            <img src={filter} alt="icon" />
                            <p className="filter-content__text pt-1 mb-1">
                              Filter
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="analytics-card_details h-100 d-none d-md-block">
                    {isLoading || isRefetching || isFetching ? (
                      <div
                        className="d-flex align-items-center justify-content-center position-absolute"
                        style={{
                          height: "90%",
                          width: "calc(100% - 60px)",
                        }}
                      >
                        <ButtonLoader />
                      </div>
                    ) : (
                      <>
                        {reports?.length > 0 ? (
                          <div className="">
                            <table className="table mb-0 transaction-table text-white">
                              <thead className="thead-light pb-3">
                                <tr>
                                  <th>
                                    <div className="d-flex align-items-center">
                                      <p className="mb-0">Content Name</p>
                                      <div
                                        className="sort-icon__wrapper cursor-pointer"
                                        onClick={() => {
                                          changeContent(1);
                                          setOrderParam("content_name");
                                          setOrder(
                                            order === "asc" ? "desc" : "asc"
                                          );
                                        }}
                                      >
                                        <img src={sort} alt="icon" />
                                      </div>
                                    </div>
                                  </th>
                                  <th>
                                    <div className="d-flex align-items-center">
                                      <p className="mb-0">Earned</p>
                                      <div
                                        className="sort-icon__wrapper cursor-pointer"
                                        onClick={() => {
                                          changeContent(1);
                                          setOrderParam("amount");
                                          setOrder(
                                            order === "asc" ? "desc" : "asc"
                                          );
                                        }}
                                      >
                                        <img src={sort} alt="icon" />
                                      </div>
                                    </div>
                                  </th>
                                  <th className="text-center">
                                    <div className="d-flex align-items-center">
                                      <p className="mb-0">Hours Listened</p>
                                      <div
                                        className="sort-icon__wrapper cursor-pointer"
                                        onClick={() => {
                                          changeContent(1);
                                          setOrderParam("plays");
                                          setOrder(
                                            order === "asc" ? "desc" : "asc"
                                          );
                                        }}
                                      >
                                        <img src={sort} alt="icon" />
                                      </div>
                                    </div>
                                  </th>
                                  <th className="text-center">
                                    <div className="d-flex align-items-center">
                                      <p className="mb-0">No. of Listeners</p>
                                      <div
                                        className="sort-icon__wrapper cursor-pointer"
                                        onClick={() => {
                                          changeContent(1);
                                          setOrderParam("total_time_listened");
                                          setOrder(
                                            order === "asc" ? "desc" : "asc"
                                          );
                                        }}
                                      >
                                        <img src={sort} alt="icon" />
                                      </div>
                                    </div>
                                  </th>

                                  <th>
                                    <div className="d-flex align-items-center">
                                      <p className="mb-0">Completion Rate</p>
                                      <div
                                        className="sort-icon__wrapper cursor-pointer"
                                        onClick={() => {
                                          changeContent(1);
                                          setOrderParam("completion_rate");
                                          setOrder(
                                            order === "asc" ? "desc" : "asc"
                                          );
                                        }}
                                      >
                                        <img src={sort} alt="icon" />
                                      </div>
                                    </div>
                                  </th>
                                  <th>
                                    <div className="d-flex align-items-center">
                                      <p className="mb-0">Rating</p>
                                      <div
                                        className="sort-icon__wrapper cursor-pointer"
                                        onClick={() => {
                                          changeContent(1);
                                          setOrderParam("ratings");
                                          setOrder(
                                            order === "asc" ? "desc" : "asc"
                                          );
                                        }}
                                      >
                                        <img src={sort} alt="icon" />
                                      </div>
                                    </div>
                                  </th>

                                  <th>
                                    <div className="d-flex align-items-center">
                                      <p className="mb-0">Date</p>
                                      <div
                                        className="sort-icon__wrapper cursor-pointer"
                                        onClick={() => {
                                          changeContent(1);
                                          setOrderParam("created_at");
                                          setOrder(
                                            order === "asc" ? "desc" : "asc"
                                          );
                                        }}
                                      >
                                        <img src={sort} alt="icon" />
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                              </thead>

                              <tbody className="content-table-body transactions-earnings__table">
                                {titleEarnings?.map((el) => (
                                  <tr>
                                    <td className="td-text__container ">
                                      <div className="d-flex align-items-center">
                                        <div className="release-img_wrapper my-0">
                                          <img
                                            src={el?.image?.location_image}
                                            alt="release-img"
                                          />
                                        </div>
                                        <p className="td-text__container recent-earnings__value my-0 mx-3">
                                          {truncate(el?.content_name, 15)}
                                        </p>
                                      </div>
                                    </td>
                                    <td className="td-text__container ">
                                      {el?.currency_group == "2" ? "₦" : "$"}
                                      {` ${Number(
                                        el?.amount
                                      ).toLocaleString()}`}
                                    </td>
                                    <td className="td-text__container text-center">
                                      {el?.hours_listened}
                                    </td>
                                    <td className="td-text__container text-center">
                                      {el?.plays}
                                    </td>
                                    <td className="td-text__container ">
                                      <div className="mx-5">
                                        {`${el?.completion_rate}%`}
                                      </div>
                                    </td>
                                    <td className="td-text__container">
                                      <div className="">{el?.ratings}</div>
                                    </td>{" "}
                                    <td className="td-text__container ">
                                      {moment(el?.created_at).format("L")}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <div>
                            <div className="d-flex justify-content-between align-items-center cstm-table__head-wrapper">
                              <div>
                                <div className="d-flex align-items-center cstm-table__head">
                                  <p className="mb-0">Content Name</p>
                                  {/* <div className='sort-icon__wrapper'>
                                                            <img src={sort} alt='icon' />
                                                        </div> */}
                                </div>
                              </div>
                              <div className="cstm-table__head">Author</div>
                              {/* <div className='cstm-table__head'>Sub Genre.</div> */}
                              <div className="cstm-table__head">
                                Pay Category
                              </div>
                              <div className="cstm-table__head">
                                Hours Listened
                              </div>
                              <div className="cstm-table__head">
                                <div className="d-flex align-items-center cstm-table__head">
                                  <p className="mb-0">No. of Listeners</p>
                                  {/* <div className='sort-icon__wrapper'>
                                                            <img src={sort} alt='icon' />
                                                        </div> */}
                                </div>
                              </div>
                              <div className="cstm-table__head">
                                Completion Rate
                              </div>

                              <div className="cstm-table__head">Rating</div>
                              <div className="cstm-table__head">
                                No. of Shares
                              </div>
                              <div className="cstm-table__head">
                                No. of Favoritess
                              </div>
                              <div className="cstm-table__head last">
                                Action
                              </div>
                            </div>
                            <EmptyContent />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="d-block d-md-none">
                    {titleEarnings?.map((el) => (
                      <MobileTile content={el} />
                    ))}
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      }
      {total && total > 10 ? (
        <div className="d-flex justify-content-center align-items-center">
          <Pagination
            onPageChange={changeContent}
            totalCount={total}
            currentPage={current_page}
            pageSize={Number(per_page)}
          />
        </div>
      ) : null}
    </>
  );
};

export default RecentEarnings;
