import React from 'react'
import AuthLayout from '../../layout/Auth'
import arrowIcon from '../../assets/img/arrow.svg'
import { useNavigate } from 'react-router-dom'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { forgotPasswordValidation } from '../../utils/form_validation';
import { useMutation } from 'react-query';
import Client from '../../client'
import { toast } from 'react-toastify';
import ButtonLoader from '../../components/ButtonLoader';
import Cookies from 'js-cookie';


const Login = () => {
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(forgotPasswordValidation),
    });


    const { mutate: verifyEmail, isLoading } = useMutation(Client.auth.forgot_password, {
        onSuccess: (data) => {
            if (data.error) {
                toast.error(data.error.message)
            } else {
                // toast.success(data.data.message)
                reset();
                navigate('/auth/verify-OTP')
            }
        },
        onError: (error) => {
            if (error.response) return toast.error(error.response.data.error.message)
            else if (error.data) return toast.error(error.data.message)
            else return toast.error(error.message)

        }
    })

    const onSubmitHandler = (data) => {
        Cookies.set("GENTI_RESET_EMAIL", data.verify)
        verifyEmail(data)
    };



    return (
        <AuthLayout>


            <form className='auth-form position-relative' autocomplete='off' onSubmit={handleSubmit(onSubmitHandler)} >

                <>
                    <div className='mb-3 '>
                        <h3 className='form-title'>Forgot Password</h3>
                    </div>
                    <div className='text-center '>
                        <p className='otp-text mb-0'>Please enter your email address</p>
                    </div>

                    <div className='password-reset-email'>
                        {
                            errors.verify ? <p className='text-danger auth-label mb-0'>{errors.verify?.message}</p> : <label className='auth-label'>Email Address</label>
                        }
                        <input
                            type='email'
                            autocomplete="off"
                            placeholder='account@email.com'
                            className={`auth-input form-control w-100 ${errors.verify ? 'border-danger' : ''} `}
                            {...register("verify")}
                        />
                    </div>


                    <div className='sign-in-btn'>
                        <button className='btn auth-btn w-100 ' disabled={isLoading} type='submit' >
                            {
                                isLoading ?
                                    <ButtonLoader /> :
                                    'Reset Password'
                            }
                        </button>
                    </div>
                </>







                <div className='back-icon d-flex align-items-center justify-content-center position-absolute' role='button' onClick={() => {
                    navigate('/auth/login')
                }}>
                    <img src={arrowIcon} alt='arrow' className='arrow-icon' />
                </div>

            </form>





        </AuthLayout>
    )
}

export default Login