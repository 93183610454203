import React from 'react'
import noImage from '../../../../assets/img/no-image.png'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

const EarningsTile = ({ content }) => {
    const navigate = useNavigate()
    return (
        <div className='report-mobile-tile' onClick={() => {
            navigate(`/report/details/${content?.title_id}`)
        }}>
            <div className='report-mobile-tile_header'>
                <div className=''>
                    <div className='d-flex'>
                        <div className='pe-3'>
                            <img className='report-tile__img' src={content?.image?.location_image ?? noImage} />
                        </div>
                        <div>
                            <h3 className='report-name '>{content?.content_name}</h3>

                            {/* <p className='tile-key mb-0'>Ratings: <span className='tile-value'>{content?.ratings ?? 0}</span></p> */}
                        </div>
                    </div>




                </div>
            </div>
            <div className='tile-details__wrapper'>
                <div className='tile-key-value_wrapper'>
                    <h3 className='tile-details__key'>Earned (₦)</h3>
                    <h3 className='tile-details__value'>{Number(content?.amount ?? 0).toLocaleString()}</h3>
                </div>
                <div className='tile-key-value_wrapper'>
                    <h3 className='tile-details__key'>Hours listened</h3>
                    <h3 className='tile-details__value'>{content?.total_time_listened ? content?.total_time_listened : content?.hours_listened ?? 0}</h3>
                </div> <div className='tile-key-value_wrapper'>
                    <h3 className='tile-details__key'>No. of listeners</h3>
                    <h3 className='tile-details__value'>{content?.plays ?? 0}</h3>
                </div>
                <div className='tile-key-value_wrapper'>
                    <h3 className='tile-details__key'>Completion rate</h3>
                    <h3 className='tile-details__value'>{content?.completion_rate ?? 0}</h3>
                </div>
                <div className='tile-key-value_wrapper'>
                    <h3 className='tile-details__key'>Ratings</h3>
                    <h3 className='tile-details__value'>{content?.ratings ?? 0}</h3>
                </div>
                <div className='tile-key-value_wrapper'>
                    <h3 className='tile-details__key'>Date</h3>
                    <h3 className='tile-details__value'>{moment(content?.created_at).format('L')}</h3>
                </div>

            </div>
        </div>
    )
}

export default EarningsTile