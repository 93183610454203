import React, { useState } from 'react'
import { Row, Col } from 'reactstrap'
import PersonalDetails from './PersonalDetails'
import Password from './Password'
import TransactionPin from './Transaction'
import Bank from './BankDetails'



const Profile = () => {
    const [activeTab, setActiveTab] = useState('personal_details')

    return (
        <div>
            <Row className='gy-3'>
                <Col sm='12' md='4'>
                    <div className="analytics-card  profile-settings__menu_container ">
                        <h3 className='profile-settings_text'>SETTINGS</h3>
                        <ul className='profile-settings__menu_wrapper mb-5 pb-5'>
                            <li className={`profile-settings__menu_link ${activeTab === 'personal_details' ? 'active' : ""}`}
                                onClick={() => {
                                    setActiveTab('personal_details')
                                }}
                            >Personal Details</li>
                            <li className={`profile-settings__menu_link ${activeTab === 'password' ? 'active' : ""}`}
                                onClick={() => {
                                    setActiveTab('password')
                                }}

                            >Password</li>
                            <li className={`profile-settings__menu_link ${activeTab === 'transaction' ? 'active' : ""}`}
                                onClick={() => {
                                    setActiveTab('transaction')
                                }}> Transaction PIN</li>
                            <li className={`profile-settings__menu_link ${activeTab === 'bank_details' ? 'active' : ""}`}
                                onClick={() => {
                                    setActiveTab('bank_details')
                                }}>Bank Details</li>
                            <li className={`profile-settings__menu_link ${activeTab === 'terms' ? 'active' : ""}`}
                                onClick={() => {
                                    setActiveTab('terms')
                                }}>Terms & Conditions</li>
                            <li className={`profile-settings__menu_link ${activeTab === 'about' ? 'active' : ""}`}
                                onClick={() => {
                                    setActiveTab('about')
                                }}>About Genti</li>

                        </ul>

                    </div>
                </Col>
                <Col sm='12' md='8'>
                    <div className="analytics-card withdraw ">
                        {activeTab === 'personal_details' && <PersonalDetails />}
                        {activeTab === 'password' && <Password />}
                        {activeTab === 'transaction' && <TransactionPin />}
                        {activeTab === 'bank_details' && <Bank />}




                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Profile