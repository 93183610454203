import React, { useState } from 'react';
import active from '../../assets/img/acc2.svg'
import inactive from '../../assets/img/acc1.svg'


const Accordion = ({ title, content, isActive, setIsActive, index }) => {

    return (
        <div className={`accordion-item ${isActive ? "active" : ""} cursor-pointer `} onClick={() => {
            setIsActive(index)
        }}>
            <div className="accordion-title d-flex align-items-center justify-content-between" >
                <div className='accordion-title__wrapper'>{title}</div>
                <div>{isActive ? <img src={active} alt='icon' /> : <img src={inactive} alt='icon' />}</div>
            </div>
            {isActive && <div className="accordion-content">{content}</div>}
        </div>
    );
};

export default Accordion;