import React, { useEffect, useState } from "react";
import backArrow from "../../../assets/img/backArrow.svg";
import { useNavigate } from "react-router-dom";
import t13 from "../../../assets/img/no-image.png";
import usee from "../../../assets/img/usee.svg";
import Star from "../../../assets/img/Stareport.svg";
import Discount from "../../../assets/img/Discount.svg";
import Graph from "../../../assets/img/Graph.svg";
import Heart from "../../../assets/img/Heart.svg";
import Upload from "../../../assets/img/Upload.svg";
import sort from "../../../assets/img/filterTable.svg";
import search from "../../../assets/img/searchIcon.svg";
import filter from "../../../assets/img/filter-list.svg";
import activeFilter from "../../../assets/img/activeFilter.svg";
import downloadReport from "../../../assets/img/downloadReport.svg";
import { useParams } from "react-router-dom";
import Client from "../../../client";
import { useQuery } from "react-query";
import EmptyContent from "./Empty";
import { scrollToTop, getInitials } from "../../../utils/helpers";
import ReportTile from "../MobileTile";

const Details = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const [reportDetails, setReportDetails] = useState(null);

  useEffect(() => {
    scrollToTop();
  }, []);

  useQuery(["Report_details"], () => Client.reports.get_single_report(id), {
    onSuccess: (data) => {
      setReportDetails(data.data);
    },
  });

  const [filterContent, setFilterContent] = useState(false);

  return (
    <div>
      <div>
        <p className="page-title">
          Report/<span className="page-sub-title text-white">View Details</span>
        </p>

        <div className="mb-4 d-flex align-items-center">
          <div
            style={{
              marginRight: "9px",
            }}
            onClick={() => {
              navigate("/report");
            }}
            role="button"
          >
            <img src={backArrow} alt="icon" />
          </div>
          <h4 className="page-details mb-0">View Details</h4>
        </div>
      </div>
      <div className="report-details-wrapper ">
        <div className="second-row analytics-card withdraw ">
          <div className="analytics-card_details h-100">
            <div className="d-flex">
              <div className="">
                <img
                  src={reportDetails?.title?.image?.location_image ?? t13}
                  alt="report-img"
                  className="report-details__img"
                />
              </div>
              <div className="report-content_details__wrapper w-100">
                <h3 className="report-content_details__title">
                  {reportDetails?.title?.content_name}
                </h3>
                <p className="report-content_details__episode-count">{`${
                  reportDetails?.episodes?.length ?? 0
                } Episodes`}</p>
                <div className="d-flex align-items-center report-details_author_wrapper">
                  <div className="report-details_author_img__wrapper ">
                    <p className="mb-0 user-initials__wrapper report-details_author_img">
                      {getInitials(reportDetails?.title?.author_name)}
                    </p>
                    {/* <img src={usee} className='report-details_author_img' alt='author_img' /> */}
                  </div>
                  <p className="report-details_author_name mb-0">
                    {reportDetails?.title?.author_name}
                  </p>
                </div>
                <div className="report-details_stats__wrapper w-100 d-md-flex d-none">
                  <div className="d-flex align-items-center report-details_stats__container first_stat">
                    <div className="report-details_stat_img__wrapper ">
                      <img
                        src={Discount}
                        className="report-details_stat_img"
                        alt="stat_img"
                      />
                    </div>
                    <p className="report-details_stat_title mb-0">
                      {`${reportDetails?.title?.episodes_unlocked}`} Episodes
                      Unlocked
                    </p>
                  </div>
                  <div className="d-flex align-items-center report-details_stats__container">
                    <div className="report-details_stat_img__wrapper">
                      <img
                        src={Star}
                        className="report-details_stat_img"
                        alt="stat_img"
                      />
                    </div>
                    <p className="report-details_stat_title mb-0">
                      {`${reportDetails?.title?.ratings ?? 0} Rating`}
                    </p>
                  </div>
                  <div className="d-flex align-items-center report-details_stats__container">
                    <div className="report-details_stat_img__wrapper">
                      <img
                        src={Upload}
                        className="report-details_stat_img"
                        alt="stat_img"
                      />
                    </div>
                    <p className="report-details_stat_title mb-0">
                      {`${reportDetails?.title?.shares ?? 0} Shares`}
                    </p>
                  </div>
                  <div className="d-flex align-items-center report-details_stats__container">
                    <div className="report-details_stat_img__wrapper">
                      <img
                        src={Graph}
                        className="report-details_stat_img"
                        alt="stat_img"
                      />
                    </div>
                    <p className="report-details_stat_title mb-0">
                      {`${
                        reportDetails?.title?.completion_rate ?? 0
                      } Completion Rate`}
                    </p>
                  </div>
                  <div className="d-flex align-items-center report-details_stats__container last_stat">
                    <div className="report-details_stat_img__wrapper">
                      <img
                        src={Heart}
                        className="report-details_stat_img"
                        alt="stat_img"
                      />
                    </div>
                    <p className="report-details_stat_title mb-0">
                      {`${reportDetails?.title?.favorites ?? 0} Favourites`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="report-details_stats__wrapper w-100 d-md-none d-block mt-4 mb-2 ">
              <div className="d-flex mb-3">
                {/* <div className="d-flex align-items-center report-details_stats__container first_stat">
                  <div className="report-details_stat_img__wrapper ">
                    <img
                      src={Discount}
                      className="report-details_stat_img"
                      alt="stat_img"
                    />
                  </div>
                  <p className="report-details_stat_title mb-0">
                    {`${
                      reportDetails?.title?.paywall === "1"
                        ? "Inside Paywall"
                        : "Outside Paywall"
                    }`}
                  </p>
                </div> */}
                <div className="d-flex align-items-center report-details_stats__container no-border">
                  <div className="report-details_stat_img__wrapper">
                    <img
                      src={Star}
                      className="report-details_stat_img"
                      alt="stat_img"
                    />
                  </div>
                  <p className="report-details_stat_title mb-0">
                    <span className="report-stats_value pe-1">
                      {reportDetails?.title?.ratings ?? 0}
                    </span>
                    {`Rating`}
                  </p>
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="d-flex align-items-center report-details_stats__container ps-0">
                  <div className="report-details_stat_img__wrapper">
                    <img
                      src={Upload}
                      className="report-details_stat_img"
                      alt="stat_img"
                    />
                  </div>
                  <p className="report-details_stat_title mb-0">
                    <span className="report-stats_value pe-1">
                      {reportDetails?.title?.shares ?? 0}
                    </span>
                    {`Shares`}
                  </p>
                </div>
                <div className="d-flex align-items-center report-details_stats__container no-border pe-0">
                  <div className="report-details_stat_img__wrapper">
                    <img
                      src={Graph}
                      className="report-details_stat_img"
                      alt="stat_img"
                    />
                  </div>
                  <p className="report-details_stat_title mb-0">
                    <span className="report-stats_value pe-1">
                      {reportDetails?.title?.completion_rate ?? 0}
                    </span>
                    {`Completion Rate`}
                  </p>
                </div>
              </div>

              <div className="d-flex align-items-center report-details_stats__container last_stat no-border ps-0">
                <div className="report-details_stat_img__wrapper">
                  <img
                    src={Heart}
                    className="report-details_stat_img"
                    alt="stat_img"
                  />
                </div>
                <p className="report-details_stat_title mb-0">
                  <span className="report-stats_value pe-1">
                    {reportDetails?.title?.favorites ?? 0}
                  </span>
                  {`Favourites`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="reports-card_wrapper">
        <div className="third-row analytics-card content-table withdraw ">
          {
            <>
              <div className="table-details__wrapper">
                <div className="d-md-flex d-block align-items-center justify-content-between ">
                  <div className="d-block d-md-flex align-items-center mb-0">
                    <h4 className="table-title pt-2">All Content</h4>
                    {/* <div className="search-content__wrapper d-md-flex d-none">
                      <div className="pb-1">
                        <img src={search} alt="icon" />
                      </div>
                      <input
                        className="search-content__input"
                        placeholder="Search Episode"
                        // defaultValue={searchValue} onChange={(e) => {
                        //     setSearchValue(e.target.value.toLowerCase())

                        // }}
                      />
                    </div> */}
                  </div>
                  {/* <div className="d-flex align-items-center">
                    <div className=" report-mobile_search search-content__wrapper d-md-none d-flex">
                      <div className="pb-1">
                        <img src={search} alt="icon" />
                      </div>
                      <input
                        className="search-content__input"
                        placeholder="Search Episode"
                        // defaultValue={searchValue} onChange={(e) => {
                        //     setSearchValue(e.target.value.toLowerCase())

                        // }}
                      />
                    </div>
                    <div className='download-report_img__wrapper d-flex align-items-center justify-content-center'>
                                            <img src={downloadReport} alt='download_report' className='download-report_img' />
                                        </div> 

                    {filterContent ? (
                      <div
                        className="filtering-active__wrapper filter-icon__wrapper d-flex justify-content-center align-items-center"
                        onClick={() => setFilterContent(false)}
                        role="button"
                      >
                        <img role="button" src={activeFilter} alt="icon" />
                        <p className="filter-content__text pt-1 mb-1">Filter</p>
                      </div>
                    ) : (
                      <div
                        role="button"
                        className="report-mobile-filter_wrapper filter-icon__wrapper d-flex justify-content-center align-items-center"
                        onClick={() => {
                          setFilterContent(true);
                        }}
                      >
                        <img src={filter} alt="icon" />
                        <p className="filter-content__text pt-1 mb-1">Filter</p>
                      </div>
                    )}
                  </div> */}
                </div>
              </div>
              <div className="analytics-card_details h-100 d-none d-md-block">
                {reportDetails?.episodes.length > 0 ? (
                  <div className="">
                    <table className="table mb-0 transaction-table text-white">
                      <thead className="thead-light pb-3">
                        <tr>
                          <th>
                            <div className="d-flex align-items-center">
                              <p className="mb-0">Episode Name</p>
                              {/* <div className="sort-icon__wrapper">
                                <img src={sort} alt="icon" />
                              </div> */}
                            </div>
                          </th>
                          {/* <th>
                                                        <div className='d-flex align-items-center'>
                                                            <p className='mb-0'>Hours Listened</p>
                                                            <div className='sort-icon__wrapper'>
                                                                <img src={sort} alt='icon' />
                                                            </div>

                                                        </div>

                                                    </th> */}
                          {/* <th>Sub Genre.</th> */}
                          <th className="text-center">Episode Unlocks</th>
                          <th className="text-center">Hours Listened</th>

                          <th className="text-center">No. of Listeners</th>
                          <th className="text-center">
                            <div className="d-flex align-items-center justify-content-center">
                              <p className="mb-0 text-center">
                                Completion Rate
                              </p>
                              {/* <div className="sort-icon__wrapper">
                                <img src={sort} alt="icon" />
                              </div> */}
                            </div>
                          </th>
                          <th className="text-center">
                            <div className="d-flex align-items-center justify-content-center">
                              <p className="mb-0 text-center">No. of Shares</p>
                              {/* <div className="sort-icon__wrapper">
                                <img src={sort} alt="icon" />
                              </div> */}
                            </div>
                          </th>
                          <th className="text-center">
                            <div className="d-flex align-items-center justify-content-center">
                              <p className="mb-0">No. of Favorites</p>
                              {/* <div className="sort-icon__wrapper">
                                <img src={sort} alt="icon" />
                              </div> */}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      {reportDetails?.episodes.map((el) => (
                        <tbody className="content-table-body">
                          <tr>
                            <td className="td-img__container">
                              <div className="d-flex align-items-center">
                                <div className="release-img_wrapper report-details_episode__img">
                                  <img src={t13} alt="release-img" />
                                </div>
                                <p className="td-text__container ">
                                  {el?.title}
                                </p>
                              </div>
                            </td>
                            <td className="td-text__container text-center">
                              {el?.total_unlocked}
                            </td>

                            <td className="td-text__container text-center">
                              {el?.hours_listened}
                            </td>
                            <td className="td-text__container text-center">
                              {el?.plays}
                            </td>
                            <td className="td-text__container text-center">
                              {`${el?.completion_rate}%`}
                            </td>
                            <td className="td-text__container text-center">
                              {el?.shares}
                            </td>
                            <td className="td-text__container text-center">
                              {el?.favourites}
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                ) : (
                  <EmptyContent />
                )}
              </div>
              <div className="d-block d-md-none report_mobile__wrapper">
                {reportDetails?.episodes?.length > 0 ? (
                  <>
                    {reportDetails?.episodes?.map((el, index) => (
                      <ReportTile key={index} content={el} />
                    ))}
                  </>
                ) : (
                  <EmptyContent />
                )}
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default Details;
