
// import backArrow from '../../../assets/img/backArrow.svg'
import RSS from './RSS'
import MP3 from './MP3'
// import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query';
import Client from '../../../client'
import { useState, useMutation } from 'react'
import ButtonLoader from '../../../components/ButtonLoader';

const Upload = () => {
    const params = useParams()
    const { id } = params;
    const [contentDetails, setContentDetails] = useState([])

    const { data: contents, isLoading: loading } = useQuery(
        ['content_details'], () => Client.content.single_content({ id: id }), {
        onSuccess: (data) => {
            setContentDetails(data.data)
        },
        onError: (err) => {
        },
        enabled: Boolean(id)
    }
    )


    const { data: types } = useQuery(['content_types'], () => Client.content.content_types())
    const { data: language } = useQuery(['content_language'], () => Client.content.content_language())
    const { data: genre } = useQuery(['content_genre'], () => Client.content.content_genre())
    const { data: sub_genre } = useQuery(['content_sub_genre'], () => Client.content.content_sub_genre())

    const { result: language_list } = language?.data || []
    const { result: genre_list } = genre?.data || []
    const { result: types_list } = types?.data || []
    const { result: sub_genre_list } = sub_genre?.data || []


    // const navigate = useNavigate()
    // const [contentType, setContentType] = useState('MP3')
    const contentType = 'MP3'
    return (
        loading ? <div
            className='d-flex align-items-center justify-content-center position-absolute'
            style={{
                height: '90%',
                width: "80%"

            }}>
            <ButtonLoader />
        </div> :
            <>
                {contentType === 'RSS' && <RSS />}
                {contentType === 'MP3' &&
                    <MP3
                        title={contentDetails}
                        loading={loading}
                        languageList={language_list}
                        genre={genre_list}
                        subGenre={sub_genre_list}
                        types={types_list}
                    />}
            </>


    )
}

export default Upload