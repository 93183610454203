
export const FAQs = [
    {
        "question": "What is the Genti Creator Portal?",
        "answer": "The Genti Creator Portal is a platform designed for creators to share their audio stories with listeners globally. It offers tools for content management, monetization, audience engagement, and real-time reporting of listens, earnings, and royalties."
    },
    {
        "question": "How do I upload my audio stories to the Genti App?",
        "answer": "Simply sign up or log in to the Creator Portal, navigate to the 'Content' section, and select 'Upload Content.' Follow the on-screen instructions to upload your chapters or episodes and provide necessary details."
    },
    {
        "question": "Can I customize my content on the portal?",
        "answer": "Yes, you can personalize your audio stories with custom cover images. You can upload your own images or let Genti create one for you."
    },
    {
        "question": "How can I monetize my content?",
        "answer": "The Genti Creator Portal offers flexible monetization options. You can offer free samples to attract listeners or provide exclusive paid content. You earn 50% of all coins spent on your content."
    },
    {
        "question": "How do I track my content's performance?",
        "answer": "The portal provides real-time reports on listens, engagement, earnings, and royalties. You can access detailed metrics in the reporting section of the dashboard."
    },
    {
        "question": "How can I withdraw my earnings?",
        "answer": "You can easily withdraw your earnings with our automated Naira withdrawal system powered by Pay Stack. Withdraw funds anytime with no minimum amount. For dollar earnings, withdrawals can be made to US-based accounts."
    },
    {
        "question": "What kind of support is available?",
        "answer": "Our dedicated customer support team is available to assist you with any questions or issues, from uploading content to managing your account."
    },
    {
        "question": "What are the benefits of using the Genti Creator Portal?",
        "answer": "The portal offers effortless content management, flexible monetization options, real-time performance tracking, seamless withdrawals, and dedicated support to help you succeed as a creator."
    },
    {
        "question": "How do I sign up for the Genti Creator Portal?",
        "answer": "Go to creator@gentimedia.com to start your registration process."
    },
    {
        "question": "Can I see how much my content is generating?",
        "answer": "Yes, you can monitor your earnings in real time. Navigate to the 'Transactions' section to view detailed information about your income in both Naira and USD."
    },
    {
        "question": "How does the portal help me engage my audience?",
        "answer": "Use captivating chapter summaries and enticing language to keep your audience eagerly awaiting your next update. The portal's tools help you craft engaging content that resonates with listeners."
    },
    {
        "question": "Ready to Start?",
        "answer": "Join the Genti Creator Portal today and bring your audio stories to life. Connect with a global audience and enjoy the benefits of real-time reporting and effortless content management. Sign up now at creator@gentimedia.com. Happy creating!"
    }
]