import React from "react";
import noImage from "../../../assets/img/no-image.png";
import truncate from "truncate";
import { useNavigate } from "react-router-dom";

const ReportTile = ({ content }) => {
  const navigate = useNavigate();
  return (
    <div
      className="report-mobile-tile"
      onClick={() => {
        navigate(`/report/details/${content?.title_id}`);
      }}
    >
      <div className="report-mobile-tile_header">
        <div className="d-flex  w-100 justify-content-between">
          <div className="d-flex">
            <div className="pe-3">
              <img
                className="report-tile__img"
                src={content?.image?.location_image ?? noImage}
              />
            </div>
            <div>
              {content?.content_name && (
                <h3 className="report-name ">
                  {truncate(content?.content_name, 20)}
                </h3>
              )}
              {content?.title && (
                <h3 className="report-name ">{truncate(content?.title, 15)}</h3>
              )}
              {content?.ratings && (
                <p className="tile-key mb-0">
                  Ratings:{" "}
                  <span className="tile-value">{content?.ratings}</span>
                </p>
              )}
            </div>
          </div>

          <div className="">
            <h3 className="tile-author text-right">
              {truncate(content?.author_name, 20)}
            </h3>
            <p className="tile-key mb-0 d-flex align-items-center justify-content-end">
              Completion rate:{" "}
              <span className="tile-value ps-1">
                {content?.completion_rate}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="tile-details__wrapper">
        <div className="tile-key-value_wrapper">
          <h3 className="tile-details__key">Episode Unlocks</h3>
          <h3 className="tile-details__value">
            {content?.total_unlocked
              ? content?.total_unlocked
              : content?.episodes_unlocked ?? 0}
          </h3>
        </div>
        {content?.coins_used > -1 && (
          <div className="tile-key-value_wrapper">
            <h3 className="tile-details__key">Coins Used</h3>
            <h3 className="tile-details__value">{content?.coins_used}</h3>
          </div>
        )}
        <div className="tile-key-value_wrapper">
          <h3 className="tile-details__key">Hours listened</h3>
          <h3 className="tile-details__value">
            {content?.total_time_listened
              ? content?.total_time_listened
              : content?.hours_listened ?? 0}
          </h3>
        </div>{" "}
        <div className="tile-key-value_wrapper">
          <h3 className="tile-details__key">No. of listeners</h3>
          <h3 className="tile-details__value">{content?.plays ?? 0}</h3>
        </div>{" "}
        <div className="tile-key-value_wrapper">
          <h3 className="tile-details__key">No. of shares</h3>
          <h3 className="tile-details__value">{content?.shares ?? 0}</h3>
        </div>{" "}
        <div className="tile-key-value_wrapper">
          <h3 className="tile-details__key">No. of favourites</h3>
          <h3 className="tile-details__value">{content?.favourites ?? 0}</h3>
        </div>
      </div>
    </div>
  );
};

export default ReportTile;
