import React, { useState } from 'react'
import backArrow from '../../../assets/img/backArrow.svg'
import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import Select from '../../../components/SelectInput'
import ButtonLoader from '../../../components/ButtonLoader'
import { useForm, useController } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { firstTimeWithdrawalValidation } from '../../../utils/form_validation'
import { useQuery, useMutation } from 'react-query'
import Client from '../../../client'
import { toast } from 'react-toastify';
import TransactionDetails from '../Modal/TransactionDetails'




const Withdraw = ({ walletBalance, banks, refetchWalletBalance }) => {
    const navigate = useNavigate()
    const [transactionDetails, showTransactionDetails] = useState(false)
    const [bankName, setBankName] = useState('')
    const [bankDetails, setBankDetails] = useState(null)
    const [amount, setAmount] = useState(0)
    const [bankCode, setBankCode] = useState(null)

    const loading = false;

    const { register, handleSubmit, setValue, formState: { errors }, control, watch } = useForm({
        resolver: yupResolver(firstTimeWithdrawalValidation),
    });

    const { field: { value: bank, onChange: typeOnChange, ...restTypeField } } = useController({ name: 'bank', control });

    const toggleTransactionDetails = () => {
        showTransactionDetails(!transactionDetails)
    }

    const { mutate: verifyBankDetails, isLoading } = useMutation(Client.transactions.verify_bank_details, {
        onSuccess: (data) => {
            if (data.error) {
                toast.error(data.error.message)
                // navigate('/auth/verify-login')
            } else {
                setBankDetails(data.data)
                toggleTransactionDetails()
            }

        },
        onError: (error) => {
            if (error.response) return toast.error(error.response.data.error.message)
            else if (error.data) return toast.error(error.data.message)
            else return toast.error(error.message)
            // navigate('/auth/verify-login')

        }
    })

    const onSubmitHandler = (data) => {
        // Cookies.set("CREATOR_RSS_LINK", data.rss)
        // setRSSUploadedLink(data.rss)
        const { bank: { bank_code, bank_name }, account_number, amount } = data
        setAmount(amount)
        setBankName(bank_name)
        setBankCode(bank_code)
        const payload = {
            bank_code,
            account_number
        }
        verifyBankDetails(payload)
        // handleUpload(data)
    };


    const handleAmountChange = (event) => {
        const rawValue = event.target.value.replace(/[^\d]/g, '');
        setValue('amount', rawValue);
        const formattedValue = parseFloat(rawValue.replace(/,/g, '')).toLocaleString();
        setValue('amountFormatted', formattedValue === 'NaN' ? '' : formattedValue);
    };



    return (
        <>
            {
                <div>
                    <p className='page-title'>Transactions /<span className='page-sub-title text-white'>Withdraw</span></p>

                    <div className='mb-4 d-flex align-items-center'>
                        <div style={{
                            marginRight: '9px'
                        }}
                            role='button'
                            onClick={() => {
                                navigate(-1)
                            }}
                        >
                            <img src={backArrow} alt='icon' />
                        </div>
                        <h4 className='page-details mb-0'>Withdraw</h4>

                    </div>

                    <div className="third-row analytics-card content-table withdraw">



                        <Row>
                            <Col sm='12' md='7'>
                                <form
                                    className='upload-content__form rss-input__form w-100 overflow-hidden'
                                    onSubmit={handleSubmit(onSubmitHandler)}
                                >
                                    <Row className='gx-3' style={{
                                        margin: 'auto'
                                    }}>

                                        <Col sm='12' md='12'>
                                            <div className=''>
                                                {
                                                    errors.bank ? <label className='text-danger auth-label'>{errors.bank?.message}</label> : <label className=''>Select Bank</label>
                                                }
                                                <Select
                                                    className={`mb-4 form-control rss-type__select cstm-react__select__wrapper w-100 ${errors.type_id ? 'error_detected' : ""}`}
                                                    placeholder='Select Bank'
                                                    options={banks ?
                                                        banks.map(el => (
                                                            {
                                                                label: el?.bank_name,
                                                                value: el
                                                            }
                                                        )
                                                        ) : []
                                                    }
                                                    onChange={option => typeOnChange(option ? option.value : option)}
                                                    {...restTypeField}
                                                />
                                            </div>
                                            <div className='mb-4  no-border'>
                                                {
                                                    errors.account_number ? <label className='text-danger '>{errors.account_number?.message}</label> : <label className=''>Account Number</label>
                                                }
                                                <input
                                                    className={`form-control w-100 ${errors.account_number ? 'border-danger' : ''}`}
                                                    placeholder='Account Number'
                                                    {...register("account_number")}
                                                />
                                            </div>
                                            <div className='mb-4  no-border'>
                                                {
                                                    errors.amount ? <label className='text-danger '>{errors.amount?.message}</label> : <label className=''>Enter Amount (NGN)</label>
                                                }
                                                <input
                                                    // type='number'
                                                    className={`form-control mb-2 w-100 ${errors.amount ? 'border-danger' : ''}`}
                                                    placeholder='Amount'
                                                    {...register("amount")}
                                                    value={watch('amountFormatted', '')}
                                                    onChange={handleAmountChange}
                                                    defaultValue={0}
                                                />
                                                <div className='user-withdrawable__amount'>
                                                    {`Withdrawable Balance (NGN) : ₦ ${Number(walletBalance ?? 0)?.toLocaleString()} `}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>


                                    <div className=' rss-upload-button__wrapper no-border'>
                                        <div className=''>
                                            <button className='btn auth-btn ' type='submit'
                                                onClick={(e) => {
                                                }}
                                                disabled={loading || isLoading}
                                            >
                                                {
                                                    loading || isLoading ?
                                                        <ButtonLoader /> :
                                                        'Withdraw'
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </Col>

                        </Row>
                    </div>

                </div >
            }

            <TransactionDetails
                isOpen={transactionDetails}
                toggle={toggleTransactionDetails}
                userType={0}
                bankList={{ bank_name: bankName, bank_code: bankCode, ...bankDetails }}
                amount={amount}
                refetchWalletBalance={refetchWalletBalance}
            />
        </>
    )
}

export default Withdraw