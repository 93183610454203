import React, { useState } from "react";
import backArrow from "../../../../assets/img/backArrow.svg";
import MP3 from "../MP3";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Select from "../../../../components/SelectInput";
import ButtonLoader from "../../../../components/ButtonLoader";
import { useForm, useController } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { rssUploadValidation } from "../../../../utils/form_validation";

const RSSUpload = ({
  setSection,
  types,
  loading,
  handleUpload,
  setRSSUploadedLink,
}) => {
  const navigate = useNavigate();
  const [contentType, setContentType] = useState("RSS_Upload");

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(rssUploadValidation),
  });

  const {
    field: { value: type_id, onChange: typeOnChange, ...restTypeField },
  } = useController({ name: "type_id", control });

  const onSubmitHandler = (data) => {
    // Cookies.set("CREATOR_RSS_LINK", data.rss)
    setRSSUploadedLink(data.rss);
    handleUpload(data);
  };

  if (contentType === "MP3") return <MP3 />;
  return (
    <div>
      <p className="page-title">
        Content/
        <span className="page-sub-title text-white">Upload Content</span>
      </p>

      <div className="mb-4 d-flex align-items-center">
        <div
          style={{
            marginRight: "9px",
          }}
          onClick={() => {
            navigate("/content");
          }}
          role="button"
        >
          <img src={backArrow} alt="icon" />
        </div>
        <h4 className="page-details mb-0">Upload Content</h4>
      </div>

      <div className="third-row analytics-card content-table withdraw rss-upload--form__wrapper no-side__padding">
        <div className="side-padding">
          <div className="d-block d-lg-flex align-items-center content-select__wrapper">
            <p className="content-select__text mb-0 ">
              What is the format of your existing audio?
            </p>
            <div className="content-select_form ">
              <label className="radio_label">
                <input
                  value={"RSS_Upload"}
                  className="content-select_input"
                  name="content-type"
                  checked={contentType === "RSS_Upload"}
                  type="radio"
                  onChange={(e) => {
                    setContentType(e.target.value);
                  }}
                />
                <span className="content-type__select">RSS</span>
              </label>
              <label className="radio_label">
                <input
                  value={"MP3"}
                  className="content-select_input"
                  checked={contentType === "MP3"}
                  name="content-type"
                  type="radio"
                  onChange={(e) => {
                    setContentType(e.target.value);
                  }}
                />
                <span className="content-type__select">MP3 file</span>
              </label>
            </div>
          </div>
        </div>

        <div>
          <form
            className="upload-content__form rss-input__form w-100 overflow-hidden"
            onSubmit={handleSubmit(onSubmitHandler)}
          >
            <Row
              className="gx-3 side-padding gy-3"
              style={{
                margin: "auto",
              }}
            >
              <Col sm="12" md="6">
                <div className="form_container  no-border">
                  {errors.rss ? (
                    <label className="text-danger ">
                      {errors.rss?.message}
                    </label>
                  ) : (
                    <label className="">Enter RSS</label>
                  )}
                  <input
                    className={`form-control w-100 ${
                      errors.rss ? "border-danger" : ""
                    }`}
                    placeholder="RSS link"
                    {...register("rss")}
                  />
                </div>
              </Col>
              <Col sm="12" md="6">
                <div className="mb-5">
                  {errors.type_id ? (
                    <label className="text-danger auth-label">
                      {errors.type_id?.message}
                    </label>
                  ) : (
                    <label className="">Type</label>
                  )}
                  <Select
                    className={`form-control rss-type__select cstm-react__select__wrapper w-100 ${
                      errors.type_id ? "error_detected" : ""
                    }`}
                    placeholder="Select Option"
                    options={
                      types
                        ? types.map((el) => ({
                            label: el?.title,
                            value: el?.id,
                          }))
                        : []
                    }
                    onChange={(option) =>
                      typeOnChange(option ? option.value : option)
                    }
                    {...restTypeField}
                  />
                </div>
              </Col>
            </Row>

            <div className="d-flex justify-content-end rss-upload-button__wrapper">
              <div className="side-padding">
                <button
                  className="btn auth-btn "
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <ButtonLoader /> : " Upload"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RSSUpload;
