import React, { useState } from 'react'
import ContentForm from './ContentForm'
import ContentUpload from './ContentUpload'
import Copyright from '../Copyright'
import Client from '../../../../client'
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import ButtonLoader from '../../../../components/ButtonLoader'
import { useNavigate } from 'react-router-dom'

const MP3 = ({
    title,
    loading,
    languageList,
    genre,
    subGenre,
    types,
}) => {
    const [section, setSection] = useState('Content_form')
    const navigate = useNavigate()

    const { mutate: editMetaData, isLoading } = useMutation(Client.content.upload_metadata, {
        onSuccess: (data) => {
            if (data.error) {
                toast.error(data.error.message)
            } else {
                toast.success('Content updated succesfully')
                // setSection('Copyright')
                navigate('/content')
            }
        },
        onError: (error) => {
            if (error.response) return toast.error(error.response.data.error.message)
            else if (error.data) return toast.error(error.data.message)
            else return toast.error(error.message)
        }
    })


    return (
        // !title?.length > 0 ? <div
        //     className='d-flex align-items-center justify-content-center position-absolute'
        //     style={{
        //         height: '90%',
        //         width: "calc(80%)"

        //     }}>
        //     <ButtonLoader />
        // </div> :
            <>
                {section === 'Content_form' && <ContentForm
                    setSection={setSection}
                    title={title[0]}
                    loading={isLoading}
                    editMetaData={editMetaData}
                    languageList={languageList}
                    genre={genre}
                    subGenre={subGenre}
                    types={types}
                />}
                {section === 'Content_upload' && <ContentUpload setSection={setSection} />}
                {section === 'Copyright' && <Copyright setSection={setSection} previousSection={'Content_form'} />}



            </>

    )
}

export default MP3