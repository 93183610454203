import React, { useRef, useEffect } from 'react'

import ReactApexChart from 'react-apexcharts'

const Chart = ({ data }) => {
    const series = [{
        name: 'Hours Listened',
        data: data
    }]

    const options = {

        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '75%',
                endingShape: 'rounded',
                borderRadius: 1,
                // borderRadiusApplication: 'end',

            },


        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 0,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            labels: {
                style: {
                    colors: '#fff'
                }
            }
        },
        yaxis: {
            show: true,
            labels: {
                style: {
                    colors: '#fff'
                }
            }
        },
        fill: {
            opacity: 1
        },

        colors: ['#57F2A8']

    }


    const chartRef = useRef(null);

    useEffect(() => {
        if (chartRef) {
            const chartInstance = chartRef?.current?.apexChart;
            chartInstance?.updateSeries({
                name: 'Hours Listened',
                data: data
            });
        }

    }, [data]);


    return <ReactApexChart options={options} series={series} type="bar" height={350} width={'100%'} ref={chartRef} />

}

export default Chart