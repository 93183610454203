import React, { useState } from "react";
import { Col } from "reactstrap";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import Client from "../../../client";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { changePasswordValidation } from "../../../utils/form_validation";
import ButtonLoader from "../../../components/ButtonLoader";

const Password = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(changePasswordValidation),
  });
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const toggleOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const togglePassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const { mutate: resetPassword, isLoading: loading } = useMutation(
    Client.profile.change_password,
    {
      onSuccess: (data) => {
        toast.success(data.message);
        reset();
      },
      onError: (err) => {
        toast.error(err.response.data.message);
      },
    }
  );

  const onSubmitHandler = (data) => {
    resetPassword(data);
  };

  return (
    <div>
      <Col sm="12" md="9">
        <form
          className="upload-content__form rss-input__form w-100 overflow-hidden mt-0"
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <div className="mb-4">
            <h3 className="password-settings__text">Password</h3>
            <p className="password-settings__subtext">
              To change your password, please fill the details below
            </p>
          </div>
          <div className="mb-4  no-border">
            {errors.old_password ? (
              <label className="text-danger ">
                {errors.old_password?.message}
              </label>
            ) : (
              <label className="">Current Password</label>
            )}
            <div className="password-input-wrappe  d-flex align-items-center justify-content-between position-relative">
              <input
                type={`${showOldPassword ? "text" : "password"}`}
                className={`auth-input form-control w-100 ${
                  errors.old_password ? "border-danger" : ""
                }`}
                {...register("old_password")}
              />
              <div
                className="visibility-icon position-absolute"
                onClick={toggleOldPassword}
                role="button"
              >
                {`${showOldPassword ? "HIDE" : "SHOW"}`}
              </div>
            </div>
          </div>
          <div className="mb-4">
            {errors.new_password ? (
              <p className="text-danger auth-label mb-0">
                {errors.new_password?.message}
              </p>
            ) : (
              <label className="auth-label" tabIndex={0}>
                New Password
              </label>
            )}
            <div className="password-input-wrappe  d-flex align-items-center justify-content-between position-relative">
              <input
                type={`${showPassword ? "text" : "password"}`}
                className={`auth-input form-control w-100 ${
                  errors.new_password ? "border-danger" : ""
                }`}
                {...register("new_password")}
              />
              <div
                className="visibility-icon position-absolute"
                onClick={togglePassword}
                role="button"
              >
                {`${showPassword ? "HIDE" : "SHOW"}`}
              </div>
            </div>
          </div>

          <div className="mb-2">
            {errors.confirm_password ? (
              <p className="text-danger auth-label mb-0">
                {errors.confirm_password?.message}
              </p>
            ) : (
              <label className="auth-label" tabIndex={0}>
                Re-enter New Password
              </label>
            )}
            <div className="password-input-wrappe  d-flex align-items-center justify-content-between position-relative">
              <input
                type={`${showPassword2 ? "text" : "password"}`}
                className={`auth-input form-control w-100 ${
                  errors.confirm_password ? "border-danger" : ""
                }`}
                {...register("confirm_password")}
              />
              <div
                className="visibility-icon position-absolute"
                onClick={togglePassword2}
                role="button"
              >
                {`${showPassword2 ? "HIDE" : "SHOW"}`}
              </div>
            </div>
          </div>

          <div className=" rss-upload-button__wrapper no-border mb-3">
            <div className="">
              <button
                className="btn auth-btn "
                type="submit"
                disabled={loading}
              >
                {loading ? <ButtonLoader /> : "Change Password"}
              </button>
            </div>
          </div>
        </form>
      </Col>
    </div>
  );
};

export default Password;
