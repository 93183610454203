import React from 'react'

const Empty = ({ setSection }) => {
    return (
        <div className='text-center py-5'>
            <h3 className='empty-banklist_text mt-2'>No Bank Found!</h3>
            <p className='empty-banklist_sub-text mx-auto'>You currently do not have any bank added to your profile. Please click on the button below to add a bank</p>
            <div className=' rss-upload-button__wrapper no-border mb-3'>
                <div className=''>
                    <button className='btn auth-btn ' type='submit'
                        onClick={(e) => {
                            e.preventDefault()
                            setSection('form')
                        }}
                    // disabled={loading}
                    >
                        {
                            // loading ?
                            //   <ButtonLoader /> :
                            'Add Bank'
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Empty