import React, { useState, useRef, useEffect } from 'react'
import backArrow from '../../../../assets/img/backArrow.svg'
import t1 from '../../../../assets/img/no-image.png'
import trash from '../../../../assets/img/trash.svg'
import Player from '../../../../components/Player'
import FileUploadHandler from '../../../../utils/fileUploadHandler'
import { useMutation } from 'react-query'
import Client from '../../../../client'
import ButtonLoader from '../../../../components/ButtonLoader'
import { toast } from 'react-toastify';
import { Row, Col } from 'reactstrap'
import Select from '../../../../components/SelectInput'



const ContentUpload = ({ setSection, title }) => {
    const [contentEpisodes, setContentEpisodes] = useState(1)
    const [uploadedMP3, setUploadedMP3] = useState([])
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [uploading, setUploading] = useState(false)

    const [inputValues, setInputValues] = useState([]);
    const [descriptionValues, setDescriptionValues] = useState([]);
    const [paywallValues, setPaywallValues] = useState([]);


    const [error, setError] = useState([]);

    const handleInputChange = (index, value) => {
        if (error?.includes(index)) {
            const filteredError = error.filter(el => el != index)
            setError(filteredError)
        }
        const updatedValues = [...inputValues];
        updatedValues[index] = value;
        setInputValues(updatedValues);
    };

    const handleDescriptionChange = (index, value) => {
        if (error?.includes(index)) {
            const filteredError = error.filter(el => el != index)
            setError(filteredError)
        }
        const updatedValues = [...descriptionValues];
        updatedValues[index] = value;
        setDescriptionValues(updatedValues);
    };

    const handlePaywallChange = (index, value) => {
        if (error?.includes(index)) {
            const filteredError = error.filter(el => el != index)
            setError(filteredError)
        }
        const updatedValues = [...paywallValues];
        updatedValues[index] = value;
        setPaywallValues(updatedValues);
    };

    const ref = useRef()



    function deleteFile(i) {
        const arr = uploadedMP3.filter(el => el.id !== i)
        setUploadedMP3(arr)
    }



    const { mutate: handleMP3EpisodesUpload, isLoading } = useMutation(Client.content.mp3_episodes, {
        onSuccess: (data) => {
            toast.success(data.message)
            setSection('Copyright')
        },

        onError: (error) => {
            toast.error(error.message)


        }
    })

    function handleFiles(i, event) {
        var files = event.target.files;
        setUploadedMP3(mp3 => [...mp3, { id: i, audio: URL.createObjectURL(files[0]) }
        ])
        const file = files[0]
        setUploadedFiles(mp3File => [...mp3File, file])
    }

    const handleEpisodesUpload = async () => {
        try {
            setUploading(true)
            const mergedAInputandFileArray = uploadedFiles.map((file, index) => {
                const obj = { file: file, title: inputValues[index], description: descriptionValues[index], is_paid: paywallValues[index] };
                return obj;
            });

            const promises = mergedAInputandFileArray.map(el => FileUploadHandler(el.file, el.title, el.description, el.is_paid))

            const arr = await Promise.all(promises)

            const payload = {
                titleId: title.id,
                mediaArray: arr
            }
            handleMP3EpisodesUpload(payload)

            setUploading(false)

        } catch (err) {

        }
    }

    const handleButtonClick = (index) => {
        if (!inputValues[index]) {
            setError(err => [...err, index]);
        } else {
            setError('');
            // Handle button click for the corresponding input
        }
    };


    useEffect(() => {
        console.log(title)
    })
    return (
        <div>
            <p className='page-title'>Content/<span className='page-sub-title text-white'>Upload Content</span></p>

            <div className='mb-4 d-flex align-items-center'>
                <div
                    style={{
                        marginRight: '9px'
                    }}
                    onClick={() => {
                        setSection('Content_form')
                    }}
                    role='button'
                >
                    <img src={backArrow} alt='icon' />
                </div>
                <h4 className='page-details mb-0'>Upload Content</h4>

            </div>

            <div className="third-row analytics-card content-table withdraw rss-upload--form__wrapper no-side__padding">
                <div className='uploaded-rss__details__wrapper side-padding'>
                    <div className='uploaded-rss__details__header d-flex align-items-center'>
                        <div className='uploaded-rss__details__img-wrapper'>
                            <img src={title?.image_location?.location_image ?? t1} alt='icon' className='uploaded-rss__details__img' />
                        </div>
                        <h3 className='uploaded-rss__details__title mb-0'>
                            {title?.title}
                        </h3>
                    </div>
                </div>
                <div className='rss-uploaded_content__form upload-rss_details__container side-padding no-border'>
                    {
                        new Array(contentEpisodes).fill("-").map((el, i) =>
                            <div className='  uploaded-rss_details d-block d-lg-flx align-items-center justify-content-between' key={i}>

                                <div className={`${uploadedMP3.some(el => el.id === i) ? 'w-100' : 'mp3-upload-wrapper_w-5'}  d-block `}>
                                    <Row>
                                        <Col sm='12' md='6'>
                                            <div className={`form-input__container `}>
                                                {error && error?.includes(i) ? <p className='text-danger auth-label mb-0'>{'Title is required!'}</p> : <label className=''>Chapter {i + 1}</label>}
                                                <input
                                                    className='form-control w-100'
                                                    placeholder='Introduction to King Wakanda'
                                                    value={inputValues[i] || ''}
                                                    onChange={(event) => handleInputChange(i, event.target.value)}

                                                />
                                            </div>
                                        </Col>
                                        <Col sm='12' md='6'>
                                            <div>
                                                {error && error?.includes(i) ? <p className='text-danger auth-label mb-0'>{'Title is required!'}</p> : <label className=''>Free episode?</label>}
                                                <Select
                                                    className='form-control cstm-react__select__wrapper'
                                                    classNamePrefix="cstm-react__select"
                                                    // isMulti
                                                    isClearable={false}
                                                    placeholder='Select Option'
                                                    options={[
                                                        {
                                                            label: 'Yes',
                                                            value: 'no'
                                                        },
                                                        {
                                                            label: 'No',
                                                            value: 'yes'
                                                        }
                                                    ]
                                                    }
                                                    onChange={(event) => handlePaywallChange(i, event.value)}
                                                // onChange={option => paywallOnChange(option ? option.value : option)}
                                                // {...restPaywallField}
                                                />
                                            </div>

                                        </Col>

                                        <Col sm='12' md='6'>
                                            <div className={`form-input__container `}>
                                                {error && error?.includes(i) ? <p className='text-danger auth-label mb-0'>{'Description is required!'}</p> : <label className=''>Description </label>}
                                                <textarea
                                                    rows={6}
                                                    className='form-control w-100'
                                                    placeholder='Episode description'
                                                    value={descriptionValues[i] || ''}
                                                    onChange={(event) => handleDescriptionChange(i, event.target.value)}

                                                />
                                            </div>
                                        </Col>
                                    </Row>

                                    {/* <div className={`form-input__container ${uploadedMP3.some(el => el.id === i) ? 'w-100' : 'w-75'}`}>
                                        {error && error?.includes(i) ? <p className='text-danger auth-label mb-0'>{'Title is required!'}</p> : <label className=''>Chapter {i + 1}</label>}
                                        <input
                                            className='form-control w-100'
                                            placeholder='Introduction to King Wakanda'
                                            value={inputValues[i] || ''}
                                            onChange={(event) => handleInputChange(i, event.target.value)}

                                        />
                                    </div> */}
                                    {
                                        !uploadedMP3.some((el) => el.id === i) &&
                                        <div className='w-25'>
                                            <label htmlFor={`mp3-upload__${i}`} className='upload-mp3__btn btn w-100 mb-0'
                                                onClick={() => {
                                                    handleButtonClick(i)
                                                }
                                                }
                                            >Upload MP3</label>

                                            <input type='file'
                                                className='d-none'
                                                id={`mp3-upload__${i}`} name={`mp3-upload__${i}`}
                                                accept=".mp3"
                                                disabled={!inputValues[i]}
                                                onChange={(e) => {

                                                    handleFiles(i, e)
                                                }} />
                                        </div>
                                    }

                                </div>

                                {
                                    uploadedMP3.some((el) => el.id === i) &&
                                    <Row className='gx-2 align-items-center'>
                                        <Col sm='12' md='10'>
                                            <div className='w-100'>
                                                <div className='rss-player__container'>
                                                    <Player audio={uploadedMP3[i].audio} />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm='12' md='1'>
                                            {/* <div className='w-25 '> */}
                                            {
                                                // uploadedMP3.some((el) => el.id === i) &&
                                                <div className='w-100  d-flex justify-content-center align-items-center' role='button'
                                                    onClick={() => {
                                                        deleteFile(i)
                                                    }}>
                                                    <div className='delete-mp3__container text-center'>
                                                        <div className='delete-mp3__icon__wrapper'>
                                                            <img src={trash} alt='icon' />
                                                        </div>
                                                        <p className='delete-mp3__text mb-0'>Delete MP3</p>
                                                    </div>
                                                </div>

                                            }
                                            {/* </div> */}
                                        </Col>
                                    </Row>


                                }



                                {/* <div className='w-100'>
                                    <div className='rss-player__container'>
                                        <Player />
                                    </div>
                                </div> */}




                            </div>
                        )
                    }
                    <div className=' rss-upload-button__wrapper no-border'>
                        <button className='btn auth-btn add-chapter__btn' type='submit' onClick={() => {
                            setContentEpisodes(episodes => episodes + 1)
                        }}>
                            Add Chapter
                        </button>

                    </div>
                    {
                        contentEpisodes === 1 ?
                            <div className='fill-chapter-container'>

                            </div> : null
                    }

                </div>



                {/* <div className='rss-uploaded_content__form upload-rss_details__container'>
                    <div className='  uploaded-rss_details d-flex align-items-center justify-content-between'>

                        <div className='w-100'>
                            <div className='form-input__container'>
                                <label className=''>Chapter 1</label>
                                <input className='form-control' placeholder='Introduction to King Wakanda' />
                            </div>
                        </div>

                        <div className='w-100'>
                            <div className='rss-player__container'>
                                <Player />
                            </div>
                        </div>


                        <div className='w-25'>
                        </div>

                    </div>
                    <div className='  uploaded-rss_details d-flex align-items-center justify-content-between no-border'>

                        <div className='w-100'>
                            <div className='form-input__container'>
                                <label className=''>Chapter 2</label>
                                <input className='form-control' placeholder='Home, sweet home' />
                            </div>
                        </div>

                        <div className='w-100'>
                            <div className='rss-player__container'>
                                <Player />
                            </div>
                        </div>


                        <div className='w-25'>
                        </div>

                    </div>
                </div> */}
                < div className='d-flex justify-content-end rss-upload-button__wrapper' >
                    <div className='side-padding'>
                        <button
                            className='btn auth-btn '
                            type='submit' onClick={(e) => {
                                e.preventDefault()
                                handleEpisodesUpload()
                            }}
                            disabled={isLoading || uploading}
                        >
                            {
                                isLoading || uploading ?
                                    <ButtonLoader /> :
                                    'Next'
                            }

                        </button>
                    </div>


                </div>



            </div>

        </div >

    )
}

export default ContentUpload;