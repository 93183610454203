import React from 'react'
import { Row, Col } from 'reactstrap'
import search from '../../assets/img/searchIcon.svg'
import { useNavigate } from 'react-router-dom'


const Guide = () => {
    const navigate = useNavigate()
    return (
        <div>
            <p className='page-title mb-4' >Guide</p>

            <div className='mb-4 d-flex  justify-content-between'>
                <h4 className='page-details mb-0'>Guide</h4>
                <div className='search-content__wrapper d-flex  '>
                    <div className='pb-1'>
                        <img src={search} alt='icon' />
                    </div>
                    <input className='search-content__input' placeholder='Search Guide'
                    />

                </div>
            </div>
            <Row className='gx-4 gy-5'>
                <Col sm='12' md='4' xxl='3'>

                    <div className='guide-card-wrapper position-relative' role='button'>
                        <div className='position-absolute guide-card__title-container text-center'>
                            <p className='guide-card__title'>Using the Guide Portal</p>
                        </div>

                    </div>

                </Col>
                <Col sm='12' md='4' xxl='3'>

                    <div className='guide-card-wrapper position-relative' role='button'>
                        <div className='position-absolute guide-card__title-container text-center'>
                            <p className='guide-card__title'>Creating Engaging Audio </p>
                        </div>

                    </div>

                </Col><Col sm='12' md='4' xxl='3'>

                    <div className='guide-card-wrapper position-relative' role='button'>
                        <div className='position-absolute guide-card__title-container text-center'>
                            <p className='guide-card__title'>Securing your Account</p>
                        </div>

                    </div>

                </Col>
                <Col sm='12' md='4' xxl='3'>

                    <div className='guide-card-wrapper position-relative' role='button' onClick={() => {
                        navigate('/guide/creator-faq')
                    }}>
                        <div className='position-absolute guide-card__title-container text-center'>
                            <p className='guide-card__title'>Creators FAQs</p>
                        </div>

                    </div>

                </Col><Col sm='12' md='4' xxl='3'>

                    <div className='guide-card-wrapper position-relative' role='button'>
                        <div className='position-absolute guide-card__title-container text-center'>
                            <p className='guide-card__title'>Withdrawal</p>
                        </div>

                    </div>

                </Col><Col sm='12' md='4' xxl='3'>

                    <div className='guide-card-wrapper position-relative' role='button'>
                        <div className='position-absolute guide-card__title-container text-center'>
                            <p className='guide-card__title'>Adding a Bank Account</p>
                        </div>

                    </div>

                </Col><Col sm='12' md='4' xxl='3'>

                    <div className='guide-card-wrapper position-relative' role='button'>
                        <div className='position-absolute guide-card__title-container text-center'>
                            <p className='guide-card__title'>Contacting Genti</p>
                        </div>

                    </div>

                </Col><Col sm='12' md='4' xxl='3'>

                    <div className='guide-card-wrapper position-relative' role='button'>
                        <div className='position-absolute guide-card__title-container text-center'>
                            <p className='guide-card__title'>Downloading Report</p>
                        </div>

                    </div>

                </Col>


            </Row>
        </div>
    )
}

export default Guide