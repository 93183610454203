import React, { useState, useEffect, useRef } from "react";
import filterIcon from "../../assets/img/filter.svg";
import showIcon from "../../assets/img/Show.svg";

import search from "../../assets/img/searchIcon.svg";
import sort from "../../assets/img/filterTable.svg";
import noImage from "../../assets/img/no-image.png";

import EmptyContent from "./Empty";
import moment from "moment";
import DatePicker from "../../components/RangePicker";
import close from "../../assets/img/dClose.svg";
import filter from "../../assets/img/filter-list.svg";
import activeFilter from "../../assets/img/activeFilter.svg";
import { Row, Col } from "reactstrap";
import Chart from "../../components/TransactionsChart";
import EmptyChart from "../../components/TransactionsChart/EmptyState";
import menu from "../../assets/img/menu.svg";
import downloadReport from "../../assets/img/downloadReport.svg";
import { useNavigate } from "react-router-dom";
import nairaIcon from "../../assets/img/naira.svg";
import arrow from "../../assets/img/rightArrow.svg";
import Client from "../../client";
import { useQuery } from "react-query";
import truncate from "truncate";
import MobileTile from "./MobileTile";
import RecentEarningsMobileTile from "./RecentEarnings/MobileTile";
import { scrollToTop, formatDate } from "../../utils/helpers";
import useDebounce from "../../components/Hooks/Debounce";
import select from "../../assets/img/headerselect.svg";
import Pagination from "../../components/Pagination";
import ButtonLoader from "../../components/ButtonLoader";

const Content = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const [startDate, setStartDate] = useState(new Date(Date.now()));
  const [endDate, setEndDate] = useState(new Date(Date.now()));
  const [showDateValue, setDateValue] = useState(false);
  const [reports, setReports] = useState([1]);
  const [filterContent, setFilterContent] = useState(false);
  const [hours, setHours] = useState([]);
  const [wallet_balance, setWalletBalance] = useState({ 1: 0, 2: 0 });
  const [all_transactions, setTransactions] = useState([]);
  const [recent_earnings, setRecentEarnings] = useState([]);
  const debouncedSearchValue = useDebounce(searchValue, 1000);
  const [filterByTransactionType, setFilterByTransactionType] = useState(false);
  const [filterByPaywall, setFilterByPaywall] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [TransactionType, setTransactionType] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [order, setOrder] = useState("desc");
  const [orderParam, setOrderParam] = useState("created_at");
  const [activeWallet, setActveWallet] = useState("naira");

  const transaction_status = [
    {
      type: "Pending",
      value: "pending",
    },
    {
      type: "Successful",
      value: "success",
    },
    // ,
    // {
    //     type: 'Failed',
    //     value: 'failed'
    // }
  ];

  const transaction_type = [
    {
      type: "Income",
      value: "income",
    },
    {
      type: "Withdrawal",
      value: "withdrawal",
    },
  ];

  const { isLoading, isFetching, isRefetching } = useQuery(
    [
      "all_transactions",
      debouncedSearchValue,
      activePage,
      transactionStatus,
      TransactionType,
      orderParam,
      order,
    ],
    () =>
      Client.transactions.get_all_transactions({
        status: transactionStatus ? transactionStatus?.value : null,
        type: TransactionType ? TransactionType.value : null,
        params: debouncedSearchValue,
        page: activePage,
        orderParam: orderParam,
        order: orderParam ? order : null,
        // currency_group: 1,
      }),
    {
      onSuccess: (data) => {
        setTransactions(data.data);
      },
    }
  );

  useQuery(["wallet_balance"], () => Client.transactions.get_wallet_balance(), {
    onSuccess: (data) => {
      setWalletBalance(data.data);
    },
  });

  useQuery(
    ["recent_earnings"],
    () => Client.transactions.get_recent_earnings(),
    {
      onSuccess: (data) => {
        setRecentEarnings(data.data);
      },
    }
  );

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {}, [TransactionType]);

  const resetFilter = () => {
    setTransactionStatus(null);
    setTransactionType(null);
  };

  const {
    data: allTransactions,
    total,
    current_page,
    per_page,
  } = all_transactions || {};

  const transaction_Ref = useRef(null);

  const changeContent = (e) => {
    setActivePage(e);
    // window.scrollTo(0, 0)
    transaction_Ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <p className="page-title" onClick={() => setReports([])}>
        Transactions
      </p>
      <div className="mb-0 mb-md-2 d-flex justify-content-between align-items-center">
        <div>
          <h4 className="page-details ">Transactions</h4>
        </div>
        {/* <div className='d-none d-md-none download-report_img__wrapper d-none align-items-center justify-content-center'>
                    <img src={downloadReport} alt='download_report' className='download-report_img' />
                </div> */}
        {/* <div className='align-items-center d-block d-md-flex'>
                    <div className='download-report_img__wrapper d-flex align-items-center justify-content-center'>
                        <img src={downloadReport} alt='download_report' className='download-report_img' />
                    </div>
                    <div className='position-relative d '>
                        {
                            !showDateValue ? <div className='filter-wrapper d-flex align-items-center' role='button' onClick={() => {
                                setShowCalendar(!showCalendar)
                            }
                            }>
                                <p className='filter_text mb-0'>Filter by Date</p>
                                <div>
                                    <img src={filterIcon} alt='icon' />
                                </div>

                            </div> :
                                <div className='filter-wrapper d-flex align-items-center position-relative' role='button' onClick={() => {
                                    setShowCalendar(true)
                                    setShowCalendar(false)

                                }
                                }>
                                    <p className='filter_text mb-0'>{`${moment(startDate).format('L')} - ${moment(endDate).format('L')}`}</p>
                                    <div style={{
                                        marginLeft: '18px'

                                    }}
                                        role='button'
                                        onClick={() => {
                                            setDateValue(false)

                                        }}
                                    >
                                        <img src={close} alt='icon' height='12px' width='12px' />

                                    </div>

                                </div>
                        }


                        {
                            showCalendar && <div className='position-absolute calendar-input_wrapper'>

                                <DatePicker
                                    startDate={startDate}
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                    setDateValue={setDateValue}
                                    setShowCalendar={setShowCalendar}
                                />
                            </div>
                        }
                    </div>
                </div> */}
      </div>
      <div className="position-relative d-md-none d-none mb-3 py-4 ">
        {!showDateValue ? (
          <div
            className="filter-wrapper d-flex align-items-center justify-content-center py-4"
            role="button"
            onClick={() => {
              setShowCalendar(!showCalendar);
            }}
          >
            <p className="filter_text mb-0">Filter by Date</p>
            <div>
              <img src={filterIcon} alt="icon" />
            </div>
          </div>
        ) : (
          <div
            className="filter-wrapper d-flex align-items-center position-relative"
            role="button"
            onClick={() => {
              setShowCalendar(true);
              setShowCalendar(false);
            }}
          >
            <p className="filter_text mb-0">{`${moment(startDate).format(
              "L"
            )} - ${moment(endDate).format("L")}`}</p>
            <div
              style={{
                marginLeft: "18px",
              }}
              role="button"
              onClick={() => {
                setDateValue(false);
              }}
            >
              <img src={close} alt="icon" height="12px" width="12px" />
            </div>
          </div>
        )}

        {showCalendar && (
          <div className="position-absolute calendar-input_wrapper">
            <DatePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setDateValue={setDateValue}
              setShowCalendar={setShowCalendar}
            />
          </div>
        )}
      </div>
      <div>
        <Row>
          <Col sm="12" md="12">
            <div className="reports-card_wrapper">
              <div className="third-row analytics-card content-table withdraw mb-4 mb-md-0">
                <div className="analytics-card_details h-100 ">
                  <div className="d-flex align-items-center justify-content-between mb-3 p-3 p-md-0">
                    <h3 className="analytics-card_title">Wallet Balance</h3>
                    <div className="d-block d-md-none">
                      <button
                        className="mx-2 dashboard-withdraw_btn btn"
                        // disabled
                        onClick={() => {
                          if (activeWallet === "naira") {
                            setActveWallet("dollar");
                          } else {
                            setActveWallet("naira");
                          }
                        }}
                      >
                        Switch Wallet
                      </button>
                      <button
                        className="dashboard-withdraw_btn btn"
                        // disabled
                        disabled={activeWallet !== "naira"}
                        onClick={() => {
                          navigate("/transactions/withdrawal");
                        }}
                      >
                        Withdraw
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between transactions--value__wrapper">
                    <div className="d-flex align-items-center px-3 px-md-0">
                      {activeWallet === "naira" ? (
                        <div className="mr-4 d-flex">
                          <h3 className="wallet-balance analytics-card_value mb-0">
                            ₦{" "}
                            {Boolean(wallet_balance["2"])
                              ? Number(wallet_balance["2"]).toLocaleString()
                              : "0.00"}
                          </h3>
                        </div>
                      ) : (
                        <div className="mx-4 d-flex">
                          <h3 className="wallet-balance analytics-card_value mb-0">
                            ${" "}
                            {Boolean(wallet_balance["1"])
                              ? Number(wallet_balance["1"]).toLocaleString()
                              : "0.00"}
                          </h3>
                        </div>
                      )}
                    </div>

                    <div className="d-none d-md-block">
                      <button
                        className="mx-2 dashboard-withdraw_btn btn"
                        // disabled
                        onClick={() => {
                          if (activeWallet === "naira") {
                            setActveWallet("dollar");
                          } else {
                            setActveWallet("naira");
                          }
                        }}
                      >
                        Switch Wallet
                      </button>
                      <button
                        className="dashboard-withdraw_btn btn"
                        // disabled
                        disabled={activeWallet !== "naira"}
                        onClick={() => {
                          navigate("/transactions/withdrawal");
                        }}
                      >
                        Withdraw
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6" className="d-none">
            <div className="second-row analytics-card d-none">
              <div className="analytics-card_details h-100">
                <div className="d-flex align-items-cente justify-content-between">
                  <h3 className="analytics-card_title">Transactions</h3>
                </div>
                <div className="align-items-center chart-wrapper d-none ">
                  {hours.length > 0 ? <Chart data={hours} /> : <EmptyChart />}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="reports-card_wrapper" ref={transaction_Ref}>
        <div className="third-row analytics-card content-table withdraw h-100">
          {!allTransactions?.length > 0 ? (
            <div className="mt-5 pt-5">
              <div className="pt-4">
                <EmptyContent />
              </div>
            </div>
          ) : (
            <>
              {isLoading || isFetching || isRefetching ? (
                <div
                  className="d-flex align-items-center justify-content-center position-absolute"
                  style={{
                    height: "90%",
                    width: "calc(100% - 60px)",
                  }}
                >
                  <ButtonLoader />
                </div>
              ) : (
                <>
                  <div className="table-details__wrapper">
                    <div className="d-block d-md-flex align-items-center justify-content-between ">
                      <div className="d-flex align-items-center mb-0">
                        <h4 className="table-title pt-2">All Transactions</h4>
                        <div className="d-none d-md-none search-content__wrapper d-flex ">
                          <div className="pb-1">
                            <img src={search} alt="icon" />
                          </div>
                          <input
                            className="search-content__input"
                            placeholder="Search Transaction"
                            defaultValue={searchValue}
                            onChange={(e) => {
                              setSearchValue(e.target.value.toLowerCase());
                            }}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="search-content__wrapper d-lg-none d-none ps-1 ">
                          <div className="pb-1">
                            <img src={search} alt="icon" />
                          </div>
                          <input
                            className="search-content__input"
                            placeholder="Search Content"
                          />
                        </div>
                        {/* <div className='download-report_img__wrapper d-flex align-items-center justify-content-center'>
                                                             <img src={downloadReport} alt='download_report' className='download-report_img' />
                                                         </div> */}
                        {filterContent ? (
                          <div
                            className="filtering-active__wrapper filter-icon__wrapper d-flex justify-content-center align-items-center"
                            onClick={() => {
                              resetFilter();
                              setFilterContent(false);
                            }}
                            role="button"
                          >
                            <img role="button" src={activeFilter} alt="icon" />
                            <p className="filter-content__text pt-1 mb-1">
                              Filter
                            </p>
                          </div>
                        ) : (
                          <div
                            role="button"
                            className="filter-icon__wrapper d-flex justify-content-center align-items-center"
                            onClick={() => {
                              setFilterContent(true);
                            }}
                          >
                            <img src={filter} alt="icon" />
                            <p className="filter-content__text pt-1 mb-1">
                              Filter
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    {filterContent && (
                      <div className="d-block d-md-flex content-filter__ctrl__wrapper  table-details__wrapper">
                        <div
                          className=" position-relative"
                          onClick={() => {
                            setFilterContent(true);
                          }}
                        >
                          <div
                            role="button"
                            className="filter-icon__wrapper d-flex justify-content-between align-items-center filter-ctrl__wrapper hours-listened__filter-wrapper "
                            onClick={() => {
                              setFilterByTransactionType(
                                !filterByTransactionType
                              );
                            }}
                          >
                            <p className="filter-content__text filter-type_text pt-1 mb-1">
                              {TransactionType ? TransactionType?.type : "Type"}
                            </p>
                            <div>
                              {TransactionType ? (
                                <img
                                  role="button"
                                  src={activeFilter}
                                  alt="icon"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setTransactionType(null);
                                  }}
                                />
                              ) : (
                                <img src={select} alt="icon" />
                              )}
                            </div>
                          </div>

                          {filterByTransactionType && (
                            <div className="position-absolute filter-content-action__cta__wrapper paywall_section ">
                              {transaction_type?.map((el, i) => (
                                <div
                                  key={i}
                                  className="d-flex align-items-center filter-select__option"
                                >
                                  <input
                                    type="checkbox"
                                    name="checkbox"
                                    className="cstm-checkbox__input filter__checkbox"
                                    id={`cstm-checkbox__input-${i}`}
                                    role="button"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        // filterContentByGenre(el)
                                        setTransactionType(el);
                                        setFilterByTransactionType(
                                          !filterByTransactionType
                                        );
                                      } else {
                                        setTransactionType(null);
                                        setFilterByTransactionType(
                                          !filterByTransactionType
                                        );
                                      }
                                    }}
                                    checked={TransactionType?.type === el?.type}
                                  />
                                  <label
                                    className="filter-select__option mb-0"
                                    htmlFor={`cstm-checkbox__input-${i}`}
                                  >
                                    {el?.type}
                                  </label>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                        <div className="position-relative">
                          <div
                            role="button"
                            className="filter-icon__wrapper d-flex justify-content-between align-items-center filter-ctrl__wrapper genre-select_section"
                            onClick={() => {
                              setFilterByPaywall(!filterByPaywall);
                            }}
                          >
                            <p className="filter-content__text filter-type_text pt-1 mb-1">
                              {transactionStatus
                                ? truncate(transactionStatus.type)
                                : "Status"}
                            </p>
                            <div>
                              {transactionStatus ? (
                                <img
                                  role="button"
                                  src={activeFilter}
                                  alt="icon"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setTransactionStatus(null);
                                  }}
                                />
                              ) : (
                                <img src={select} alt="icon" />
                              )}
                            </div>
                          </div>

                          {filterByPaywall && (
                            <div className="position-absolute filter-content-action__cta__wrapper paywall_section ">
                              {transaction_status?.map((el, i) => (
                                <div
                                  key={i}
                                  className="d-flex align-items-center filter-select__option"
                                >
                                  <input
                                    type="checkbox"
                                    name="checkbox"
                                    className="cstm-checkbox__input filter__checkbox"
                                    id={`cstm-checkbox__input-${i}`}
                                    role="button"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        // filterContentByGenre(el)
                                        setTransactionStatus(el);
                                        setFilterByPaywall(!filterByPaywall);
                                      } else {
                                        setTransactionStatus(null);
                                        setFilterByPaywall(!filterByPaywall);
                                      }
                                    }}
                                    checked={
                                      transactionStatus?.value === el?.value
                                    }
                                  />
                                  <label
                                    className="filter-select__option mb-0"
                                    htmlFor={`cstm-checkbox__input-${i}`}
                                  >
                                    {el?.type}
                                  </label>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="analytics-card_details h-100 d-none d-md-block">
                    <div className="">
                      <table className="table mb-0 transaction-table text-white">
                        <thead className="thead-light pb-3">
                          <tr>
                            <th>
                              <div className="d-flex align-items-center">
                                <p className="mb-0">Transaction Type</p>

                                {/* <div className='sort-icon__wrapper'>
                                                                             <img src={sort} alt='icon' />
                                                                         </div> */}
                              </div>
                            </th>
                            <th>
                              <div className="d-flex align-items-center">
                                <p className="mb-0">Content Name</p>
                              </div>
                            </th>

                            <th>
                              <div className="d-flex align-items-center">
                                <p className="mb-0">Episodes Unlocked</p>
                              </div>
                            </th>

                            <th>
                              <div className="d-flex align-items-center">
                                <p className="mb-0">Coins Used</p>
                              </div>
                            </th>

                            <th>
                              <div className="d-flex align-items-center">
                                <p className="mb-0">Amount</p>
                                <div
                                  className="sort-icon__wrapper cursor-pointer"
                                  onClick={() => {
                                    changeContent(1);
                                    setOrderParam("amount");
                                    setOrder(order === "asc" ? "desc" : "asc");
                                  }}
                                >
                                  <img src={sort} alt="icon" />
                                </div>
                                {/* <div className='sort-icon__wrapper'>
                                                                             <img src={sort} alt='icon' />
                                                                         </div> */}
                              </div>
                            </th>
                            {/* <th>Sub Genre.</th> */}
                            <th>Acc Number</th>
                            <th>Bank</th>
                            <th>Transction ID</th>
                            <th>Period</th>
                            <th>
                              <div className="d-flex align-items-center">
                                <p className="mb-0">Date</p>
                                <div
                                  className="sort-icon__wrapper cursor-pointer"
                                  onClick={() => {
                                    changeContent(1);
                                    setOrderParam("created_at");
                                    setOrder(order === "asc" ? "desc" : "asc");
                                  }}
                                >
                                  <img src={sort} alt="icon" />
                                </div>
                              </div>
                            </th>
                            <th>Status</th>

                            {/* <th className='text-center'>Action</th> */}
                          </tr>
                        </thead>

                        <tbody className="content-table-body transactions-earnings__table">
                          {allTransactions.map((el, i) => (
                            <tr>
                              <td className="td-text__container ">
                                {!Boolean(el?.type)
                                  ? "Income-Royalty"
                                  : "Withdrawal"}
                              </td>
                              <td className="td-img__container">
                                <div className="d-flex align-items-center">
                                  {!Boolean(el?.type) && (
                                    <div
                                      className="release-img_wrapper"
                                      style={{
                                        borderRadius: "5px",
                                        border: "solid 1px #48494b",
                                      }}
                                    >
                                      <img
                                        src={
                                          el?.title?.image_upload
                                            ?.location_image ?? noImage
                                        }
                                        alt="release-img"
                                      />
                                    </div>
                                  )}
                                  <p className="td-text__container ms-2">
                                    {truncate(el?.title?.title, 11)}
                                  </p>
                                </div>
                              </td>
                              <td className="td-text__container ">
                                {el?.episode_unlocks > 0
                                  ? el?.episode_unlocks
                                  : "-"}
                              </td>
                              <td className="td-text__container ">
                                {el?.coins_used > 0 ? el?.coins_used : "-"}
                              </td>
                              <td className="td-text__container ">
                                {el?.currency_group == "2" ? "₦" : "$"}
                                {` ${el?.amount?.toLocaleString()}`}
                              </td>
                              <td className="td-text__container ">
                                {el?.account_number}
                              </td>
                              <td className="td-text__container ">
                                {el?.bank_name}
                              </td>
                              <td className="td-text__container ">
                                {el?.reference_number}
                              </td>
                              <td className="td-text__container ">
                                {el?.start_date
                                  ? formatDate(el?.start_date)
                                  : ""}{" "}
                                - {el?.end_date ? formatDate(el?.end_date) : ""}
                              </td>
                              <td className="td-text__container ">
                                {moment(el?.created_at).format("L")}
                              </td>{" "}
                              <td className="td-text__container ">
                                {el?.status === 0
                                  ? "Pending"
                                  : el?.status === 1
                                  ? "Succesful"
                                  : "Failed"}
                              </td>
                              {/* <td className='text-center'>
                                                                             <div className='position-relative'>
                                                                                 <div className='content-action__wrapper' role='button' onClick={() => {
                                                                                     setEditContent(editContent === i ? null : i)
                                                                                 }}>
                                                                                     <img src={menu} alt='icon' />
                                                                                 </div>
                                                                                 {
             
                                                                                     editContent === i ? <div className='position-absolute content-action__cta__wrapper report-action__wrapper'>
                                                                                         <div className='d-flex align-items-center content-action__cta'
             
                                                                                             onClick={() => {
                                                                                                 navigate('/report/details')
                                                                                             }}>
                                                                                             <div className='content-action__icon'>
                                                                                                 <img src={showIcon} alt='icon' />
                                                                                             </div>
                                                                                             <p className='content-action_edit_text mb-0'>View Details</p>
             
                                                                                         </div>
                                                                                     </div>
             
                                                                                         : null
                                                                                 }
             
                                                                             </div>
             
             
                                                                         </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="d-md-none d-block">
                    {allTransactions.map((el, i) => (
                      <MobileTile transaction={el} />
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </div>

        {total && total > 10 ? (
          <div className="d-flex justify-content-center align-items-center">
            <Pagination
              onPageChange={changeContent}
              totalCount={total}
              currentPage={current_page}
              pageSize={Number(per_page)}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Content;
