import React, { useState } from "react";
import ContentForm from "./ContentForm";
import ContentUpload from "./ContentUpload";
import Copyright from "../Copyright";
import { useQuery } from "react-query";
import Client from "../../../../client";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

const MP3 = () => {
  const [section, setSection] = useState("Content_form");
  const [uploadedTitle, setUploadedTitle] = useState(null);

  const { data: types } = useQuery(["content_types"], () =>
    Client.content.content_types()
  );
  const { data: language } = useQuery(["content_language"], () =>
    Client.content.content_language()
  );
  const { data: genre } = useQuery(["content_genre"], () =>
    Client.content.content_genre()
  );
  const { data: sub_genre } = useQuery(["content_sub_genre"], () =>
    Client.content.content_sub_genre()
  );
  const { data: author_details } = useQuery(["author_details"], () =>
    Client.content.author_details()
  );

  const { result: language_list } = language?.data || [];
  const { result: genre_list } = genre?.data || [];
  const { result: types_list } = types?.data || [];
  const { result: sub_genre_list } = sub_genre?.data || [];

  const { mutate: uploadMP3, isLoading } = useMutation(
    Client.content.upload_rss,
    {
      onSuccess: (data) => {
        if (data.error) {
          toast.error(data.error.message);
        } else {
          setUploadedTitle(data.data);
          setSection("Content_upload");
        }
      },
      onError: (error) => {
        if (error.response)
          return toast.error(error.response.data.error.message);
        else if (error.data) return toast.error(error.data.message);
        else return toast.error(error.message);
      },
    }
  );

  const { mutate: uploadMetaData, isLoading: uploading } = useMutation(
    Client.content.upload_metadata,
    {
      onSuccess: (data) => {
        if (data.error) {
          toast.error(data.error.message);
        } else {
          setUploadedTitle(data.data);

          // setUploadedRSS(data.data)
          // setUploadedMetadata(data.data)
          setSection("Content_upload");
        }
      },
      onError: (error) => {
        if (error.response)
          return toast.error(error.response.data.error.message);
        else if (error.data) return toast.error(error.data.message);
        else return toast.error(error.message);
      },
    }
  );

  return (
    <>
      {section === "Content_form" && (
        <ContentForm
          setSection={setSection}
          languageList={language_list}
          genreList={genre_list}
          typesList={types_list}
          subGenreList={sub_genre_list}
          handleUpload={uploadMetaData}
          isLoading={uploading}
          authorDetails={author_details}
        />
      )}

      {section === "Content_upload" && (
        <ContentUpload setSection={setSection} title={uploadedTitle} />
      )}
      {section === "Copyright" && (
        <Copyright setSection={setSection} previousSection={"Content_upload"} />
      )}
    </>
  );
};

export default MP3;
