import React, { useState } from "react";
// import backArrow from '../../../../assets/img/backArrow.svg'
import info from "../../../../assets/img/info.svg";
import { Row, Col } from "reactstrap";
import Select from "../../../../components/SelectInput";
import Dropzone from "../../../../components/Dropzone";
import { useForm, useController } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { metadataValidation } from "../../../../utils/form_validation";
import ButtonLoader from "../../../../components/ButtonLoader";
import ImageUploadHandler from "../../../../utils/imageUpload";

const Form = ({
  setSection,
  genreList,
  subGenreList,
  typesList,
  languageList,
  title,
  loading,
  editMetaData,
}) => {
  const [author_image, setAuthorImage] = useState(null);
  const [title_image, setContentImage] = useState(null);
  const [uploading, setUploading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    trigger,
  } = useForm({
    resolver: yupResolver(metadataValidation),

    defaultValues: {
      title: title?.title,
      description: title?.description,
      genre: title?.genre?.map((el) => el.genre.title).join(","),
      sub_genre: title?.sub_genre?.map((el) => el.sub_genre.title).join(","),
      language: title?.language?.map((el) => el.language.name).join(","),
      type_id: title?.type_id,
      id: title?.id,
      // is_paywall: title?.is_paywall
    },
  });
  const {
    field: { value: genre, onChange: genreOnChange, ...restGenreField },
  } = useController({ name: "genre", control });
  const {
    field: {
      value: sub_genre,
      onChange: subGenreOnChange,
      ...restSubGenreField
    },
  } = useController({ name: "sub_genre", control });
  const {
    field: {
      value: language,
      onChange: languageOnChange,
      ...restLanguageField
    },
  } = useController({ name: "language", control });
  const {
    field: { value: type_id, onChange: typeOnChange, ...restTypeField },
  } = useController({ name: "type_id", control });
  // const { field: { value: is_paywall, onChange: isPaywallChange, ...restIsPaywallField } } = useController({ name: 'is_paywall', control });

  // console.log('<<<---this--is-the__title---->>>', title, genreList)

  const onSubmitHandler = async (data) => {
    // await trigger();
    setUploading(true);
    const authorPicture = author_image
      ? await ImageUploadHandler(author_image)
      : title?.image?.serial_number ?? title?.image;
    const contentPicture = title_image
      ? await ImageUploadHandler(title_image)
      : title?.image_upload?.serial_number;
    setUploading(false);
    data.id = title?.id;
    data.author_image = authorPicture?.serial_number;
    data.title_image = contentPicture?.serial_number;
    editMetaData(data);
    // console.log('<<<---this--is-the__data---->>>', data)
    // handleUpload(data)
  };

  return (
    title && (
      <form
        className="rss-uploaded_content__form"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <div className="side-padding">
          <h3 className="metadata__text">Metadata</h3>
          <Row>
            <Col sm="6">
              <div className="form-input__container">
                <label className="">Content Title</label>
                <input
                  className="form-control"
                  placeholder="Content Title"
                  defaultValue={title?.title}
                  {...register("title")}
                />
              </div>

              <div className="form-input__container">
                <label className="">Synopsis of each Chapter/ Episode</label>
                <input className="form-control" placeholder="Synopsis" />
                <div className="input-info__wrapper d-flex ">
                  <div className="input-info_icon__wrapper">
                    <img src={info} alt="icon" />
                  </div>
                  <p className="input-info_text">
                    Eg: Episode 1: This is what episode 1 is about. Episode 2:
                    This is.... (For Podcasters, fill as 'I am a podcaster).
                  </p>
                </div>
              </div>
            </Col>
            <Col sm="6">
              <div className="form-input__container">
                <label className="">Description</label>
                <textarea
                  className="form-control uploaded-content__description"
                  placeholder="Enter Description"
                  rows="6"
                  defaultValue={title?.description}
                  {...register("description")}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <div className="form-input__container">
                {errors.genre ? (
                  <label className="text-danger auth-label">
                    {errors.genre?.message}
                  </label>
                ) : (
                  <label className="">Genre (s)</label>
                )}
                <Select
                  className={`form-control cstm-react__select__wrapper ${
                    errors.genre ? "error_detected" : ""
                  }`}
                  classNamePrefix="cstm-react__select"
                  isMulti
                  isClearable={false}
                  placeholder="Select Genre (s)"
                  options={
                    genreList
                      ? genreList?.map((el) => ({
                          label: el?.title,
                          value: el?.id,
                        }))
                      : []
                  }
                  defaultValue={title?.genre?.map((el) => {
                    return {
                      label: el?.genre?.title,
                      value: el?.genre?.id,
                    };
                  })}
                  onChange={(option) =>
                    genreOnChange(
                      option ? option?.map((el) => el.label).join(",") : option
                    )
                  }
                  {...restGenreField}
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="form-input__container">
                {errors.sub_genre ? (
                  <label className="text-danger auth-label">
                    {errors.sub_genre?.message}
                  </label>
                ) : (
                  <label className="">Sub Genre</label>
                )}
                <Select
                  className={`form-control cstm-react__select__wrapper ${
                    errors.sub_genre ? "error_detected" : ""
                  }`}
                  classNamePrefix="cstm-react__select"
                  isMulti
                  isClearable={false}
                  placeholder="Sub Genre"
                  options={
                    subGenreList
                      ? subGenreList?.map((el) => ({
                          label: el?.title,
                          value: el?.id,
                        }))
                      : []
                  }
                  defaultValue={title?.sub_genre?.map((el) => ({
                    label: el?.sub_genre?.title,
                    value: el?.sub_genre?.id,
                  }))}
                  onChange={(option) =>
                    subGenreOnChange(
                      option ? option?.map((el) => el.label).join(",") : option
                    )
                  }
                  {...restSubGenreField}
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="form-input__container">
                {errors.type_id ? (
                  <label className="text-danger auth-label">
                    {errors.type_id?.message}
                  </label>
                ) : (
                  <label className="">Type</label>
                )}
                <Select
                  className={`form-control cstm-react__select__wrapper ${
                    errors.type_id ? "error_detected" : ""
                  }`}
                  classNamePrefix="cstm-react__select"
                  defaultValue={[
                    {
                      label: title?.type?.title,
                      value: title?.type?.id,
                    },
                  ]}
                  isClearable={false}
                  placeholder="Select Option"
                  options={
                    typesList
                      ? typesList?.map((el) => ({
                          label: el?.title,
                          value: el?.id,
                        }))
                      : []
                  }
                  onChange={(option) =>
                    typeOnChange(option ? option.value : option)
                  }
                  {...restTypeField}
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="form-input__container">
                {errors.language ? (
                  <label className="text-danger auth-label">
                    {errors.language?.message}
                  </label>
                ) : (
                  <label className="">Language (s)</label>
                )}
                <Select
                  className={`form-control cstm-react__select__wrapper ${
                    errors.language ? "error_detected" : ""
                  }`}
                  classNamePrefix="cstm-react__select"
                  isMulti
                  isClearable={false}
                  placeholder="Select Language (s)"
                  options={
                    languageList
                      ? languageList?.map((el) => ({
                          label: el?.name,
                          value: el?.id,
                        }))
                      : []
                  }
                  defaultValue={title?.language?.map((el) => ({
                    label: el?.language?.name,
                    value: el?.language?.id,
                  }))}
                  onChange={(option) =>
                    languageOnChange(
                      option ? option?.map((el) => el.label).join(",") : option
                    )
                  }
                  {...restLanguageField}
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="form-input__container">
                {errors.authors ? (
                  <label className="text-danger ">
                    {errors.authors?.message}
                  </label>
                ) : (
                  <label className="">Author (s)</label>
                )}
                <input
                  className={`form-control w-100 ${
                    errors.authors ? "border-danger" : ""
                  }`}
                  placeholder="Author (s)"
                  defaultValue={title?.authors_name
                    ?.map(
                      (ele, i) =>
                        `${ele?.authors_name.name}${
                          i <= title?.authors_name?.length - 1 ? "" : ","
                        }`
                    )
                    .join(" ")}
                  {...register("authors")}
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="form-input__container">
                {errors.author_description ? (
                  <label className="text-danger ">
                    {errors.author_description?.message}
                  </label>
                ) : (
                  <label className="">Author Description</label>
                )}
                <input
                  className={`form-control w-100 ${
                    errors.author_description ? "border-danger" : ""
                  }`}
                  placeholder="Author Description"
                  defaultValue={title?.authors_name
                    ?.map((ele) => ele?.authors_name.description)
                    .join(" ")}
                  {...register("author_description")}
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="form-input__container">
                {errors.narrators ? (
                  <label className="text-danger ">
                    {errors.narrators?.message}
                  </label>
                ) : (
                  <label className="">Narrator (s)</label>
                )}
                <input
                  className={`form-control w-100 ${
                    errors.narrators ? "border-danger" : ""
                  }`}
                  placeholder="Narrator (s)"
                  defaultValue={title?.narrators_name
                    ?.map((ele) => ele?.narrators_name?.name)
                    .join(" ")}
                  {...register("narrators")}
                />
                <div className="input-info__wrapper d-flex ">
                  <div className="input-info_icon__wrapper">
                    <img src={info} alt="icon" />
                  </div>
                  <p className="input-info_text">
                    For everyone apart from podcasters. Podcasters please fill
                    narrator as 'I am a podcaster.
                  </p>
                </div>
              </div>
            </Col>
            <Col sm="6">
              <div className="form-input__container">
                <label className="">Publisher (If Applicable)</label>
                <input
                  className="form-control"
                  placeholder="Content Title"
                  {...register("publishers")}
                />
                <div className="input-info__wrapper d-flex ">
                  <div className="input-info_icon__wrapper">
                    <img src={info} alt="icon" />
                  </div>
                  <p className="input-info_text">
                    Not necessary for podcasters.
                  </p>
                </div>
              </div>
            </Col>
            {/* <Col sm='6'>
                        <div className='form-input__container'>
                            {
                                errors.is_paywall ? <label className='text-danger auth-label'>{errors.is_paywall?.message}</label> : <label className=''>Is paywall</label>
                            }
                            <Select
                                className={`form-control cstm-react__select__wrapper ${errors.is_paywall ? 'error_detected' : ""}`}
                                classNamePrefix="cstm-react__select"
                                placeholder='Is paywall'
                                options={[
                                    {
                                        label: 'Outside Paywall',
                                        value: '0'
                                    },
                                    {
                                        label: 'Inside Paywall',
                                        value: '1'
                                    }
                                ]

                                }
                                defaultValue={
                                    {
                                        label: title?.is_paywall === '0' ? 'Outside Paywall' : 'Inside Paywall',
                                        value: title?.is_paywall === '0' ? '0' : '1'
                                    }
                                }
                                onChange={option => isPaywallChange(option ? option?.value : option)}
                                {...restIsPaywallField}
                            />
                            <div className='input-info__wrapper d-flex '>
                                <div className='input-info_icon__wrapper'>
                                    <img src={info} alt='icon' />
                                </div>
                                <p className='input-info_text'>
                                Do you want your work Outside the Paywall or Inside the Paywall? Outside the paywall means you will earn revenue on Ads and subscriptions as listeners can listen for free once they have the app, while Inside the Paywall means you will earn revenue on subscriptions only as listeners will need to be on Genti premium to listen to your work (Recommended for audiobooks)
                                </p>

                            </div>
                        </div>


                    </Col> */}
          </Row>
          <div className="upload-content__image__wrapper">
            <Row className="gy-4">
              <Col sm="12" md="6">
                <label className="">
                  Content Cover Art as available (Square)
                </label>

                <Dropzone
                  name={"title_image"}
                  register={register}
                  setValue={setContentImage}
                  onChange={setContentImage}
                />
                <div className="no-cover_art__wrapper d-flex align-items-center">
                  <div className="">
                    <input
                      type="checkbox"
                      name="checkbox"
                      className="cstm-checkbox__input"
                      role="button"
                    />
                  </div>
                  <p className="mb-0 no-cover_art__text">
                    No Image? No problem. Genti will create one for you, Tick
                    this box
                  </p>
                </div>
              </Col>
              <Col sm="12" md="6">
                <label className="">Authors Image</label>

                <Dropzone
                  name={"author_image"}
                  register={register}
                  setValue={setAuthorImage}
                  onChange={setAuthorImage}
                />
              </Col>
            </Row>
          </div>
        </div>

        <div className="d-flex justify-content-end rss-upload-button__wrapper">
          <div className="side-padding">
            <button
              className="btn auth-btn "
              type="submit"
              onClick={() => {
                // setSection('RSS_Preview')
              }}
              disabled={loading || uploading}
            >
              {loading || uploading ? <ButtonLoader /> : "Next"}
            </button>
          </div>
        </div>
      </form>
    )
  );
};

export default Form;
