import React, { useState, useEffect } from 'react'
import AuthLayout from '../../layout/Auth'
import OtpForm from '../../components/OTP'
import arrowIcon from '../../assets/img/arrow.svg'
import { useNavigate } from 'react-router-dom'
import Success from '../../components/SuccessAnimation'
import Cookies from 'js-cookie';
import Client from '../../client'
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import ButtonLoader from '../../components/ButtonLoader';



const LoginOTP = () => {
    const userEmail = Cookies.get("GENTI_LOGIN_USER_EMAIL")
    const navigate = useNavigate()
    const [otp, setOtp] = useState('')
    const [secondsLeft, setSecondsLeft] = useState(60)
    const [resend, setResend] = useState(false)
    const [verificationSuccess, setVerificationSuccess] = useState(false)

    const { mutate: requestOTP, isLoading } = useMutation(Client.auth.request_otp, {
        onSuccess: async (data) => {
        },
        onError: (error) => {
            if (error.data) toast.error(error.data.message)
            toast.error(error.message)

        }
    })

    const { mutate: verifyOTP, isLoading: verifying } = useMutation(Client.auth.verify_otp, {
        onSuccess: (data) => {
            Cookies.remove("GENTI_LOGIN_USER_EMAIL")
            // setVerificationSuccess(true)
        },
        onError: (error) => {
            if (error.response) return toast.error(error.response.data.error.message)
            else if (error.data) return toast.error(error.data.message)
            else return toast.error(error.message)

        }
    })


    const handleOTPVerification = () => {
        const data = {
            email: userEmail,
            code: otp,
        }
        verifyOTP(data)
    }


    useEffect(() => {
        if (secondsLeft > 0) {
            setTimeout(() => setSecondsLeft(secondsLeft - 1), 1000);
        } else {
            setResend(true)

        }
    }, [secondsLeft])


    // useEffect(() => {
    //     const data = {
    //         email: userEmail
    //     }
    //     requestOTP(data)

    //     // eslint-disable-next-line
    // }, [])

    return (
        <AuthLayout>
            {
                !verificationSuccess ?
                    <form className='auth-form position-relative' autocomplete='off' >
                        <div className='mb-3 '>
                            <h3 className='form-title'>Enter OTP</h3>
                        </div>
                        <div className='text-center '>
                            <p className='otp-text mb-0'>Please enter the 4-digit code sent to</p>
                            <p className='verify-email'>{userEmail}</p>
                        </div>

                        <div className='otp-verify-wrapper'>
                            <OtpForm otp={otp} setOtp={setOtp} />
                        </div>


                        <div className='sign-in-btn' type='submit'>
                            <button className='btn auth-btn w-100' disabled={isLoading || verifying || otp.length < 4} onClick={(e) => {
                                e.preventDefault()
                                // setVerificationSuccess(true)
                                handleOTPVerification()
                            }}>
                                {
                                    verifying ?
                                        <ButtonLoader /> :
                                        'Confirm'
                                }

                            </button>
                        </div>

                        <div>
                            {
                                resend ? <p role='button' className='verify-email' onClick={() => {
                                    const data = {
                                        email: userEmail
                                    }
                                    setResend(false)
                                    setSecondsLeft(60)
                                    requestOTP(data)
                                }}>
                                    Resend OTP
                                </p> : <p className='resend-otp-text'>
                                    Resend OTP in  <span className='resend-countdown'>{`${secondsLeft}s`}</span>
                                </p>
                            }

                        </div>

                        <div className='back-icon d-flex align-items-center justify-content-center position-absolute' role='button' onClick={() => {
                            navigate('/auth/register')
                        }}>
                            <img src={arrowIcon} alt='arrow' className='arrow-icon' />
                        </div>

                    </form>
                    :
                    <form className='auth-form position-relative'>
                        <div className='animation-wrapper'>
                            <Success />
                        </div>
                        <p className='congrats-text'>Congratulations! </p>
                        <p className='congrats-sub-text'>You have successfully signed up on Genti platform as an Author</p>
                        <div className='sign-in-btn'>
                            <button className='btn auth-btn w-100 ' onClick={(e) => {
                                e.preventDefault()
                                navigate("/auth/login")
                            }}>
                                Done
                            </button>
                        </div>
                    </form>
            }

        </AuthLayout>
    )
}

export default LoginOTP