import React, { useState } from 'react'
import notification from '../../assets/img/notification.svg'
import select from '../../assets/img/headerselect.svg'
// import user from '../../assets/img/usee.svg'
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/img/logo.svg'
import ham from '../../assets/img/ham.svg'

import MobileSidebar from './MobileSidebar';


const getInitials = (fullName) => {
    const allNames = fullName.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
        if (index === 0 || index === allNames.length - 1) {
            acc = `${acc}${curr.charAt(0).toUpperCase()}`;
        }
        return acc;
    }, '');
    return initials;
}


const Header = () => {
    const currentUser = JSON.parse(Cookies.get("CREATOR_CURRENT_USER"))
    const navigate = useNavigate()

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar)
    }

    const [showSidebar, setShowSidebar] = useState(false)


    return (
        <>
            <header className='d-none d-lg-flex justify-content-between position-fixed  px-5 align-items-center'>
                <div className='d-flex align-items-center header-right-wrapper justify-content-end w-100' >
                    <div className='header-select-wrapper'>
                        <select id='content-select' name='content-select' className='header-select' >
                            <option >English</option>
                        </select>
                    </div>
                    <div className='header-notification-wrapper d-flex align-items-center justify-content-center'>
                        <img src={notification} alt='icon' />
                    </div>

                    <div className='author-details-wrapper d-flex align-items-center'>
                        <div className='user-initials__wrapper'>
                            {
                                getInitials(currentUser.name)
                            }
                        </div>
                        <div className='user-details' role='button' onClick={() => {
                            navigate('/profile')
                        }}>
                            <p className='header-user-name mb-0'>{currentUser.name}</p>
                            <p className='header-user-email mb-0'>{currentUser.email}</p>
                        </div>
                        <div>
                            <img src={select} alt='icon' />
                        </div>
                    </div>
                </div>
            </header>

            <header className='d-flex d-lg-none justify-content-between position-fixed  p-3 align-items-center mobile-header'>
                <div className='d-flex align-items-center'>
                    <div onClick={toggleSidebar}>
                        <img src={ham} alt='icon' />
                    </div>
                    <div className='mobile-logo__wrapper'>
                        <img src={logo} className='mobile-logo' />
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='header-notification-wrapper d-flex align-items-center justify-content-center mx-2'>
                        <img src={notification} alt='icon' />
                    </div>
                    <div className='user-initials__wrapper'>
                        {
                            getInitials(currentUser.name)
                        }
                    </div>
                </div>


            </header>


            <MobileSidebar
                show={showSidebar}
                toggle={toggleSidebar}
            />
        </>
    )
}

export default Header