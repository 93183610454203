import React, { useState } from 'react'
// import backArrow from '../../../../assets/img/backArrow.svg'
import RSSUpload from './Upload'
import RSSContent from './Content'
import RSSPreview from './Preview'
import CopyRight from '../Copyright'

const RSS = () => {
    const [section, setSection] = useState('RSS_Upload')

    return (
        <>
            {section === 'RSS_Upload' && <RSSUpload setSection={setSection} />}
            {section === 'RSS_Content' && <RSSContent setSection={setSection} />}
            {section === 'RSS_Preview' && <RSSPreview setSection={setSection} />}
            {section === 'Copyright' && <CopyRight setSection={setSection} />}


        </>



    )
}

export default RSS