export const handleUTMLink = (url) => {
    window.open(url, '_blank')
}
export const handleWhatsappChat = () => {
    window.open('https://api.whatsapp.com/send/?phone=%2B2349167391320&text=Hi+Genti+Audio+Team%2C+I+need+some+help&type=phone_number&app_absent=0', '_blank')
}
export const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)


export const handleSocialRedirect = (social) => {
    let link;
    switch (social) {
        case 'facebook':
            link = 'https://www.facebook.com/Gentimedia';
            break;
        case 'twitter':
            link = 'https://twitter.com/GentiMedia';
            break;
        case 'medium':
            link = 'https://medium.com/@gentimedia';
            break;
        case 'instagram':
            link = 'https://instagram.com/gentimedia?igshid=MzRlODBiNWFlZA==';
            break;
        case 'linkedin':
            link = 'https://www.linkedin.com/company/genti-media/';
            break;
        case 'youtube':
            link = 'https://youtube.com/@gentimedia?si=2VCOIBPdG3Sdqwiw';
            break;
        case 'tiktok':
            link = 'https://www.tiktok.com/@gentimedia?lang=en';
            break;
        default:
            link = '/'; // Default to an empty string or you can provide a default link
            break;
    }
    window.open(link, '_blank');
};