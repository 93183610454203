import React, { useState } from "react";
import backArrow from "../../../../assets/img/backArrow.svg";
import Form from "./Form";
import RSS from "../RSS";
import { useNavigate } from "react-router-dom";

const MP3 = ({
  setSection,
  title,
  languageList,
  genre,
  subGenre,
  types,
  editMetaData,
  loading,
}) => {
  const [contentType, setContentType] = useState("MP3");
  const navigate = useNavigate();

  if (contentType === "RSS_Upload") return <RSS />;

  return (
    <div>
      <p className="page-title">
        Content/<span className="page-sub-title text-white">Edit Content</span>
      </p>

      <div className="mb-4 d-flex align-items-center">
        <div
          style={{
            marginRight: "9px",
          }}
          onClick={() => {
            navigate(-1);
          }}
          role="button"
        >
          <img src={backArrow} alt="icon" />
        </div>
        <h4 className="page-details mb-0">Edit Content</h4>
      </div>

      <div className="third-row analytics-card content-table withdraw rss-upload--form__wrapper no-side__padding">
        <div className="d-block d-lg-flex align-items-center content-select__wrapper side-padding">
          <p className="content-select__text mb-0">
            What is the format of your existing audio?
          </p>
          <div className="content-select_form ">
            {/* <label className="radio_label">
                            <input value={'RSS_Upload'} className='content-select_input' name="content-type"
                                checked={contentType === 'RSS_Upload'}
                                type="radio" onChange={(e) => {
                                    setContentType(e.target.value)

                                }} />
                            <span className='content-type__select'>RSS</span>
                        </label> */}
            <label className="radio_label">
              <input
                value={"MP3"}
                checked={contentType === "MP3"}
                className="content-select_input"
                name="content-type"
                type="radio"
                onChange={(e) => {
                  setContentType(e.target.value);
                }}
              />
              <span className="content-type__select">MP3 file</span>
            </label>
          </div>
        </div>

        <div>
          <Form
            setSection={setSection}
            title={title}
            genreList={genre}
            subGenreList={subGenre}
            typesList={types}
            languageList={languageList}
            editMetaData={editMetaData}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default MP3;
