import React, { useEffect } from 'react'
import logo from '../../assets/img/logo.svg'
// import menu from '../../Assets/Img/menu.svg'
import { useNavigate } from 'react-router-dom'
import { handleUTMLink } from '../../utils/LinkHandler'

const Header = ({ className }) => {
    const navigate = useNavigate()

    return (

        <div className={`header-wrapper w-100 position-fixed top-0 ${className}`}>
            <div className='px-3 px-md-5 d-flex align-items-center justify-content-between'>
                <div className='logo-container cursor-pointer' onClick={() => {
                    navigate('/')
                }}>
                    <img src={logo} />
                </div>
                <div className='d-none d-lg-flex align-items-center'>
                    {/* <a href='https://player.gentimedia.com/' rel="noreferrer" target="_blank" className='nav-item mb-0 '>Listen on web</a> */}
                    <button className='btn genti-gradient_btn text-white' onClick={() => handleUTMLink('https://gentiaudio.app.link')}>
                        Listen on Genti Audio
                    </button>

                </div>
                <div className='d-block d-lg-none '>
                    {/* <img alt='menu_icon' src={menu} /> */}
                    <button className='btn genti-gradient_btn text-white mobile-header_btn' onClick={() => handleUTMLink('https://gentiaudio.app.link')}>
                        Listen on Genti Audio
                    </button>
                </div>
            </div >


        </div >
    )
}

export default Header