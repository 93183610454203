export function formatter(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
}
export function scrollToTop() {
  window.scrollTo(0, 0);
  document.querySelector(".genti-main-container").scrollTo(0, 0);
}
export const getInitials = (fullName) => {
  if (fullName) {
    const allNames = fullName.trim().split(" ");
    const initials = allNames.reduce((acc, curr, index) => {
      if (index === 0 || index === allNames.length - 1) {
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, "");
    return initials;
  } else {
    return "U";
  }
};

export function formatDate(dateString) {
  const dateComponents = dateString?.split("-");
  const formattedDate =
    dateComponents[2] + "-" + dateComponents[1] + "-" + dateComponents[0];

  return dateString ? formattedDate : "";
}

export function formatDateString(dateString) {
  const date = new Date(dateString);

  const options = { day: "numeric", month: "long", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-GB", options);

  return formattedDate;
}
