import React, { useState } from 'react'
import { Modal } from 'reactstrap'
import Pin from './Pin'
import { useMutation } from 'react-query'
import Client from '../../../client'
import { toast } from 'react-toastify';
import ButtonLoader from '../../../components/ButtonLoader'


const TransactionDetails = ({ isOpen, toggle, userType, bankList, amount, refetchWalletBalance }) => {
    const [transactionSuccessful, setTransactionSuccesful] = useState(false)

    const toggleTransactionSuccess = () => {
        setTransactionSuccesful(!transactionSuccessful)
    }
    const [enterPin, setEnterPin] = useState(false)
    const togglePin = () => {
        setEnterPin(!enterPin)
    }

    const { mutate: makeWithdrawal, isLoading } = useMutation(Client.transactions.make_withdrawal, {
        onSuccess: (data) => {
            if (data.error) {
                toast.error(data.error.message)
                // navigate('/auth/verify-login')
            } else {
                toast.success(data.message)
                toggle()
                toggleTransactionSuccess()
                refetchWalletBalance()
                togglePin()

            }
        },
        onError: (error) => {
            if (error.response) return toast.error(error.response.data.message)
            else if (error.data) return toast.error(error.data.message)
            else return toast.error(error.response?.data.message)

        }
    })


    const withdraw = (pin) => {
        const data = {
            withdrawal_amount: Number(amount.replace(/,/g, '')),
            bank_code: bankList.bank_code,
            bank_name: bankList.bank_name,
            account_name: bankList.account_name,
            account_number: bankList.account_number,
            transaction_pin: pin
        }
        makeWithdrawal(data)
        // console.log(data)
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={toggle}
                className='modal-dialog-centered '
                contentClassName="upload-success__modal_content-wrapper  "
            >
                <div className='analytics-card transaction__modal'>
                    <h3 className='confirm-transaction__modal_text text-center'>Confirm to Withdraw</h3>

                    <div className='transactions-details__wrapper'>
                        <div className='d-flex align-items-center justify-content-between transaction_details_container'>
                            <p className='transaction_details_name mb-0'>Amount</p>
                            <p className='transaction_details_value m-0'>{`${amount ?? 0}`}</p>
                        </div>
                        <div className='d-flex align-items-center justify-content-between transaction_details_container'>
                            <p className='transaction_details_name mb-0'>Bank Name</p>
                            <p className='transaction_details_value m-0'>{bankList?.bank_name}</p>
                        </div>
                        <div className='d-flex align-items-center justify-content-between transaction_details_container'>
                            <p className='transaction_details_name mb-0'>Account Number</p>
                            <p className='transaction_details_value m-0'>{bankList?.account_number}</p>
                        </div><div className='d-flex align-items-center justify-content-between transaction_details_container'>
                            <p className='transaction_details_name mb-0'>Account Name</p>
                            <p className='transaction_details_value m-0'>{bankList?.account_name}</p>
                        </div>

                    </div>
                    <div>

                        <div className=' d-flex align-items-center justify-content-between'>
                            <div className='w-100 ' style={{
                                marginRight: '9px'
                            }}>
                                <button className='btn  w-100 cancel-btn' type='submit'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        toggle()
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                            <div className='w-100 ' style={{
                                marginLeft: '9px'
                            }}>
                                <button className='btn auth-btn w-100' type='submit'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        toggle()
                                        togglePin()
                                        // withdraw()
                                    }}

                                    disabled={isLoading}
                                >
                                    {
                                        isLoading ?
                                            <ButtonLoader /> :
                                            'Withdraw'
                                    }

                                </button>
                            </div>


                        </div>

                    </div>
                </div>

            </Modal >
            <Pin
                toggle={togglePin}
                isOpen={enterPin}
                userType={userType}
                withdraw={withdraw}
                toggleTransactionSuccess={toggleTransactionSuccess}
                transactionSuccessful={transactionSuccessful}
                withdrawing={isLoading}

            />
        </>

    )
}

export default TransactionDetails