import React, { useState } from 'react'
import { Row, Col } from 'reactstrap'
import { useForm, useController } from "react-hook-form";
import Cookies from 'js-cookie';
import Select from '../../../components/SelectInput'
import countryCode from '../../../utils/countryCode'
import changePFP from '../../../assets/img/changePFP.svg'
import { useQuery, useMutation } from 'react-query'
import Client from '../../../client';
import { toast } from 'react-toastify';
import ButtonLoader from '../../../components/ButtonLoader';

const getInitials = (fullName) => {
  const allNames = fullName.trim().split(' ');
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, '');
  return initials;
}

const PersonalDetails = () => {
  const currentUser = JSON.parse(Cookies.get("CREATOR_CURRENT_USER"))
  const [refresh,setRefresh]=useState(false)

  const [user, setUser] = useState(null)

  const defaultValues = {
    name: user?.name,
    email: user?.email,
    country_id: user?.country_id,
    mobile_number: user?.mobile_number,
    dob: user?.dob,
    address: user?.address

  };

  const { register, handleSubmit, formState: { errors }, control, reset } = useForm({
    defaultValues: defaultValues
  });
  const { field: { value: type_id, onChange: typeOnChange, ...restTypeField } } = useController({ name: 'type_id', control });

  useQuery(['user-details', refresh], () => Client.profile.get_user_profile(), {
    onSuccess: (data) => {
      setUser(data.data.user)
      reset(data.data.user)
    }
  })

  const { mutate: updateProfile, isLoading: updating } = useMutation(Client.profile.update_profile, {
    onSuccess: (data) => {
      toast.success(data.data.message)
      setRefresh(!refresh)
    },
    onError: (err) => {
    }
  })


  const onSubmitHandler = (values) => {
    const {
      name,
      email,
      country_id,
      mobile_number,
      dob,
      address
    } = values
    const data = {
      name,
      email,
      country_id,
      mobile_number,
      dob,
      address
    }
    updateProfile(data)

  }

  return (
    <div>
      <div className='mb-4'>
        <h3 className='password-settings__text'>Personal Details</h3>
      </div>
      <div className='d-flex justify-content-betwee'>
        <div className='creator-profile-image__wrapper d-flex align-items-center justify-content-center position-relative ' style={{
          flexShrink: 0
        }}>
          {
            getInitials(user ? user?.name : currentUser.name)
          }
          <div className='position-absolute change-creator-profile__image d-flex align-items-center justify-content-center'>
            <img src={changePFP} alt='icon' />
          </div>
        </div>
        <div className='w-100'>
          <form
            className='upload-content__form rss-input__form w-100 overflow-hidden mt-0'
            onSubmit={handleSubmit(onSubmitHandler)}
          >
            <Row>
              <Col sm='12'>
                <div className='mb-4  no-border'>
                  {
                    errors.name ? <label className='text-danger '>{errors.name?.message}</label> : <label className=''>Fullname</label>
                  }
                  <input
                    className={`form-control w-100 ${errors.name ? 'border-danger' : ''}`}
                    placeholder='Fullname'
                    defaultValue={user?.name}
                    {...register("name")}
                  />
                </div>
              </Col>

            </Row>

            <div className='mb-4  no-border'>
              {
                errors.email ? <label className='text-danger '>{errors.email?.message}</label> : <label className=''>Email</label>
              }
              <input
                className={`form-control w-100 ${errors.email ? 'border-danger' : ''}`}
                type='email'
                value={user?.email}

                placeholder='Email'
                {...register("email")}
              />
            </div>

            <div className='mb-4 '>

              {errors.mobile_number ? <p className='text-danger auth-label mb-0'>{errors.mobile_number?.message}</p> : errors.country_id ? <p className='text-danger auth-label mb-0'>{errors.country_id?.message}</p> : <label className='auth-label'>Phone Number</label>}
              <div className={`${errors.mobile_number || errors.country_id ? 'border-danger' : ""} d-flex phone-number-wrapper`}>
                <div className='d-flex align-items-center w-5' style={{
                  paddingLeft: '13px',
                  width: '80px'
                }}>
                  <div className='text-white'>+</div>
                  <select className='phone-input-select  w-100'
                    style={{
                      paddingRight: '0'
                    }} {...register("country_id")}>
                    {countryCode.map((el, i) =>
                      <option
                        label={`${el?.code}`}
                        value={`${el?.code}`}
                        // eslint-disable-next-line
                        selected={el?.code == user?.country_id?.toString()}>
                        {`+${el?.code}`}
                      </option>
                    )}
                  </select>
                </div>

                <span />
                <input type='phone'
                  readOnly
                  placeholder='Phone Number'
                  defaultValue={user?.mobile_number}
                  onFocus={(e) => e.target.removeAttribute('readonly')}
                  className='auth-input form-control w-75 border-set-right'
                  {...register("mobile_number")}
                />
              </div>
            </div>
            <div className='mb-4  no-border'>
              {
                errors.rss ? <label className='text-danger '>{errors.dob?.message}</label> : <label className=''>Date of Birth</label>
              }
              <input
                defaultValue={user?.dob}
                className={`form-control w-100 ${errors.dob ? 'border-danger' : ''}`}
                // placeholder='Account Number'
                {...register("dob")}
                type='date'
              />
            </div>

            <div className=''>
              {
                errors.type_id ? <label className='text-danger auth-label'>{errors.type_id?.message}</label> : <label className=''>Creator Type</label>
              }
              <Select
                className={`mb-4 form-control rss-type__select cstm-react__select__wrapper w-100 ${errors.type_id ? 'error_detected' : ""}`}
                placeholder='Select Type'
                options={[]
                }

                onChange={option => typeOnChange(option ? option.value : option)}
                {...restTypeField}
              />
            </div>

            <div className='mb-2  no-border'>
              {
                errors.rss ? <label className='text-danger '>{errors.address?.message}</label> : <label className=''>Address</label>
              }
              <input
                className={`form-control mb-2 w-100 ${errors.rss ? 'border-danger' : ''}`}
                placeholder='Address'
                defaultValue={user?.address}
                {...register("address")}
              />

            </div>



            <div className=' rss-upload-button__wrapper no-border mb-3'>
              <div className=''>
                <button className='btn auth-btn '
                  type='submit'
                // onClick={(e) => {
                //   e.preventDefault()
                // }}
                disabled={updating}
                >
                  {
                    updating ?
                      <ButtonLoader /> :
                    'Update'
                  }
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* <Row className='gx-3'>
        <Col sm='12' md='3'>
          <div className='creator-profile-image__wrapper d-flex align-items-center justify-content-center position-relative'>
            {
              getInitials(currentUser.name)
            }
            <div className='position-absolute change-creator-profile__image d-flex align-items-center justify-content-center'>
              <img src={changePFP} alt='icon' />
            </div>
          </div>
        </Col>
        <Col sm='12' md='9'>
          <form
            className='upload-content__form rss-input__form w-100 overflow-hidden mt-0'
          // onSubmit={handleSubmit(onSubmitHandler)}
          >
            <Row>
              <Col sm='12' md='6'>
                <div className='mb-4  no-border'>
                  {
                    errors.rss ? <label className='text-danger '>{errors.rss?.message}</label> : <label className=''>First name</label>
                  }
                  <input
                    className={`form-control w-100 ${errors.rss ? 'border-danger' : ''}`}
                    placeholder='First name'
                    {...register("rss")}
                  />
                </div>
              </Col>
              <Col sm='12' md='6'>
                <div className='mb-4  no-border'>
                  {
                    errors.rss ? <label className='text-danger '>{errors.rss?.message}</label> : <label className=''>Last name</label>
                  }
                  <input
                    className={`form-control w-100 ${errors.rss ? 'border-danger' : ''}`}
                    placeholder='Last name'
                    {...register("rss")}
                  />
                </div>
              </Col>
            </Row>

            <div className='mb-4  no-border'>
              {
                errors.rss ? <label className='text-danger '>{errors.rss?.message}</label> : <label className=''>Email</label>
              }
              <input
                className={`form-control w-100 ${errors.rss ? 'border-danger' : ''}`}
                type='email'
                placeholder='Account Number'
                {...register("rss")}
              />
            </div>

            <div className='mb-4 '>

              {errors.mobile_number ? <p className='text-danger auth-label mb-0'>{errors.mobile_number?.message}</p> : errors.country_id ? <p className='text-danger auth-label mb-0'>{errors.country_id?.message}</p> : <label className='auth-label'>Phone Number</label>}
              <div className={`${errors.mobile_number || errors.country_id ? 'border-danger' : ""} d-flex phone-number-wrapper`}>
                <div className='d-flex align-items-center w-5' style={{
                  paddingLeft: '13px',
                  width: '80px'
                }}>
                  <div className='text-white'>+</div>
                  <select className='phone-input-select  w-100' style={{
                    paddingRight: '0'
                  }} {...register("country_id")}>
                    {countryCode.map((el, i) => <option label={`${el?.code}`} value={`+${el?.code}`}>{`+${el?.code}`}</option>)}
                  </select>
                </div>

                <span />
                <input type='phone'
                  readOnly
                  placeholder='Phone Number'
                  onFocus={(e) => e.target.removeAttribute('readonly')}
                  className='auth-input form-control w-75 border-set-right'
                  {...register("mobile_number")}
                />
              </div>
            </div>
            <div className='mb-4  no-border'>
              {
                errors.rss ? <label className='text-danger '>{errors.rss?.message}</label> : <label className=''>Date of Birth</label>
              }
              <input
                className={`form-control w-100 ${errors.rss ? 'border-danger' : ''}`}
                // placeholder='Account Number'
                {...register("rss")}
                type='date'
              />
            </div>

            <div className=''>
              {
                errors.type_id ? <label className='text-danger auth-label'>{errors.type_id?.message}</label> : <label className=''>Creator Type</label>
              }
              <Select
                className={`mb-4 form-control rss-type__select cstm-react__select__wrapper w-100 ${errors.type_id ? 'error_detected' : ""}`}
                placeholder='Select Type'
                options={[]
                }

                onChange={option => typeOnChange(option ? option.value : option)}
                {...restTypeField}
              />
            </div>

            <div className='mb-2  no-border'>
              {
                errors.rss ? <label className='text-danger '>{errors.rss?.message}</label> : <label className=''>Address</label>
              }
              <input
                className={`form-control mb-2 w-100 ${errors.rss ? 'border-danger' : ''}`}
                placeholder='Address'
                {...register("rss")}
              />

            </div>



            <div className=' rss-upload-button__wrapper no-border mb-3'>
              <div className=''>
                <button className='btn auth-btn ' type='submit'
                  onClick={(e) => {
                    e.preventDefault()
                  }}
                // disabled={loading}
                >
                  {
                    // loading ?
                    //   <ButtonLoader /> :
                    'Update'
                  }
                </button>
              </div>
            </div>
          </form>
        </Col>

      </Row> */}
    </div>
  )
}

export default PersonalDetails