import React, { useState } from "react";
import AuthLayout from "../../layout/Auth";
import { NavLink, useNavigate } from "react-router-dom";
// import countryCode from '../../utils/countryCode'
import { useForm, useController } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerValidation } from "../../utils/form_validation";
import { useMutation } from "react-query";
import Client from "../../client";
import { toast } from "react-toastify";
import ButtonLoader from "../../components/ButtonLoader";
import Cookies from "js-cookie";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

const Login = () => {
  const [dialCode, setDialCode] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(registerValidation),
  });

  const {
    field: { value: phone, onChange: phoneOnChange, ...restField },
  } = useController({ name: "phone", control });

  const { mutate: registerUser, isLoading } = useMutation(
    Client.auth.register,
    {
      onSuccess: async (data) => {
        // reset();
        navigate("/auth/OTP");
      },
      onError: (error) => {
        if (error.response)
          return toast.error(error.response.data.error.message);
        if (error.data) return toast.error(error.data.message);
        else toast.error(error.message);
      },
    }
  );
  function extractPhoneNumber(dialCode, fullPhoneNumber) {
    dialCode = dialCode.replace(/^[\+0]+/, "");
    const cleanedPhoneNumber = fullPhoneNumber.replace(/[ -]/g, "");
    const startIndex = cleanedPhoneNumber.indexOf(dialCode);

    if (startIndex !== -1) {
      const remainingNumbers = cleanedPhoneNumber.slice(
        startIndex + dialCode.length
      );
      return remainingNumbers;
    } else {
      return null;
    }
  }

  const onSubmitHandler = (data) => {
    const { name, email, phone, password } = data;
    const payload = {
      name,
      email,
      password,
    };
    payload.country_id = dialCode;
    payload.mobile_number = extractPhoneNumber(dialCode, phone);
    Cookies.set("GENTI_NEW_USER_EMAIL", data.email);
    registerUser(payload);
    // console.log(payload)
  };

  const navigate = useNavigate();
  // const [phone, setPhone] = useState('');
  // const [inputValue, setInputValue] = useState('');
  // const [country, setCountry] = useState(parseCountry('us'));

  // const handlePhoneValueChange = (e) => {
  //     const newValue = e.target.value;
  //     setInputValue(newValue);
  //     // onChange(newValue);
  // };

  return (
    <AuthLayout>
      <form
        className="auth-form"
        autocomplete="off"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <div className="mb-3">
          <h3 className="form-title create-text">Create Account</h3>
        </div>
        <div className="mb-4">
          {errors.name ? (
            <p className="text-danger auth-label mb-0">
              {errors.name?.message}
            </p>
          ) : (
            <label className="auth-label">Full Name</label>
          )}
          <input
            type=""
            placeholder="Your First and Last Name"
            className={`${
              errors.name ? "border-danger" : ""
            } auth-input form-control w-100`}
            {...register("name")}
          />
        </div>
        <div className="mb-4">
          {errors.email ? (
            <p className="text-danger auth-label mb-0">
              {errors.email?.message}
            </p>
          ) : (
            <label className="auth-label">Email Address</label>
          )}
          <input
            type="email"
            autocomplete="off"
            placeholder="account@email.com"
            className={`${
              errors.email ? "border-danger" : ""
            } auth-input form-control w-100`}
            {...register("email")}
          />
        </div>
        <div className="mb-4 ">
          {errors.phone ? (
            <p className="text-danger auth-label mb-0">
              {errors.phone?.message}
            </p>
          ) : (
            <label className="auth-label">Phone Number</label>
          )}

          {/* <div className={`${errors.mobile_number || errors.country_id ? 'border-danger' : ""} d-flex phone-number-wrapper`}>
                        <div className='d-flex align-items-center w-5' style={{
                            paddingLeft: '13px',
                            width: '80px'
                        }}>
                            <div className='text-white'>+</div>
                            <select className='phone-input-select  w-100' style={{
                                paddingRight: '0'
                            }} {...register("country_id")}>
                                {countryCode.map((el, i) => <option label={`${el?.code}`} value={`+${el?.code}`}>{`+${el?.code}`}</option>)}
                            </select>
                        </div>

                        <span />
                        <input type='phone'
                            readOnly
                            placeholder='Phone Number'
                            onFocus={(e) => e.target.removeAttribute('readonly')}
                            className='auth-input form-control w-75 border-set-right'
                            {...register("mobile_number")}
                        />

                    </div> */}
          <PhoneInput
            className={`${errors.phone ? "error-detected" : ""}`}
            // {...register("phone")}
            defaultCountry="ng"
            value={phone}
            // onChange={(phone) => setPhone(phone)}

            style={{
              "--react-international-phone-border-radius": "6px",
              "--react-international-phone-border-color": "#8C8C8C",
              "--react-international-phone-background-color": "#262626",
              "--react-international-phone-text-color": "white",
              "--react-international-phone-selected-dropdown-item-background-color":
                "black",
              "--react-international-phone-country-selector-background-color-hover":
                "black",
              "react-international-phone-input-width": "100%",
            }}
            // charAfterDialCode='-'
            onChange={(option, e) => {
              setDialCode(e.country.dialCode);
              phoneOnChange(option);
            }}
            {...restField}
          />
        </div>

        <div className="mb-5">
          {errors.password ? (
            <p className="text-danger auth-label mb-0">
              {errors.password?.message}
            </p>
          ) : (
            <label className="auth-label" tabIndex={0}>
              Password
            </label>
          )}

          <div className="password-input-wrappe  d-flex align-items-center justify-content-between position-relative">
            <input
              type={`${showPassword ? "text" : "password"}`}
              className={`${
                errors.password ? "border-danger" : ""
              } auth-input form-control w-100 `}
              readOnly
              onClick={(e) => e.target.removeAttribute("readonly")}
              {...register("password")}
            />
            <div
              className="visibility-icon position-absolute"
              onClick={togglePassword}
              role="button"
            >
              {`${showPassword ? "HIDE" : "SHOW"}`}
            </div>
          </div>
        </div>

        <div className="sign-in-btn">
          <button
            className="btn auth-btn w-100 "
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? <ButtonLoader /> : "Create Account"}
          </button>
        </div>

        <div>
          <p className="auth-text">
            New to Genti?{" "}
            <NavLink to={"/auth/login"} className="auth-link">
              Sign In
            </NavLink>
          </p>
        </div>
      </form>
    </AuthLayout>
  );
};

export default Login;
