import React, { useState, useEffect } from 'react'
import backArrow from '../../../../assets/img/backArrow.svg'
import t1 from '../../../../assets/img/no-image.png'
import { useMutation } from 'react-query';
import Client from '../../../../client'
import Player from '../../../../components/Player'
import { toast } from 'react-toastify';
import ButtonLoader from '../../../../components/ButtonLoader'



const RSSPreview = ({ setSection, RSS, RSSLink }) => {
    const [RSSEpisodes, setRSSEpisodes] = useState([])



    const { mutate: getRSSEpisodes, isLoading } = useMutation(Client.content.rss_episodes, {
        onSuccess: (data) => {
            if (data.error) {
                toast.error(data.error.message)
            } else {
                setRSSEpisodes(data.data)
            }
        },
        onError: (error) => {
            if (error.response) return toast.error(error.response.data.error.message)
            else if (error.data) return toast.error(error.data.message)
            else return toast.error(error.message)
        }
    })

    useEffect(() => {
        const data = {
            rss: RSSLink
        }
        getRSSEpisodes(data)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        // eslint-disable-next-line
    }, [RSSEpisodes])

    return (
        <div>
            <p className='page-title'>Content/<span className='page-sub-title text-white'>Upload Content</span></p>

            <div className='mb-4 d-flex align-items-center'>
                <div
                    style={{
                        marginRight: '9px'
                    }}
                    onClick={() => {
                        setSection('RSS_Content')
                    }}
                    role='button'
                >
                    <img src={backArrow} alt='icon' />
                </div>
                <h4 className='page-details mb-0'>Upload Content</h4>

            </div>

            <div className="third-row analytics-card content-table withdraw rss-upload--form__wrapper no-side__padding">
                {isLoading ?
                    <div
                        className='d-flex align-items-center justify-content-center position-absolute'
                        style={{
                            height: '90%',
                            width: "calc(100% - 60px)"

                        }}>
                        <ButtonLoader />
                    </div> :
                    <div>
                        <div className='uploaded-rss__details__wrapper side-padding'>
                            <div className='uploaded-rss__details__header d-flex align-items-center'>
                                <div className='uploaded-rss__details__img-wrapper'>
                                    <img src={RSS?.image ? RSS?.image : t1} alt='icon' className='uploaded-rss__details__img' />
                                </div>
                                <h3 className='uploaded-rss__details__title mb-0'>
                                    {/* Name of Content here */}
                                    {RSS?.title}
                                </h3>
                            </div>
                        </div>
                        <div className='rss-uploaded_content__form upload-rss_details__container side-padding no-border'>
                            {
                                RSSEpisodes.slice(0,3).map((el, i) =>
                                    <div className='  uploaded-rss_details d-flex align-items-center justify-content-between'>

                                        <div className='w-100'>
                                            <div className='form-input__container'>
                                                <label className=''>{`Chapter ${i + 1}`}</label>
                                                <input className='form-control' value={el?.title} />
                                            </div>
                                        </div>

                                        <div className='w-100'>
                                            <div className='rss-player__container'>
                                                <Player audio={el?.audio_url} />
                                            </div>
                                        </div>


                                        <div className='w-25'>
                                        </div>

                                    </div>
                                )
                            }
                            {/* <div className='  uploaded-rss_details d-flex align-items-center justify-content-between'>

                        <div className='w-100'>
                            <div className='form-input__container'>
                                <label className=''>Chapter 1</label>
                                <input className='form-control' placeholder='Introduction to King Wakanda' />
                            </div>
                        </div>

                        <div className='w-100'>
                            <div className='rss-player__container'>
                                <Player />
                            </div>
                        </div>


                        <div className='w-25'>
                        </div>

                    </div>
                    <div className='  uploaded-rss_details d-flex align-items-center justify-content-between no-border'>

                        <div className='w-100'>
                            <div className='form-input__container'>
                                <label className=''>Chapter 2</label>
                                <input className='form-control' placeholder='Home, sweet home' />
                            </div>
                        </div>

                        <div className='w-100'>
                            <div className='rss-player__container'>
                                <Player />
                            </div>
                        </div>


                        <div className='w-25'>
                        </div>

                    </div> */}
                        </div>
                        <div className='d-flex justify-content-end rss-upload-button__wrapper'>
                            <div className='side-padding'>
                                <button className='btn auth-btn '
                                    disabled={isLoading}
                                    type='submit' onClick={() => {
                                        setSection('Copyright')
                                    }}>
                                    {/* {
                        isLoading ?
                            <ButtonLoader /> :
                            'Sign in'
                    } */}
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>

                }

            </div>
        </div >

    )
}

export default RSSPreview