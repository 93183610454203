import React from 'react'
import NoDocuments from '../../../assets/img/NoDocuments.svg'
const EmptyContent = () => {
    return (
        <div className='empty-content__state  text-center d-flex justify-content-center align-items-center'>
            <div>
                <div className='empty-content__icon-wrapper'>
                    <img src={NoDocuments} alt='icon' />
                </div>
                <div className='empty-content__texts'>
                    <h3 className='empty-content__header'>No Episode Found</h3>
                    <p className='empty-content__text mb-0 w-100'>You currently do not have any epispde for this title.</p>

                </div>
            </div>


        </div>


    )
}

export default EmptyContent