import React from 'react'
import { Row, Col } from 'reactstrap'
import googlePlay from '../../assets/img/google_play.svg'
import appleStore from '../../assets/img/apple_store.svg'
import whatsapp from '../../assets/img/whatsapp.svg'
import fb from '../../assets/img/fb.svg'
import linkedin from '../../assets/img/linkedin.svg'
import x from '../../assets/img/x.svg'
import instagram from '../../assets/img/instagram.svg'
import ming from '../../assets/img/ming.svg'
import youtube from '../../assets/img/youtube.svg'
import tiktok from '../../assets/img/tiktok.svg'
import { handleWhatsappChat } from '../../utils/LinkHandler'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/img/logo.svg'


const Footer = () => {
    const navigate = useNavigate()
    return (
        <footer className='px-5 pt-5 pb-4 footer-section border-top'>
            <div className=''>
                <Row className='gy-5 gx-md-5'>

                    {/* <Col sm='12' md='6' xl='7'> */}
                    <Col sm='12' md='12' xl='12'>

                        <div className='ms-xl-4 ms-0'>
                            <Row className='gx-md-5 gx-1 gy-5'>
                                <Col xs='6' xl='4'>
                                    <div className=''>
                                        <div className='mb-3'>
                                            <img src={logo} />
                                        </div>
                                        <p>
                                            Sign up with Genti Creator Portal: Effortlessly manage, monetize, and track your audio stories in real-time. Connect with listeners worldwide and turn your creativity into earnings!
                                        </p>
                                    </div>

                                </Col>
                                <Col xs='6' xl='4'>
                                    <div className=''>
                                        <h3 className='footer-header_text'>Need Help?</h3>
                                        <ul className='footer-link__wrapper'>
                                            <li onClick={() => navigate('/faqs')}>FAQs</li>
                                            <li onClick={() => navigate('https://youtu.be/rVRNtW4mACA')}>Creator Portal WalkThrough</li>

                                            <li>
                                                <a href="mailto:support@gentimedia.com">Email Us</a>
                                            </li>
                                        </ul>
                                        <div className=''>
                                            {/* <h3 className='footer-header_text' >Chat with us</h3> */}
                                            <div className='footer-contact' role='button' onClick={handleWhatsappChat}>
                                                <img src={whatsapp} alt='whatsapp' />
                                            </div>
                                        </div>
                                    </div>

                                </Col>
                                <Col xs='6' xl='4'>
                                    <div className=' mt-4'>
                                        <p className='footer-sub-text mb-1'>Follow us</p>
                                        <div className='d-flex align-items-center'>
                                            <div className='pe-2' role='button'>
                                                <img src={fb} alt='social' />
                                            </div>
                                            <div className='pe-2' role='button'>
                                                <img src={linkedin} alt='social' />
                                            </div><div className='pe-2' role='button'>
                                                <img src={x} alt='social' />
                                            </div><div className='pe-2' role='button'>
                                                <img src={instagram} alt='social' />
                                            </div><div className='pe-2' role='button'>
                                                <img src={ming} alt='social' />
                                            </div><div className='pe-2' role='button'>
                                                <img src={youtube} alt='social' />
                                            </div><div className='' role='button'>
                                                <img src={tiktok} alt='social' />
                                            </div>

                                        </div>
                                    </div>
                                </Col>


                            </Row>
                        </div>

                    </Col>
                </Row >
                <div className='copyright_container text-center pt-4 mt-4'>
                    <p className='text-white mb-0'>{`Genti Media ${new Date().getFullYear()}. All rights reserved`}</p>
                </div>
            </div >

        </footer >

    )
}

export default Footer