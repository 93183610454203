import React from 'react'
import NoDocuments from '../../assets/img/NoDocuments.svg'
const EmptyContent = () => {
    return (
        <div className='empty-content__state  text-center d-flex justify-content-center align-items-center'>
            <div>
                <div className='empty-content__icon-wrapper'>
                    <img src={NoDocuments} alt='icon' />
                </div>
                <div className='empty-content__texts'>
                    <h3 className='empty-content__header'>No Content Found</h3>
                    <p className='empty-content__text mb-0'>You currently do not have any content.</p>
                    <p className='empty-content__text mb-0'>Click on the <span className='text-cstm__primary'>Upload Content</span>  button to get started</p>

                </div>
            </div>


        </div>


    )
}

export default EmptyContent