import React, { useState } from 'react'
import RSSUpload from './Upload'
import RSSContent from './Content'
import RSSPreview from './Preview'
import CopyRight from '../Copyright'
import { useQuery } from 'react-query';
import Client from '../../../../client'
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

const RSS = () => {
    const [section, setSection] = useState('RSS_Upload')
    const [uploadedRSS, setUploadedRSS] = useState(null)
    const [uploadedMetadata, setUploadedMetadata] = useState(null)
    const [uploadedRSSLink, setUploadedRSSLink] = useState('')


    const { data: types } = useQuery(['content_types'], () => Client.content.content_types())
    const { data: language } = useQuery(['content_language'], () => Client.content.content_language())
    const { data: genre } = useQuery(['content_genre'], () => Client.content.content_genre())
    const { data: sub_genre } = useQuery(['content_sub_genre'], () => Client.content.content_sub_genre())

    const { result: language_list } = language?.data || []
    const { result: genre_list } = genre?.data || []
    const { result: types_list } = types?.data || []
    const { result: sub_genre_list } = sub_genre?.data || []


    useQuery(['rss_episodes', uploadedMetadata], () => Client.content.fetch_rss_episodes(uploadedMetadata?.id), {
        enabled: Boolean(uploadedMetadata)
    })


    const { mutate: uploadRSS, isLoading } = useMutation(Client.content.upload_rss, {
        onSuccess: (data) => {
            if (data.error) {
                toast.error(data.error.message)
            } else {
                setUploadedRSS(data.data)
                setSection('RSS_Content')
            }
        },
        onError: (error) => {
            // console.log('------data-error---', error)
            if (error?.response?.data?.data) return toast.error(error.response.data.data)
            if (error.response) return toast.error(error.response.data.error.message)
            else if (error.data) return toast.error(error.data.message)
            else return toast.error(error.message)
        }
    })

    const { mutate: uploadMetaData, isLoading: uploading } = useMutation(Client.content.upload_metadata, {
        onSuccess: (data) => {
            if (data.error) {
                toast.error(data.error.message)
            } else {
                // setUploadedRSS(data.data)

                setUploadedMetadata(data.data)
                // setSection('RSS_Preview')
                setSection('Copyright')

            }
        },
        onError: (error) => {
            if (error.response) return toast.error(error.response.data.error.message)
            else if (error.data) return toast.error(error.data.message)
            else return toast.error(error.message)
        }
    })

    return (
        <>
            {section === 'RSS_Upload' && <RSSUpload
                setSection={setSection}
                types={types_list}
                handleUpload={uploadRSS}
                loading={isLoading}
                setRSSUploadedLink={setUploadedRSSLink}
            />}
            {section === 'RSS_Content' && <RSSContent
                setSection={setSection}
                RSS={uploadedRSS}
                languageList={language_list}
                genreList={genre_list}
                typesList={types_list}
                subGenreList={sub_genre_list}
                handleUpload={uploadMetaData}
                loading={uploading}
            />}
            {section === 'RSS_Preview' && <RSSPreview
                setSection={setSection}
                RSS={uploadedMetadata}
                RSSLink={uploadedRSSLink}

            />}
            {section === 'Copyright' && <CopyRight setSection={setSection} />}
        </>

    )
}

export default RSS