import React, { useState } from 'react';
import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import frenchLocale from 'date-fns/locale/en-US';
// import { DateFormatter } from 'react-day-picker';
import moment from 'moment';

export default function RangePicker({ startDate, endDate, setStartDate, setEndDate, setDateValue, setShowCalendar }) {



    let footer = <p>Please pick a day.</p>;
    const formatWeekdayName = (day) => {
        return format(day, 'EEE', { locale: frenchLocale });
    };
    const [currentInput, setCurrentInput] = useState('start_date')
    return (

        <>

            <div className=''>
                <div className='d-flex align-items-center justify-content-between w-100 cstm-date__input-wraper' >
                    <div className='cstm-start__date-wrapper'>
                        <label className='picker-label' htmlFor='start_date'>From</label>
                        <div className='position-relative'>
                            <input
                                id='start_date'
                                value={moment(startDate).format('L')}
                                className={`cstm-date__input w-100 ${currentInput === 'start_date' ? 'active' : ''}`}
                                onClick={() => {
                                    setCurrentInput('start_date')
                                }}
                            // onChange={onChange}
                            // ref={ref}
                            />
                            <div className='date_icon-wrapper position-absolute'>

                            </div>
                        </div>

                    </div>
                    <div className=''>
                        <label className='picker-label' htmlFor='end_date'>To</label>
                        <div className='position-relative'>
                            <input
                                id='end_date'
                                value={moment(endDate).format('L')}

                                className={`cstm-date__input w-100 ${currentInput === 'end_date' ? 'active' : ''}`}
                                onClick={() => {
                                    setCurrentInput('end_date')
                                }}
                            // onChange={onChange}
                            // ref={ref}
                            />
                            <div className='date_icon-wrapper position-absolute'>

                            </div>
                        </div>

                    </div>
                </div>
                <div className='day-picker__wrapper'>
                    {
                        currentInput === 'start_date' ?
                            <DayPicker
                                formatters={{ formatWeekdayName }}
                                mode="single"
                                selected={startDate}
                                onSelect={(e) => {
                                    setStartDate(e)
                                }
                                }
                                footer={footer}
                                toDate={new Date()}
                            // disabledDays={day => day > (new Date())}
                            // dis
                            /> :
                            <DayPicker
                                formatters={{ formatWeekdayName }}
                                mode="single"
                                selected={endDate}
                                onSelect={(e) => {
                                    setEndDate(e)
                                    setDateValue(true)
                                    setShowCalendar(false)
                                }
                                }
                                footer={footer}
                                toDate={new Date()}

                                // disabledDays={day => day > (new Date())}
                                disabled={day => day < startDate}
                            />
                    }

                </div>

            </div>


        </>
    );
}