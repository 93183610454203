import React from 'react'
// import b1 from '../../../../assets/img/b1.svg'
// import b2 from '../../../../assets/img/b2.svg'
// import b3 from '../../../../assets/img/b3.svg'
import thrash from '../../../../assets/img/thrash.svg'

import { Row, Col } from 'reactstrap'

const BankList = ({ setSection, bankList }) => {
    const { bank_name, account_number } = bankList
    return (
        <Row className='pb-5'>
            <Col sm='12' md='9'>
                <div className='user-saved__bank-list'>
                    <h3 className='password-settings__text mb-3'>List of Added Banks</h3>

                    <div className='user-saved-bank '>

                        <table class="table table-borderless">
                            <thead>
                                {/* <tr>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                </tr> */}
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <p className='user-bank_details text-white mb-0'><span className='mx-0'>{bank_name}</span></p>
                                    </td>
                                    <td>
                                        <p className='user-bank_details text-white mb-0'>{account_number}</p>
                                    </td>
                                    <td><img src={thrash} alt='bank' /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                <div className=' d-flex align-items-center justify-content-between w-75 mt-5'>
                    <button className='btn auth-btn w-100' type='submit'
                        onClick={(e) => {
                            e.preventDefault()
                            setSection('form')
                        }}
                    >
                        Add new
                    </button>
                </div>
            </Col>


        </Row>
    )
}

export default BankList