import React from 'react'
import { CircularProgressbar , buildStyles} from 'react-circular-progressbar';

const Progress = ({completion}) => {
    return (
        <div className='mx-auto circular_progress_bar' >
            <CircularProgressbar
                strokeWidth={19}
                counterClockwise={true}
                value={completion?completion:0}
                maxValue={100}
                text={`${completion?completion:0}%`}
                styles={buildStyles({
                    rotation: 0.05,
                    strokeLinecap: 'butt',
                    textSize: '20px',
                    pathTransitionDuration: 0.5,
                    textColor: '#FFFFFF',
                    trailColor: '#6F6F6F',
                    pathColor:'#57F2A8',
                })}
            />
        </div>
    )
}

export default Progress