import React from 'react'
import backArrow from '../../../../assets/img/backArrow.svg'
import info from '../../../../assets/img/info.svg'
import { Row, Col } from 'reactstrap'
import Select from 'react-select'
import Dropzone from '../../../../components/Dropzone'

const Upload = ({ setSection }) => {
    return (
        <>
            <div>
                <p className='page-title'>Content/<span className='page-sub-title text-white'>Upload Content</span></p>

                <div className='mb-4 d-flex align-items-center'>
                    <div style={{
                        marginRight: '9px'
                    }}
                        role='button'
                        onClick={() => {
                            setSection('RSS_Upload')
                        }}
                    >
                        <img src={backArrow} alt='icon' />
                    </div>
                    <h4 className='page-details mb-0'>RSS Content</h4>

                </div>

                <div className="third-row analytics-card content-table withdraw no-side__padding">

                    <form className='rss-uploaded_content__form '>
                        <div className='side-padding'>
                            <h3 className='metadata__text'>Metadata</h3>
                            <Row>
                                <Col sm='6'>
                                    <div className='form-input__container'>
                                        <label className=''>Content Title</label>
                                        <input className='form-control' placeholder='Content Title' />
                                    </div>

                                    <div className='form-input__container'>
                                        <label className=''>Synopsis of each Chapter/ Episode</label>
                                        <input className='form-control' placeholder='Synopsis' />
                                        <div className='input-info__wrapper d-flex '>
                                            <div className='input-info_icon__wrapper'>
                                                <img src={info} alt='icon' />
                                            </div>
                                            <p className='input-info_text'>
                                                Eg: Episode 1: This is what episode 1 is about. Episode 2: This is....
                                                (For Podcasters, fill as 'I am a podcaster).
                                            </p>

                                        </div>
                                    </div>
                                </Col>
                                <Col sm='6'>
                                    <div className='form-input__container'>
                                        <label className=''>Description</label>
                                        <textarea className='form-control uploaded-content__description' placeholder='Enter Description' rows='6' />
                                    </div>


                                </Col>
                            </Row>
                            <Row>
                                <Col sm='6'>
                                    <div className='form-input__container'>
                                        <label className=''>Genre (s)</label>
                                        <Select
                                            className='form-control cstm-react__select__wrapper'
                                            classNamePrefix="cstm-react__select"
                                            isMulti
                                            isClearable={false}
                                            placeholder='Select Genre (s)'
                                            options={[
                                                {
                                                    label: "Comedy",
                                                    value: "Comedy"
                                                },
                                                {
                                                    label: "Podcast",
                                                    value: "Podcast"
                                                }
                                            ]}
                                        />
                                    </div>


                                </Col>
                                <Col sm='6'>
                                    <div className='form-input__container'>
                                        <label className=''>Sub Genre</label>
                                        <Select
                                            className='form-control cstm-react__select__wrapper'
                                            classNamePrefix="cstm-react__select"
                                            isMulti
                                            isClearable={false}
                                            placeholder='Sub Genre'
                                            options={[
                                                {
                                                    label: "Comedy",
                                                    value: "Comedy"
                                                },
                                                {
                                                    label: "Podcast",
                                                    value: "Podcast"
                                                }
                                            ]}
                                        />
                                    </div>


                                </Col>
                                <Col sm='6'>
                                    <div className='form-input__container'>
                                        <label className=''>Type</label>
                                        <Select
                                            className='form-control cstm-react__select__wrapper'
                                            classNamePrefix="cstm-react__select"
                                            isMulti
                                            isClearable={false}
                                            placeholder='Select Option'
                                            options={[
                                                {
                                                    label: "Comedy",
                                                    value: "Comedy"
                                                },
                                                {
                                                    label: "Podcast",
                                                    value: "Podcast"
                                                }
                                            ]}
                                        />
                                    </div>


                                </Col>
                                <Col sm='6'>
                                    <div className='form-input__container'>
                                        <label className=''>Language (s)</label>
                                        <Select
                                            className='form-control cstm-react__select__wrapper'
                                            classNamePrefix="cstm-react__select"
                                            isMulti
                                            isClearable={false}
                                            placeholder='Select Language (s)'
                                            options={[
                                                {
                                                    label: "Comedy",
                                                    value: "Comedy"
                                                },
                                                {
                                                    label: "Podcast",
                                                    value: "Podcast"
                                                }
                                            ]}
                                        />
                                    </div>


                                </Col>
                                <Col sm='6'>
                                    <div className='form-input__container'>
                                        <label className=''>Author (s)</label>
                                        <input className='form-control' placeholder='Author (s)' />
                                    </div>
                                </Col>
                                <Col sm='6'>
                                    <div className='form-input__container'>
                                        <label className=''>Author Description</label>
                                        <input className='form-control' placeholder='Author Description' />
                                    </div>
                                </Col>
                                <Col sm='6'>
                                    <div className='form-input__container'>
                                        <label className=''>Narrator(s)</label>
                                        <input className='form-control' placeholder='Narrator (s)' />
                                        <div className='input-info__wrapper d-flex '>
                                            <div className='input-info_icon__wrapper'>
                                                <img src={info} alt='icon' />
                                            </div>
                                            <p className='input-info_text'>
                                                For everyone apart from podcasters. Podcasters please fill narrator as 'I am a podcaster.
                                            </p>

                                        </div>
                                    </div>
                                </Col>
                                <Col sm='6'>
                                    <div className='form-input__container'>
                                        <label className=''>Publisher (If Applicable)</label>
                                        <input className='form-control' placeholder='Content Title' />
                                        <div className='input-info__wrapper d-flex '>
                                            <div className='input-info_icon__wrapper'>
                                                <img src={info} alt='icon' />
                                            </div>
                                            <p className='input-info_text'>
                                                Not necessary for podcasters.
                                            </p>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className='upload-content__image__wrapper'>
                                <Row className='gy-4'>
                                    <Col sm='12' md='6'>
                                        <label className=''>Content Cover Art as available (Square)</label>

                                        <Dropzone />
                                        <div className='no-cover_art__wrapper d-flex align-items-center'>
                                            <div className='' >
                                                <input type="checkbox" name="checkbox" className='cstm-checkbox__input' role='button' />
                                            </div>
                                            <p className='mb-0 no-cover_art__text'>
                                                No Image? No problem. Genti will create one for you, Tick this box
                                            </p>


                                        </div>
                                    </Col>
                                    <Col sm='12' md='6'>
                                        <label className=''>Authors Image</label>

                                        <Dropzone />
                                    </Col>
                                </Row>

                            </div>
                        </div>

                        <div className='d-flex justify-content-end rss-upload-button__wrapper'>
                            <div className='side-padding'>
                                <button className='btn auth-btn ' type='submit' onClick={() => {
                                    setSection('RSS_Preview')
                                }}>
                                    {/* {
                        isLoading ?
                            <ButtonLoader /> :
                            'Sign in'
                    } */}
                                    Next


                                </button>
                            </div>


                        </div>

                    </form>
                </div>

            </div>

        </>


    )
}

export default Upload