import React, { useState } from 'react'
import backArrow from '../../assets/img/backArrow.svg'
import { useNavigate } from 'react-router-dom'
import search from '../../assets/img/searchIcon.svg'
import Accordion from '../../components/Accordion'
import { FAQs } from '../../utils/FAQs'
import Header from '../../layout/Auth/Header'
import Footer from '../../layout/Auth/Footer'
import { Head } from 'react-day-picker'
import { handleWhatsappChat } from '../../utils/LinkHandler'


const FAQ = () => {
    const navigate = useNavigate()
    const [isActive, setIsActive] = useState(0);



    return (
        <div>
            <Header className={'dark-header'} />


            <div className=" my-5 py-5 container ">
                <div className='pt-4'>
                    <h3 className='text-center text-white fs-1'>Frequently asked questions</h3>
                    <p className='text-center mb-0 text-white'>These are the most commonly asked questions</p>
                    <p className='text-center text-white'>Can't find what you are looking for? <a className='link-light link-underline-light link-offset-3 link-underline link-underline-opacity-10' role="button" onClick={handleWhatsappChat}>Chat with our friendly team</a></p>
                </div>

                <div className='mt-5 faq-container mx-auto'>
                    {FAQs.map(({ question, answer }, key) => (
                        <Accordion
                            key={key}
                            index={key}
                            title={question}
                            content={answer}
                            isActive={isActive === key}
                            setIsActive={setIsActive}
                        />
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default FAQ