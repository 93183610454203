import React, { useEffect, useMemo, useState, useRef } from "react";
import filterIcon from "../../assets/img/filter.svg";
import showIcon from "../../assets/img/Show.svg";

import search from "../../assets/img/searchIcon.svg";
import sort from "../../assets/img/filterTable.svg";
import t13 from "../../assets/img/t13.svg";

import EmptyContent from "./Empty";
import moment from "moment";
import DatePicker from "../../components/RangePicker";
import close from "../../assets/img/dClose.svg";
import filter from "../../assets/img/filter-list.svg";
import activeFilter from "../../assets/img/activeFilter.svg";
import { Row, Col } from "reactstrap";
import Chart from "../../components/ReportChart";
import EmptyChart from "../../components/ReportChart/EmptyState";
import menu from "../../assets/img/menu.svg";
import downloadReport from "../../assets/img/downloadReport.svg";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import Client from "../../client";
import truncate from "truncate";
import noImage from "../../assets/img/NoDocuments.svg";
import useDebounce from "../../components/Hooks/Debounce";
import select from "../../assets/img/headerselect.svg";
import ReportTile from "./MobileTile";
import { scrollToTop } from "../../utils/helpers";
import Pagination from "../../components/Pagination";
import Select from "../../components/SelectInput";
import ButtonLoader from "../../components/ButtonLoader";

const Content = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const [startDate, setStartDate] = useState(new Date(Date.now()));
  const [endDate, setEndDate] = useState(new Date(Date.now()));
  const [showDateValue, setDateValue] = useState(false);
  const [reports, setReports] = useState([1]);
  const [filterContent, setFilterContent] = useState(false);
  const [editContent, setEditContent] = useState(null);
  const [allReports, setAllReports] = useState([]);
  const [reportChart, setReportChart] = useState([]);
  const debouncedSearchValue = useDebounce(searchValue, 1000);
  const [filterByHoursListened, setFilterByHoursListened] = useState(false);
  const [filterByPaywall, setFilterByPaywall] = useState(false);
  const [filterByListenersCount, setFilterByListenersCount] = useState(false);
  const [filterByTimeframe, setFilterByTimeframe] = useState(false);
  const [is_paywall, setIsPaywall] = useState(null);
  const [hoursListened, setHoursListened] = useState(null);
  const [plays, setPlays] = useState(null);
  const [start_date, setReportStartDate] = useState(new Date(Date.now()));
  const [end_date, setReportEndDate] = useState(new Date(Date.now()));
  const [report_date, setReportDateValue] = useState(null);
  const [searchByDate, setSearchByDate] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [order, setOrder] = useState("asc");
  const [orderParam, setOrderParam] = useState(null);

  const paywall_type = [
    {
      type: "Inside Paywall",
      value: 1,
    },
    {
      type: "Outside Paywall",
      value: 0,
    },
  ];

  const hours_listened = ["<0:20", "0:20 - 2:00", ">2:00"];

  const listers_count = ["<200", "200 - 500", ">500"];

  const currentYear = new Date().getFullYear();
  const startYear = 2020;
  const yearOptions = Array.from(
    { length: currentYear - startYear + 1 },
    (_, i) => startYear + i
  );

  const [selectedYear, setSelectedYear] = useState(currentYear);

  const {
    isLoading: loadingReport,
    isFetching: fetchingReport,
    isRefetching: refetchingReport,
  } = useQuery(
    [
      "all-reports",
      debouncedSearchValue,
      is_paywall,
      hoursListened,
      plays,
      searchByDate,
      activePage,
      orderParam,
      order,
    ],
    () =>
      Client.reports.get_all_reports({
        params: debouncedSearchValue,
        is_paywall: is_paywall ? is_paywall?.value : null,
        hoursListened: hoursListened ? hoursListened : null,
        plays: plays ? plays : null,
        start_date: Boolean(searchByDate) ? report_date.startDate : null,
        end_date: Boolean(searchByDate) ? report_date.endDate : null,
        orderParam: orderParam,
        order: orderParam ? order : null,
        page: activePage,

        // start_date:report_date?report_date
      }),
    {
      onSuccess: (data) => {
        // console.log('--->>data<<---', data)
        setAllReports(data.data);
      },
    }
  );

  const { isLoading, isFetching, isRefetching } = useQuery(
    ["report-chart", selectedYear],
    () =>
      Client.reports.get_report_chart({
        year: selectedYear,
      }),
    {
      onSuccess: (data) => {
        // setAllReports(data.data)
        setReportChart(data.data.hours_listened);
      },
    }
  );

  useEffect(() => {
    if (filterByTimeframe) {
      setReportDateValue({
        startDate: moment(start_date).format("l"),
        endDate: moment(end_date).format("l"),
      });
    }
  }, [report_date, start_date, end_date, filterByTimeframe]);

  const resetFilter = () => {
    setIsPaywall(null);
    setHoursListened(null);
    setPlays(null);
    setReportDateValue(null);
    setFilterByHoursListened(false);
    setFilterByPaywall(false);
    setFilterByListenersCount(false);
    setFilterByTimeframe(false);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const reports_Ref = useRef(null);

  const { data: all_reports, total, current_page, per_page } = allReports || {};

  const changeContent = (e) => {
    setActivePage(e);
    // window.scrollTo(0, 0)
    reports_Ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <p className="page-title" onClick={() => setReports([])}>
        Report
      </p>

      <div className="mb-4 d-flex justify-content-between align-items-center">
        <div>
          <h4 className="page-details ">Report</h4>
        </div>
        <div className="d-flex align-items-center">
          {/* <div className='download-report_img__wrapper download-report_img__wrapper d-flex align-items-center justify-content-center'>
                        <img src={downloadReport} alt='download_report' className='download-report_img' />
                    </div> */}
          <div className="position-relative">
            <Select
              className={`form-control cstm-react__select__wrapper`}
              classNamePrefix="cstm-react__select"
              isClearable={false}
              placeholder="Filter by year"
              options={yearOptions?.map((el) => ({
                label: el,
                value: el,
              }))}
              onChange={(option) => {
                // console.log('', option.value)
                setSelectedYear(option?.value);
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <Row>
          <Col sm="12" md="12">
            <div className="second-row analytics-card report-chart__wrapper ">
              <div className="analytics-card_details h-100">
                <div className="d-flex align-items-cente justify-content-between">
                  <h3 className="analytics-card_title">Hours Listened</h3>
                </div>
                <div className="align-items-center chart-wrapper h-100">
                  {isLoading || isFetching || isRefetching ? (
                    <div
                      className="h-100 w-100 d-flex items-center justify-center"
                      style={{
                        height: "100%",
                      }}
                    >
                      <ButtonLoader />
                    </div>
                  ) : reportChart.length > 0 ? (
                    <Chart data={reportChart} />
                  ) : (
                    <EmptyChart />
                  )}
                </div>
              </div>
            </div>
          </Col>
          {/* <Col sm='12' md='6'>
                        <div className="second-row analytics-card ">
                            <div className='analytics-card_details h-100'>
                                <div className='d-flex align-items-cente justify-content-between'>
                                    <h3 className='analytics-card_title'>Royalties</h3>

                                </div>
                                <div className='align-items-center chart-wrapper '>

                                    {
                                        hours ?
                                            <Chart data={hours} /> :
                                            <EmptyChart />
                                    }
                                </div>

                            </div>
                        </div>
                    </Col> */}
        </Row>
      </div>
      <div className="reports-card_wrapper" ref={reports_Ref}>
        <div className="third-row analytics-card content-table withdraw h-100">
          {loadingReport || refetchingReport || fetchingReport ? (
            <div
              className="d-flex align-items-center justify-content-center position-absolute"
              style={{
                height: "90%",
                width: "calc(100% - 60px)",
              }}
            >
              <ButtonLoader />
            </div>
          ) : (
            <>
              <div className="table-details__wrapper">
                <div className="d-block d-md-flex align-items-center justify-content-between ">
                  <div className="d-flex align-items-center mb-0">
                    <h4 className="table-title pt-2">All Content</h4>
                    <div className="d-none d-md-flex search-content__wrapper  ">
                      <div className="pb-1">
                        <img src={search} alt="icon" />
                      </div>
                      <input
                        className="search-content__input"
                        placeholder="Search Content"
                        defaultValue={searchValue}
                        onChange={(e) => {
                          setSearchValue(e.target.value.toLowerCase());
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="d-block d-md-none report-mobile_search search-content__wrapper d-flex ">
                      <div className="pb-1">
                        <img src={search} alt="icon" />
                      </div>
                      <input
                        className="search-content__input"
                        placeholder="Search Content"
                        defaultValue={searchValue}
                        onChange={(e) => {
                          setSearchValue(e.target.value.toLowerCase());
                        }}
                      />
                    </div>
                    {/* <div className='download-report_img__wrapper d-flex align-items-center justify-content-center'>
                                                <img src={downloadReport} alt='download_report' className='download-report_img' />
                                            </div> */}
                    {filterContent ? (
                      <div
                        className="mobile-active-filtering filtering-active__wrapper filter-icon__wrapper d-flex justify-content-center align-items-center"
                        onClick={() => {
                          setFilterContent(false);
                          resetFilter();
                        }}
                        role="button"
                      >
                        <img role="button" src={activeFilter} alt="icon" />
                        <p className="filter-content__text pt-1 mb-1">Filter</p>
                      </div>
                    ) : (
                      <div
                        role="button"
                        className="report-mobile-filter_wrapper filter-icon__wrapper d-flex justify-content-center align-items-center"
                        onClick={() => {
                          setFilterContent(true);
                        }}
                      >
                        <img src={filter} alt="icon" />
                        <p className="filter-content__text pt-1 mb-1">Filter</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {filterContent && (
                <div className="d-block d-md-flex content-filter__ctrl__wrapper  table-details__wrapper">
                  {/* <div className="position-relative">
                    <div
                      role="button"
                      className="filter-icon__wrapper d-flex justify-content-between align-items-center filter-ctrl__wrapper genre-select_section"
                      onClick={() => {
                        setFilterByPaywall(!filterByPaywall);
                      }}
                    >
                      <p className="filter-content__text filter-type_text pt-1 mb-1">
                        {is_paywall
                          ? truncate(is_paywall.type)
                          : "Pay Category"}
                      </p>
                      <div>
                        {is_paywall ? (
                          <img
                            role="button"
                            src={activeFilter}
                            alt="icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsPaywall(null);
                            }}
                          />
                        ) : (
                          <img src={select} alt="icon" />
                        )}
                      </div>
                    </div>

                    {filterByPaywall && (
                      <div className="position-absolute filter-content-action__cta__wrapper paywall_section ">
                        {paywall_type?.map((el, i) => (
                          <div
                            key={i}
                            className="d-flex align-items-center filter-select__option"
                          >
                            <input
                              type="checkbox"
                              name="checkbox"
                              className="cstm-checkbox__input filter__checkbox"
                              id={`cstm-checkbox__input-${i}`}
                              role="button"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  // filterContentByGenre(el)
                                  setIsPaywall(el);
                                  setFilterByPaywall(!filterByPaywall);
                                } else {
                                  setIsPaywall(null);
                                  setFilterByPaywall(!filterByPaywall);
                                }
                              }}
                              checked={is_paywall?.value === el?.value}
                            />
                            <label
                              className="filter-select__option mb-0"
                              htmlFor={`cstm-checkbox__input-${i}`}
                            >
                              {el?.type}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div> */}

                  <div
                    className=" position-relative"
                    onClick={() => {
                      setFilterContent(true);
                    }}
                  >
                    <div
                      role="button"
                      className="filter-icon__wrapper d-flex justify-content-between align-items-center filter-ctrl__wrapper hours-listened__filter-wrapper "
                      onClick={() => {
                        setFilterByHoursListened(!filterByHoursListened);
                      }}
                    >
                      <p className="filter-content__text filter-type_text pt-1 mb-1">
                        {hoursListened ? hoursListened : "Hours Listened"}
                      </p>
                      <div>
                        {hoursListened ? (
                          <img
                            role="button"
                            src={activeFilter}
                            alt="icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              setHoursListened(null);
                            }}
                          />
                        ) : (
                          <img src={select} alt="icon" />
                        )}
                      </div>
                    </div>

                    {filterByHoursListened && (
                      <div className="position-absolute filter-content-action__cta__wrapper paywall_section ">
                        {hours_listened?.map((el, i) => (
                          <div
                            key={i}
                            className="d-flex align-items-center filter-select__option"
                          >
                            <input
                              type="checkbox"
                              name="checkbox"
                              className="cstm-checkbox__input filter__checkbox"
                              id={`cstm-checkbox__input-${i}`}
                              role="button"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  // filterContentByGenre(el)
                                  setHoursListened(el);
                                  setFilterByHoursListened(
                                    !filterByHoursListened
                                  );
                                } else {
                                  setHoursListened(null);
                                  setFilterByHoursListened(
                                    !filterByHoursListened
                                  );
                                }
                              }}
                              checked={hoursListened === el}
                            />
                            <label
                              className="filter-select__option mb-0"
                              htmlFor={`cstm-checkbox__input-${i}`}
                            >
                              {el}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="position-relative pe-md-3 pe-0">
                    <div
                      role="button"
                      className={`filter-icon__wrapper d-flex justify-content-between align-items-center filter-ctrl__wrapper language-select_section`}
                      onClick={() => {
                        setFilterByListenersCount(!filterByListenersCount);
                      }}
                    >
                      <p className="filter-content__text filter-type_text pt-1 mb-1">
                        {plays ? plays : "No. of Plays"}
                      </p>
                      <div>
                        {plays ? (
                          <img
                            role="button"
                            src={activeFilter}
                            alt="icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              setPlays(null);
                            }}
                          />
                        ) : (
                          <img src={select} alt="icon" />
                        )}
                      </div>
                    </div>
                    {filterByListenersCount && (
                      <div className="position-absolute filter-content-action__cta__wrapper paywall_section ">
                        {listers_count?.map((el, i) => (
                          <div
                            key={i}
                            className="d-flex align-items-center filter-select__option"
                          >
                            <input
                              type="checkbox"
                              name="checkbox"
                              className="cstm-checkbox__input filter__checkbox"
                              id={`cstm-checkbox__input-${i}`}
                              role="button"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  // filterContentByGenre(el)
                                  setPlays(el);
                                  setFilterByListenersCount(
                                    !filterByListenersCount
                                  );
                                } else {
                                  setPlays(null);
                                  setFilterByListenersCount(
                                    !filterByListenersCount
                                  );
                                }
                              }}
                              checked={plays === el}
                            />
                            <label
                              className="filter-select__option mb-0"
                              htmlFor={`cstm-checkbox__input-${i}`}
                            >
                              {el}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="position-relative">
                    <div
                      role="button"
                      className={`filter-icon__wrapper d-flex justify-content-between align-items-center filter-ctrl__wrapper language-select_section`}
                      onClick={() => {
                        setFilterByTimeframe(!filterByTimeframe);
                      }}
                    >
                      <p className="filter-content__text filter-type_text pt-1 mb-1">
                        {searchByDate
                          ? `${report_date?.startDate} - ${report_date?.endDate}`
                          : "Timeframe"}
                      </p>
                      <div>
                        {Boolean(searchByDate) ? (
                          <img
                            role="button"
                            src={activeFilter}
                            alt="icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSearchByDate(0);
                            }}
                          />
                        ) : (
                          <img src={select} alt="icon" />
                        )}
                      </div>
                    </div>
                    {filterByTimeframe && (
                      <div className="position-absolute calendar-input_wrapper report_date__filter-wrapper ">
                        {
                          <div className=" ">
                            <DatePicker
                              startDate={start_date}
                              endDate={end_date}
                              setStartDate={setReportStartDate}
                              setEndDate={setReportEndDate}
                              setDateValue={setReportDateValue}
                              setShowCalendar={setShowCalendar}
                            />
                          </div>
                        }
                        <div className="w-100">
                          <button
                            className="btn auth-btn w-100 mt-3 mb-0"
                            onClick={() => {
                              setFilterByTimeframe(false);
                              setSearchByDate(searchByDate + 1);
                            }}
                          >
                            Filter
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="analytics-card_details h-100 overflow-scroll d-none d-md-block">
                {reports?.length > 0 ? (
                  <div className="">
                    <table className="table mb-0 transaction-table text-white">
                      <thead className="thead-light pb-3">
                        <tr>
                          <th>
                            <div className="d-flex align-items-center">
                              <p className="mb-0">Content Name</p>
                              <div
                                className="sort-icon__wrapper cursor-pointer"
                                onClick={() => {
                                  changeContent(1);
                                  setOrderParam("content_name");
                                  setOrder(order === "asc" ? "desc" : "asc");
                                }}
                              >
                                <img src={sort} alt="icon" />
                              </div>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex align-items-center">
                              <p className="mb-0">Author</p>
                              <div
                                className="sort-icon__wrapper cursor-pointer"
                                onClick={() => {
                                  changeContent(1);
                                  setOrderParam("author_name");
                                  setOrder(order === "asc" ? "desc" : "asc");
                                }}
                              >
                                <img src={sort} alt="icon" />
                              </div>
                            </div>
                          </th>
                          {/* <th>Sub Genre.</th> */}

                          <th>
                            <div className="d-flex align-items-center">
                              <p className="mb-0">Episodes Unlocked</p>
                              <div
                                className="sort-icon__wrapper cursor-pointer"
                                onClick={() => {
                                  changeContent(1);
                                  setOrderParam("episodes_unlocked");
                                  setOrder(order === "asc" ? "desc" : "asc");
                                }}
                              >
                                <img src={sort} alt="icon" />
                              </div>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex align-items-center">
                              <p className="mb-0">Coins Used</p>
                              <div
                                className="sort-icon__wrapper cursor-pointer"
                                onClick={() => {
                                  changeContent(1);
                                  setOrderParam("coins_used");
                                  setOrder(order === "asc" ? "desc" : "asc");
                                }}
                              >
                                <img src={sort} alt="icon" />
                              </div>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex align-items-center">
                              <p className="mb-0">Hours Listened</p>
                              <div
                                className="sort-icon__wrapper cursor-pointer"
                                onClick={() => {
                                  changeContent(1);
                                  setOrderParam("total_time_listened");
                                  setOrder(order === "asc" ? "desc" : "asc");
                                }}
                              >
                                <img src={sort} alt="icon" />
                              </div>
                            </div>
                          </th>
                          {/* <th></th> */}
                          <th>
                            <div className="d-flex align-items-center">
                              <p className="mb-0">No. of Plays</p>
                              <div
                                className="sort-icon__wrapper cursor-pointer"
                                onClick={() => {
                                  changeContent(1);
                                  setOrderParam("plays");
                                  setOrder(order === "asc" ? "desc" : "asc");
                                }}
                              >
                                <img src={sort} alt="icon" />
                              </div>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex align-items-center">
                              <p className="mb-0">Completion Rate</p>
                              <div
                                className="sort-icon__wrapper cursor-pointer"
                                onClick={() => {
                                  changeContent(1);
                                  setOrderParam("completion_rate");
                                  setOrder(order === "asc" ? "desc" : "asc");
                                }}
                              >
                                <img src={sort} alt="icon" />
                              </div>
                            </div>
                          </th>
                          {/* <th>Completion Rate</th> */}
                          {/* <th>Rating</th> */}
                          <th>
                            <div className="d-flex align-items-center">
                              <p className="mb-0">Rating</p>
                              <div
                                className="sort-icon__wrapper cursor-pointer"
                                onClick={() => {
                                  changeContent(1);
                                  setOrderParam("ratings");
                                  setOrder(order === "asc" ? "desc" : "asc");
                                }}
                              >
                                <img src={sort} alt="icon" />
                              </div>
                            </div>
                          </th>
                          <th>
                            <div className="d-flex align-items-center">
                              <p className="mb-0">No. of Shares</p>
                              <div
                                className="sort-icon__wrapper cursor-pointer"
                                onClick={() => {
                                  changeContent(1);
                                  setOrderParam("shares");
                                  setOrder(order === "asc" ? "desc" : "asc");
                                }}
                              >
                                <img src={sort} alt="icon" />
                              </div>
                            </div>
                          </th>
                          {/* <th>No. of Shares</th> */}
                          <th>
                            <div className="d-flex align-items-center">
                              <p className="mb-0">No. of Favorites</p>
                              <div
                                className="sort-icon__wrapper cursor-pointer"
                                onClick={() => {
                                  changeContent(1);
                                  setOrderParam("favorites");
                                  setOrder(order === "asc" ? "desc" : "asc");
                                }}
                              >
                                <img src={sort} alt="icon" />
                              </div>
                            </div>
                          </th>
                          {/* <th>No. of Favorites</th> */}

                          <th className="text-center">Action</th>
                        </tr>
                      </thead>

                      <tbody className="content-table-body">
                        {all_reports?.map((el, i) => (
                          <tr>
                            <td className="td-img__container">
                              <div className="d-flex align-items-center">
                                <div
                                  className="release-img_wrapper"
                                  style={{
                                    borderRadius: "5px",
                                    border: "solid 1px #48494b",
                                  }}
                                >
                                  <img
                                    src={el?.image?.location_image ?? noImage}
                                    alt="release-img"
                                  />
                                </div>
                                <p className="td-text__container ms-2">
                                  {truncate(el?.content_name, 11)}
                                </p>
                              </div>
                            </td>
                            <td className="td-text__container text-center ">
                              {truncate(el?.author_name, 20)}
                            </td>
                            <td className="td-text__container text-center ">
                              {el?.episodes_unlocked}
                            </td>
                            <td className="td-text__container text-center ">
                              {el?.coins_used}
                            </td>
                            <td className="td-text__container text-center ">
                              {el?.total_time_listened}
                            </td>
                            <td className="td-text__container text-r ">
                              {el?.plays}
                            </td>
                            <td className="td-text__container tex-center ">
                              {`${el?.completion_rate}%`}
                            </td>
                            <td className="td-text__container text-center">
                              {el?.ratings}
                            </td>{" "}
                            <td className="td-text__container text-center">
                              {el?.shares}
                            </td>
                            <td className="td-text__container text-center">
                              {el?.favorites ?? 0}
                            </td>
                            <td className="text-center">
                              <div
                                className="content-action__wrapper"
                                role="button"
                                onClick={() => {
                                  navigate(`/report/details/${el?.title_id}`);
                                }}
                              >
                                <img src={showIcon} alt="icon" />
                              </div>
                            </td>
                          </tr>
                        ))}
                        {/* <tr>

                                                        <td className='td-img__container'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='release-img_wrapper'
                                                                >
                                                                    <img src={t13} alt='release-img' />
                                                                </div>
                                                                <p className='td-text__container mx-auto'>225 katakata</p>
                                                            </div>
                                                        </td>
                                                        <td className='td-text__container text-center'>John Sani</td>
                                                        <td className='td-text__container text-center'>Inside</td>
                                                        <td className='td-text__container text-center'>20hrs 15mins</td>
                                                        <td className='td-text__container text-center'>1,532</td>
                                                        <td className='td-text__container text-center'>
                                                            80%
                                                        </td>
                                                        <td className='td-text__container text-center'>
                                                            4.5
                                                        </td> <td className='td-text__container text-center'>
                                                            521
                                                        </td>
                                                        <td className='td-text__container text-center'>
                                                            521
                                                        </td>

                                                        <td className='text-center'>
                                                            <div className='position-relative'>
                                                                <div className='content-action__wrapper' role='button' onClick={() => {
                                                                    setEditContent(editContent === 1 ? null : 1)
                                                                }}>
                                                                    <img src={menu} alt='icon' />
                                                                </div>
                                                                {

                                                                    editContent === 1 ? <div className='position-absolute content-action__cta__wrapper report-action__wrapper'>
                                                                        <div className='d-flex align-items-center content-action__cta'

                                                                            onClick={() => {
                                                                                navigate('/report/details')
                                                                            }}>
                                                                            <div className='content-action__icon'>
                                                                                <img src={showIcon} alt='icon' />
                                                                            </div>
                                                                            <p className='content-action_edit_text mb-0'>View Details</p>

                                                                        </div>
                                                                    </div>

                                                                        : null
                                                                }

                                                            </div>


                                                        </td>

                                                    </tr>
                                                    <tr>

                                                        <td className='td-img__container'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='release-img_wrapper'
                                                                >
                                                                    <img src={t13} alt='release-img' />
                                                                </div>
                                                                <p className='td-text__container mx-auto'>225 katakata</p>
                                                            </div>
                                                        </td>
                                                        <td className='td-text__container text-center'>John Sani</td>
                                                        <td className='td-text__container text-center'>Inside</td>
                                                        <td className='td-text__container text-center'>20hrs 15mins</td>
                                                        <td className='td-text__container text-center'>1,532</td>
                                                        <td className='td-text__container text-center'>
                                                            80%
                                                        </td>
                                                        <td className='td-text__container text-center'>
                                                            4.5
                                                        </td> <td className='td-text__container text-center'>
                                                            521
                                                        </td>
                                                        <td className='td-text__container text-center'>
                                                            521
                                                        </td>

                                                        <td className='text-center'>
                                                            <div className='position-relative'>
                                                                <div className='content-action__wrapper' role='button' onClick={() => {
                                                                    setEditContent(editContent === 2 ? null : 2)
                                                                }}>
                                                                    <img src={menu} alt='icon' />
                                                                </div>
                                                                {

                                                                    editContent === 2 ? <div className='position-absolute content-action__cta__wrapper report-action__wrapper'>
                                                                        <div className='d-flex align-items-center content-action__cta'

                                                                            onClick={() => {
                                                                                navigate('/report/details')
                                                                            }}>
                                                                            <div className='content-action__icon'>
                                                                                <img src={showIcon} alt='icon' />
                                                                            </div>
                                                                            <p className='content-action_edit_text mb-0'>View Details</p>

                                                                        </div>
                                                                    </div>

                                                                        : null
                                                                }

                                                            </div>


                                                        </td>

                                                    </tr>
                                                    <tr>

                                                        <td className='td-img__container'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='release-img_wrapper'
                                                                >
                                                                    <img src={t13} alt='release-img' />
                                                                </div>
                                                                <p className='td-text__container mx-auto'>225 katakata</p>
                                                            </div>
                                                        </td>
                                                        <td className='td-text__container text-center'>John Sani</td>
                                                        <td className='td-text__container text-center'>Inside</td>
                                                        <td className='td-text__container text-center'>20hrs 15mins</td>
                                                        <td className='td-text__container text-center'>1,532</td>
                                                        <td className='td-text__container text-center'>
                                                            80%
                                                        </td>
                                                        <td className='td-text__container text-center'>
                                                            4.5
                                                        </td> <td className='td-text__container text-center'>
                                                            521
                                                        </td>
                                                        <td className='td-text__container text-center'>
                                                            521
                                                        </td>

                                                        <td className='text-center'>
                                                            <div className='position-relative'>
                                                                <div className='content-action__wrapper' role='button' onClick={() => {
                                                                    setEditContent(editContent === 3 ? null : 3)
                                                                }}>
                                                                    <img src={menu} alt='icon' />
                                                                </div>
                                                                {

                                                                    editContent === 3 ? <div className='position-absolute content-action__cta__wrapper report-action__wrapper'>
                                                                        <div className='d-flex align-items-center content-action__cta'

                                                                            onClick={() => {
                                                                                navigate('/report/details')
                                                                            }}>
                                                                            <div className='content-action__icon'>
                                                                                <img src={showIcon} alt='icon' />
                                                                            </div>
                                                                            <p className='content-action_edit_text mb-0'>View Details</p>

                                                                        </div>
                                                                    </div>

                                                                        : null
                                                                }

                                                            </div>


                                                        </td>

                                                    </tr> */}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <EmptyContent />
                )}
              </div>
              <div className="d-block d-md-none report_mobile__wrapper">
                {all_reports?.length > 0 ? (
                  <>
                    {all_reports?.map((el) => (
                      <ReportTile content={el} />
                    ))}
                  </>
                ) : (
                  <EmptyContent />
                )}
              </div>
              {total && total > 10 ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Pagination
                    onPageChange={changeContent}
                    totalCount={total}
                    currentPage={current_page}
                    pageSize={Number(per_page)}
                  />
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Content;
