import ENDPOINTS from '../client/endpoints';
import axios from "axios";
import { HttpClient } from "../client/axios";


const formatDuration = (duration) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.round(duration % 60);

    const formattedHours = hours > 0 ? `${hours}:` : '';
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
};

function getAudioMetadata(file) {
    return new Promise((resolve, reject) => {
        const audio = new Audio();
        audio.src = URL.createObjectURL(file);

        audio.onloadedmetadata = () => {
            const playtimeSeconds = audio.duration;
            const playtimeFormatted = formatDuration(audio.duration);
            const metadata = {
                playtimeSeconds,
                playtimeFormatted,
            };
            resolve(metadata);
        };

        audio.onerror = (error) => {
            reject(error);
        };
    });
}

const FileUploadHandler = (file, title, description,is_paid) => {
    return new Promise((resolve, reject) => {
        const fileName = file.name;
        const fileExt = fileName.substring(fileName.lastIndexOf('.') + 1);

        HttpClient.get(`${ENDPOINTS.GET_PRESIGN_URL}/${fileName}`)
            .then((response) => {
                const newName = response.filePath;
                const modifiedFile = new File([file], newName, { type: file.type });

                const xhr = new XMLHttpRequest();
                xhr.open('PUT', response.uploadUrl);
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200) {
                            getAudioMetadata(file)
                                .then((metadata) => {
                                    const data = {
                                        url: response.file,
                                        extension: fileExt,
                                        title: title,
                                        description: description,
                                        is_paid,
                                        size: file.size,
                                        playtime: metadata.playtimeFormatted,
                                        playtime_seconds: metadata.playtimeSeconds,
                                    };
                                    resolve(data);
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        } else {
                            reject(new Error('Could not upload file.'));
                        }
                    }
                };
                xhr.send(modifiedFile);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export default FileUploadHandler;
