import Router from './Router'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './assets/style'
import 'react-circular-progressbar/dist/styles.css';


const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      {/* <div className="App"> */}
      <Router />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored" />
      {/* </div> */}
    </QueryClientProvider>

  );
}

export default App;
