import React from "react";
import noImage from "../../../assets/img/no-image.png";
import truncate from "truncate";
import { useNavigate } from "react-router-dom";
import rightArrow from "../../../assets/img/forwardArrow.svg";
import moment from "moment";

const MobileTile = ({ transaction }) => {
  return (
    <div className="report-mobile-tile">
      <div className="report-mobile-tile_header">
        <div className="d-flex  w-100 justify-content-between">
          <div className="d-flex align-items-center justify-content-between w-100">
            <div>
              <h3 className="report-name mb-0 w-100">
                {transaction?.type == 0 ? "Income-Royalty" : "Withdrawal"}
              </h3>
            </div>
            <div
              className={`w-25 content-status ${
                transaction?.status === 1
                  ? "live"
                  : transaction?.status === 0
                  ? "review"
                  : "rejected"
              }`}
            >
              {transaction?.status === 1
                ? "Successful"
                : transaction?.status === 0
                ? "Pending"
                : "Rejected"}
            </div>
          </div>
        </div>
      </div>
      <div className="tile-details__wrapper">
        <div className="tile-key-value_wrapper">
          <h3 className="tile-details__key">Amount</h3>
          <h3 className="tile-details__value">
            {Number(transaction?.amount)?.toLocaleString()}
          </h3>
        </div>
        <div className="tile-key-value_wrapper">
          <h3 className="tile-details__key">Account number</h3>
          <h3 className="tile-details__value">
            {" "}
            {Boolean(transaction?.type) ? "*****" : transaction?.account_number}
          </h3>
        </div>{" "}
        <div className="tile-key-value_wrapper">
          <h3 className="tile-details__key">Bank</h3>
          <h3 className="tile-details__value">{transaction?.bank_name}</h3>
        </div>
        <div className="tile-key-value_wrapper">
          <h3 className="tile-details__key">Episodes Unlocked</h3>
          <h3 className="tile-details__value">
            {transaction?.episode_unlocks > 0
              ? transaction?.episode_unlocks
              : "-"}
          </h3>
        </div>
        <div className="tile-key-value_wrapper">
          <h3 className="tile-details__key">Coins Used</h3>
          <h3 className="tile-details__value">
            {transaction?.coins_used > 0 ? transaction?.coins_used : "-"}
          </h3>
        </div>
        <div className="tile-key-value_wrapper">
          <h3 className="tile-details__key">Transaction ID</h3>
          <h3 className="tile-details__value">
            {transaction?.reference_number}
          </h3>
        </div>
        <div className="tile-key-value_wrapper">
          <h3 className="tile-details__key">Date</h3>
          <h3 className="tile-details__value">
            {moment(transaction?.created_at).format("L")}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default MobileTile;
